<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-transaction')"
    route="transaction"
    auto-complete-id="transaction"
    :table-attributes="tableAttributes"
    :data-list-config="dataListConfig"
    :default-sort="{property: 'designation', order: 'desc'}"
    :only-load-active-records="false"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {
  HitContainerAttribute,
  ATTRIBUTE_TYPES,
} from '../../container/HitContainerAttribute';
import {useI18n} from 'vue-i18n';

export default {
  name: 'HitTransactionBrowser',
  components: {
    HitEntityBrowser,
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      tableAttributes: {
        designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-base.common.designation'),
            maxWidth: '1fr',
            mainSearch: true,
          },
        }),
        journal: new HitContainerAttribute(
          ATTRIBUTE_TYPES.JOINED_STRING_COLUMN,
          {
            column: {
              table: 'journal',
              column: 'no',
            },
            transform: (item) => item.no,
            tableProperties: {
              header: this.t('hit-app.common.journal'),
              maxWidth: '1fr',
            },
          }
        ),
        period: new HitContainerAttribute(
          ATTRIBUTE_TYPES.JOINED_STRING_COLUMN,
          {
            column: {
              table: 'period',
              column: 'designation',
            },
            transform: (item) => item.designation,
            tableProperties: {
              header: this.t('hit-app.common.period'),
              maxWidth: '0.5fr',
            },
          }
        ),
        docNo: new HitContainerAttribute(ATTRIBUTE_TYPES.INT, {
          column: 'document_no',
          tableProperties: {
            header: this.t('hit-app.transaction.document-number'),
            width: '1fr',
          },
        }),
        documentDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
          column: 'document_date',
          tableProperties: {
            header: this.t('hit-app.transaction.document-date'),
            width: '1fr',
          },
        }),
        address: new HitContainerAttribute(
          ATTRIBUTE_TYPES.JOINED_STRING_COLUMN,
          {
            column: {
              table: 'address',
              column: 'designation',
            },
            transform: (item) => item.designation,
            tableProperties: {
              header: this.t('hit-base.common.address'),
              maxWidth: '1fr',
            },
          }
        ),
        staffName: new HitContainerAttribute(
          ATTRIBUTE_TYPES.JOINED_STRING_COLUMN,
          {
            column: {
              table: 'staff!fk_transaction_staff_id_staff',
              column: 'designation',
            },
            transform: (staff) => staff.designation,
            tableProperties: {
              header: this.t('hit-app.common.responsible'),
              maxWidth: '1fr',
            },
          }
        ),
        projectPart: new HitContainerAttribute(
          ATTRIBUTE_TYPES.JOINED_STRING_COLUMN,
          {
            column: {
              table: 'project_part',
              column: 'designation',
            },
            transform: (item) => item.designation,
            tableProperties: {
              header: this.t('hit-base.common.project-part'),
              maxWidth: '1fr',
            },
          }
        ),
        tags: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS, {
          tableProperties: {
            header: this.t('hit-base.common.tags'),
            maxWidth: '1fr',
          },
        }),
      },
      dataListConfig: {
        zone1CustomFunction: (item) => `${item.journal} - ${item.docNo}`,
        zone2Field: 'designation',
        zone3CustomFunction: (item) => {
          if (item.address) {
            return item.address;
          } else {
            return item.projectPart;
          }
        },
      },
    };
  },
};
</script>
