import {
  getValSchemaFormDef,
  getValSchemaSections,
  getValSchemaSectionsForDisplay,
} from './valSchema';
const valSchemaFormDef = getValSchemaFormDef();
const valSchemaSections = getValSchemaSections();
const valSchemaSectionsForDisplay = getValSchemaSectionsForDisplay();

//TODO service check code for errors

export function generateData(yamlFormDefinition) {
  const yamlLoader = require('js-yaml');
  const META_FIELDS = ['parent', 'formDate'];
  function getInput(inputDefinition, order) {
    // eslint-disable-next-line no-prototype-builtins
    if (inputDefinition.hasOwnProperty('data')) {
      throw new Error(
        `data key already defined: ${JSON.stringify(inputDefinition)}`
      );
    }

    if (inputDefinition['type'] === 'list') {
      // contains sub-inputs
      let contentDefinition = {
        definition: Object.assign({}, inputDefinition, {
          content: {},
          order: order,
        }),
        data: [],
      };
      let contentOrder = 1;
      for (const [contentInputId, contentInputDefinition] of Object.entries(
        inputDefinition['content']
      )) {
        let subInput = getInput(contentInputDefinition, contentOrder);
        // eslint-disable-next-line no-prototype-builtins
        if (subInput.hasOwnProperty('data')) {
          delete subInput['data'];
        }
        contentDefinition['definition']['content'][contentInputId] = subInput;
        contentOrder += 1;
      }
      if (contentDefinition['definition']['constraints'] === undefined) {
        contentDefinition['definition']['constraints'] = {};
      }
      return contentDefinition;
    } else {
      // eslint-disable-next-line no-prototype-builtins
      if (inputDefinition.hasOwnProperty('values')) {
        let valueOrder = 1;
        for (const [valuesInputId, valuesInputDefinition] of Object.entries(
          inputDefinition['values']
        )) {
          valuesInputDefinition['order'] = valueOrder;
          valueOrder += 1;
        }
      }

      let inputContent = {
        definition: Object.assign({}, inputDefinition, {order: order}),
      };
      if (inputDefinition['type'] !== 'label') {
        // eslint-disable-next-line no-prototype-builtins
        inputContent['data'] = inputDefinition.hasOwnProperty('default')
          ? inputDefinition['default']
          : null;
        if (inputContent['definition']['constraints'] === undefined) {
          inputContent['definition']['constraints'] = {};
        }
      }
      return inputContent;
    }
  }

  try {
    let yaml = yamlLoader.load(yamlFormDefinition);
    if (yaml === null || Object.keys(yaml).length === 0) {
      throw new Error('yaml definition is empty');
    }

    let dataDict = Object.assign({}, yaml);

    // Process meta fields
    let metaFieldOrder = 1;
    for (let metaField in yaml['meta']) {
      if (META_FIELDS.includes(metaField)) {
        dataDict['meta'][metaField] = getInput(
          yaml['meta'][metaField],
          metaFieldOrder
        );
        metaFieldOrder += 1;
      }
    }

    // Process sections
    let sectionOrder = 1;
    for (const [sectionId, sectionDefinition] of Object.entries(
      yaml['sections']
    )) {
      dataDict['sections'][sectionId] = {
        meta: Object.assign({}, sectionDefinition['meta'], {
          order: sectionOrder,
        }),
        content: {},
      };
      let sectionContentOrder = 1;
      for (const [inputId, inputDefinition] of Object.entries(
        sectionDefinition['content']
      )) {
        dataDict['sections'][sectionId]['content'][inputId] = getInput(
          inputDefinition,
          sectionContentOrder
        );
        sectionContentOrder += 1;
      }
      sectionOrder += 1;
    }
    return dataDict;
  } catch (e) {
    throw new Error(
      `Cannot initialize form data from yaml definition: [${e.constructor.name}] ${e.message}`
    );
  }
}
export function isValidFormDefYaml(yamlFormDefinition) {
  const yamlLoader = require('js-yaml');
  const yamlObj = yamlLoader.load(yamlFormDefinition);
  const Ajv = require('ajv');
  const ajv = new Ajv();
  const validate = ajv.compile(valSchemaFormDef);
  return validate(yamlObj);
}

export function isValidSectionsYaml(yamlSections, forDisplay = false) {
  const yamlLoader = require('js-yaml');
  const yamlObj = yamlLoader.load(yamlSections);
  if (yamlObj.meta && yamlObj.meta.name === null) {
    yamlObj.meta.name = {};
  }
  const Ajv = require('ajv');
  const ajv = new Ajv();
  const validate = ajv.compile(
    forDisplay ? valSchemaSectionsForDisplay : valSchemaSections
  );
  return validate(yamlObj);
}
