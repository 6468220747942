import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_hit_button = _resolveComponent("hit-button");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_hit_button, {
            label: _ctx.labelString,
            to: _ctx.to,
            disabled: _ctx.disabled,
            tooltip: _ctx.t('hit-base.common.print-tooltip'),
            color: "accent",
            "prefix-icon": "printer",
            onClick: _withModifiers(_ctx.handleClick, ["stop"])
        }, null, 8, ["label", "to", "disabled", "tooltip", "onClick"])
    ]));
}
