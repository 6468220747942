<template>
  <div
    class="h-full p-1 flex flex-row overflow-hidden"
    :class="{
      'border-r border-table': displayBorder,
    }"
    :style="{
      'justify-content': justifyContent,
      'align-items': 'center',
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'HitModuleListCell',
  props: {
    /**
     * Boolean to disable right border for the last column to not have two overlapping borders
     */
    displayBorder: {
      type: Boolean,
      required: false,
      default: true,
    },

    justifyContent: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped></style>
