<template>
  <hit-base-input
    :label="label"
    :validation-state="validationState"
    :full-width="false"
  >
    <div class="relative h-full">
      <input
        class="border rounded p-2 h-full w-full xl:w-1/2 focus:outline-none focus:ring-transparent bg-input"
        :class="{
          'border-input focus:border-accent':
            (valid && pristine) || !validationState || (valid && dirty),
          'border-danger focus:border-danger': invalid && dirty,
        }"
        :value="inputValue"
        type="text"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder"
        @input="onInput"
        @blur="onBlur"
        @change="onChange"
        @focus="onFocus"
      >
      <div>
        <div
          v-if="displayResults"
          class="absolute shadow rounded overflow-y-auto z-40 inline-blockw-full xl:w-1/2"
        >
          <div class="flex flex-col w-full">
            <div
              v-for="item in itemsToDisplay"
              :key="item.id || item.no"
              class="w-full border-gray-100 rounded-t border-b"
            >
              <div
                class="flex w-full items-center p-2 pl-2 border-transparent bg-default border-l-2 relative"
              >
                <div class="w-full items-center flex">
                  <div
                    class="mx-2 leading-6 items-center flex"
                    :class="{
                      'text-accent': item.currentItem,
                    }"
                  >
                    <hit-icon
                      v-if="item.currentItem"
                      icon="expand-right"
                      size="xs"
                    />{{ item.no }}
                    <!-- @slot Item designation slot -->
                    <slot
                      name="itemDesignation"
                      :item="item"
                    >
                      {{ item.designation }}
                    </slot>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </hit-base-input>
</template>

<script>
import HitBaseInput from '@hit/components/src/components/form/input/HitBaseInput';
import HitFormValidationMixin from '@hit/components/src/mixins/form/HitFormValidationMixin';
import HitInputMixin from '@hit/components/src/mixins/form/HitInputMixin';
import _ from 'lodash';
import {translate} from '../../../../plugins/i18n/i18n';

function valueToReturn(value) {
  if (value === '') {
    return null;
  } else {
    return value;
  }
}

async function fetchPreviousItems() {
  this.previousItemsQueryId = undefined;
  this.previousItemsQueryId = await this.$store.dispatch(
    `${this.storeNamespace}/fetchItems`,
    {
      query: {
        ...this.fetchPreviousItemsQueryParams,
        q: null,
      },
      listenAdd: true,
    }
  );
}

async function fetchNextItems() {
  this.nextItemsQueryId = undefined;
  this.nextItemsQueryId = await this.$store.dispatch(
    `${this.storeNamespace}/fetchItems`,
    {
      query: {
        ...this.fetchNextItemsQueryParams,
        q: null,
      },
      listenAdd: true,
    }
  );
}

export default {
  name: 'HitInputNo',
  components: {HitBaseInput},
  mixins: [HitFormValidationMixin, HitInputMixin],
  props: {
    /**
     * Value of the input
     */
    value: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Label of the input
     */
    label: {
      type: String,
      default() {
        return translate('hit-base.common.no');
      },
    },
    /**
     * Items excluded from the search
     */
    excludedItemIds: {
      type: Array,
      default() {
        return [];
      },
    },
    /**
     * Disable the input
     */
    disabled: Boolean,
    /**
     * Placeholder of the input
     */
    placeholder: {
      type: String,
      default: null,
    },
    /**
     * Store namespace
     */
    storeNamespace: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      previousItemsQueryId: null,
      nextItemsQueryId: null,
      isFocussed: false,
    };
  },
  computed: {
    inputValue() {
      if (this.value) {
        return this.value;
      }
      return '';
    },
    previousItems() {
      return this.isPreviousItemsLoading || this.inputValue === ''
        ? []
        : this.$store.getters[`${this.storeNamespace}/items`](
            this.previousItemsQueryId
          );
    },
    nextItems() {
      return this.isNextItemsLoading || this.inputValue === ''
        ? []
        : this.$store.getters[`${this.storeNamespace}/items`](
            this.nextItemsQueryId
          );
    },
    itemsToDisplay() {
      if (this.inputValue !== '') {
        let result = Object.assign([], this.previousItems);
        result.push({
          no: this.inputValue,
          currentItem: true,
        });
        result = result.concat(this.nextItems);
        return result;
      } else {
        return [];
      }
    },
    displayResults() {
      return this.isFocussed && this.inputValue !== '';
    },
    isPreviousItemsLoading() {
      return (
        this.previousItemsQueryId &&
        this.$store.getters[`${this.storeNamespace}/itemsLoading`](
          this.previousItemsQueryId
        )
      );
    },
    isNextItemsLoading() {
      return (
        this.nextItemsQueryId &&
        this.$store.getters[`${this.storeNamespace}/itemsLoading`](
          this.nextItemsQueryId
        )
      );
    },
    baseFilter() {
      let result = {
        offset: '0',
        limit: '5',
      };

      if (
        this.excludedItemIds &&
        this.excludedItemIds.toString() !== [undefined].toString()
      ) {
        let excludeString = 'not.in.(';
        this.excludedItemIds
          .filter((item) => !!item)
          .forEach((item) => (excludeString += item + ','));
        excludeString = excludeString.slice(0, -1) + ')';
        result.id = excludeString;
      }

      return result;
    },
    fetchPreviousItemsQueryParams() {
      return {
        filters: {
          no: `lte.'${this.value}'`,
          ...this.baseFilter,
        },
        sort: 'no.desc',
      };
    },
    fetchNextItemsQueryParams() {
      return {
        filters: {
          no: `gte.'${this.value}'`,
          ...this.baseFilter,
        },
        sort: 'no.asc',
      };
    },
  },
  watch: {
    fetchPreviousItemsQueryParams: {
      handler: _.debounce(function (newValue, oldValue) {
        // Need to compare as string since an array including the id to exclude is in the item and gives false on equals
        let newValueString = JSON.stringify(newValue);
        let oldValueString = JSON.stringify(oldValue);
        if (this.inputValue !== '' && newValueString !== oldValueString) {
          fetchPreviousItems.bind(this).call();
        }
      }, 100),
      immediate: true,
    },
    fetchNextItemsQueryParams: {
      handler: _.debounce(function (newValue, oldValue) {
        // Need to compare as string since an array including the id to exclude is in the item and gives false on equals
        let newValueString = JSON.stringify(newValue);
        let oldValueString = JSON.stringify(oldValue);
        if (this.inputValue !== '' && newValueString !== oldValueString) {
          fetchNextItems.bind(this).call();
        }
      }, 100),
      immediate: true,
    },
  },
  methods: {
    onInput($event) {
      this.fireInputInput(valueToReturn($event.target.value));
      $event.stopImmediatePropagation();
      if (this.validationState) {
        this.validationState.$touch();
      }
    },
    onChange($event) {
      this.fireInputChange(valueToReturn($event.target.value));
      $event.stopImmediatePropagation();
    },
    onFocus($event) {
      /**
       * When the input has the focus
       */
      this.$emit('focus');
      this.isFocussed = true;
      $event.stopImmediatePropagation();
    },
    onBlur() {
      this.isFocussed = false;
    },
  },
};
</script>

<style scoped lang="scss">
.input {
  font-size: inherit;
}
</style>
