import {BaseRootEntityService} from '../../../../api';
import {makeEntityStore} from '../../../helpers/factory';
import Tag from '../../../../models/hit/common/Tag';
import {tagSchema} from '../../../../models/hit/common/schema';

export default makeEntityStore({
  modelCls: Tag,
  service: new BaseRootEntityService('tag', Tag),
  schema: tagSchema,
});
