export default {
  props: {
    handleContentLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      registeredLoadingEmitter: 0,
    };
  },
  computed: {
    hasRegisteredLoadingEmitter() {
      return this.registeredLoadingEmitter > 0;
    },
  },
  methods: {
    unregisterLoadingEmitter: function () {
      if (this.registeredLoadingEmitter > 0) {
        this.registeredLoadingEmitter--;
      }
    },
    registerLoadingEmitter: function () {
      this.registeredLoadingEmitter++;
    },
  },
  provide() {
    return {
      unregisterLoadingEmitter: this.unregisterLoadingEmitter,
      registerLoadingEmitter: this.registerLoadingEmitter,
    };
  },
};
