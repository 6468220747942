import BaseModel from '../BaseModel';

export default class Form extends BaseModel {
  constructor({name, formDefinition, author, status, data}) {
    super();
    this.name = name;
    this.formDefinition = formDefinition;
    this.author = author;
    this.status = status;
    this.data = data;
  }

  static factory() {
    return new Form({});
  }

  static getMTORelations() {
    return {
      formDefinition: {
        childIdColumn: 'form_definition_id',
        childTableName: 'form_definition',
      },
      author: {
        childIdColumn: 'author_id',
        childTableName: 'staff',
      },
    };
  }

  getStatusTextKey() {
    switch (this.status) {
      case 0:
        return 'hit-base.common.in-progress';
      case 1:
        return 'hit-base.common.sent';
      case 2:
        return 'hit-base.common.validated';
      case 3:
        return 'hit-base.common.injected';
      default:
        return 'hit-base.common.unknown';
    }
  }
}
