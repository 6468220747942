import {BaseChildEntityService} from '../../../../api';
import {makeEntityStore} from '../../../helpers/factory';
import CommunicationModeData from '../../../../models/hit/common/CommunicationModeData';
import {addressCommunicationModeDataSchema} from '../../../../models/hit/address/schema';

export default makeEntityStore({
  modelCls: CommunicationModeData,
  service: new BaseChildEntityService(
    'address_communication_mode_data',
    'address_id',
    CommunicationModeData
  ),
  schema: addressCommunicationModeDataSchema,
});
