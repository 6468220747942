<template>
  <div
    class="grid hit-app-card min-h-16 w-full border-gray-200 cursor-pointer"
    @click="onDivClick"
  >
    <div
      class="grid items-center justify-center w-16 text-white text-sm leading-5 font-medium rounded-l-md"
      :class="backgroundColorClass"
    >
      <span
        v-if="!icon && !iconSrc"
        class="text-xl"
      > {{ shortName }}</span>
      <hit-icon
        v-if="icon"
        :icon="icon"
        color="white"
        size="md"
      />
      <img
        v-if="iconSrc"
        :src="iconSrc"
        class="w-12"
      >
    </div>
    <div
      class="bg-table border-t border-b border-r border-table rounded-r-md"
      :class="hoverBorderColorClass"
    >
      <div class="pl-4 py-2 text-lg leading-normal grid items-center h-full">
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script>
import {HitIcon} from '@hit/components/src';

/**
 * A simple app card component
 */
export default {
  name: 'HitAppCard',
  components: {HitIcon},
  props: {
    /**
     * Color class to apply to the app card background
     */
    backgroundColorClass: {
      type: String,
      required: true,
    },
    /**
     * Color class to apply to the app card border on hover
     */
    hoverBorderColorClass: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Icon for the app card
     */
    icon: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Image for the app card
     */
    iconSrc: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Name to display in the app card
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Is the destination external
     */
    isExternal: {
      type: Boolean,
      default: false,
    },
    /**
     * Url of destination
     */
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
    shortName() {
      return this.name.substring(0, 2).toUpperCase();
    },
  },
  methods: {
    onDivClick() {
      if (this.isExternal) {
        window.open(this.url, '_blank');
      } else {
        this.$router.push({name: this.url});
      }
    },
  },
};
</script>

<style scoped lang="scss">
.hit-app-card {
  grid-template-columns: 4rem 1fr;
}

.bg-hit-tracking {
  background-color: #04a3b2;
}

.hover\:border-hit-tracking:hover {
  border-color: #04a3b2;
}
</style>
