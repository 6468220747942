<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-unit')"
    route="unit"
    auto-complete-id="unit"
    :table-attributes="tableAttributes"
    :default-sort="{property: 'no', order: 'asc'}"
    :only-load-active-records="onlyLoadActiveRecords"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
//dont shorten import
import {
  HitContainerAttribute,
  ATTRIBUTE_TYPES,
} from '../../container/HitContainerAttribute';
import {useI18n} from 'vue-i18n';
import {useConfigurationStore} from '../../../store';

export default {
  name: 'HitUnitBrowser',
  components: {
    HitEntityBrowser,
  },
  props: {
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    const configStore = useConfigurationStore();
    return {t, configStore};
  },
  data() {
    return {
      tableAttributes: {
        number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'no',
          tableProperties: {
            header: this.t('hit-app.common.number'),
            maxWidth: '8em',
          },
        }),
        designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'designation_' + this.configStore.mainLanguageSnakeCase,
          tableProperties: {
            header: this.t('hit-base.common.designation'),
            mainSearch: true,
            maxWidth: '1fr',
          },
        }),
        unit: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'unit_' + this.configStore.mainLanguageSnakeCase,
          tableProperties: {
            header: this.t('hit-app.common.unit'),
            maxWidth: '1fr',
          },
        }),
      },
    };
  },
};
</script>
