import BaseGeolocationServiceAPI from './BaseGeolocationServiceAPI';

export default class GeolocationService {
  constructor({geolocationAPI}) {
    if (geolocationAPI instanceof BaseGeolocationServiceAPI) {
      this.geolocationAPI = geolocationAPI;
    } else {
      throw Error(
        `${geolocationAPI} is not an instance of BaseGeolocationServiceAPI`
      );
    }
  }

  async reverseGeocoding({lat, lon}) {
    return this.geolocationAPI.reverseGeocoding({lat, lon});
  }
}
