export default {
  computed: {
    searchedFields() {
      return this.itemPropertiesToDisplay
        .filter((x) => x.mainSearch)
        .map((x) =>
          x.searchProps
            ? x.searchProps.map((y) => `${x.propertyName}.${y}`)
            : x.propertyName
        )
        .flat();
    },
    itemPropertiesToDisplay() {
      return this.itemProperties.filter((x) => this.displayColumn(x));
    },
  },
  methods: {
    generateCustomSlotName(property, editMode = false) {
      return 'item.' + property + (editMode ? '.edit' : '.display');
    },
    getValueType(propertyName, item = undefined) {
      let prop = this.getPropertyByName(propertyName);
      if (prop) {
        switch (prop.type) {
          case 'boolean':
            return 'boolean';
          case 'bool':
            return 'boolean';
          case 'date':
            return 'date';
          case 'datetime':
            return 'datetime';
          case 'datetime-seconds':
            return 'datetime-seconds';
          case 'text':
            return 'text';
          case 'colour':
            return 'colour';
          case 'decimal':
            return 'decimal';
          case 'int':
            return 'int';
          case 'tag':
            return 'tag';
          case 'enum':
            return 'enum';
        }
      }
      if (item) {
        switch (typeof item[propertyName]) {
          case 'boolean':
            return 'boolean';
        }
      }
      return undefined;
    },
    getPropertyByName(propertyName) {
      return this.itemProperties.find((x) => x.propertyName == propertyName);
    },
    displayColumn(itemProperty) {
      return this.canHideColumn(itemProperty)
        ? this.columnsToDisplay.includes(itemProperty.header)
        : true;
    },
    canHideColumn(itemProperty) {
      return !this.itemBeingCreated || !itemProperty.alwaysVisible;
    },
    initColumnsToDisplay() {
      return this.itemProperties.map((x) => x.header);
    },
  },
  data() {
    return {
      columnsToDisplay: this.initColumnsToDisplay(),
    };
  },
};
