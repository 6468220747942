<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-form-def')"
    route="form_definition"
    :table-attributes="tableAttributes"
    auto-complete-id="form-def"
    :default-sort="defaultSort"
  >
    <template #item.designationUserLanguage.display="{formData}">
      <p>{{ formData.designation }}</p>
    </template>
  </hit-entity-browser>
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
//dont shorten import
import {
  HitContainerAttribute,
  ATTRIBUTE_TYPES,
} from '../../container/HitContainerAttribute';
import {useI18n} from 'vue-i18n';
import {useConfigurationStore} from '@hit/base';

export default {
  name: 'HitFormDefBrowser',
  components: {
    HitEntityBrowser,
  },
  inject: ['tHitField'],
  setup() {
    const {t} = useI18n();
    const config = useConfigurationStore();
    const userLanguage = config.userLanguageSnakeCase;
    return {t, userLanguage};
  },
  data() {
    return {
      tableAttributes: {
        designation: new HitContainerAttribute(
          ATTRIBUTE_TYPES.ML_WITH_FALLBACK,
          {
            tableProperties: {
              header: this.t('hit-base.common.designation'),
              mainSearch: true,
              maxWidth: '2fr',
            },
          }
        ),
      },
      defaultSort: {property: 'designation_' + this.userLanguage, order: 'asc'},
    };
  },
};
</script>
