<template>
  <div class="contents">
    <hit-input-checkbox
      v-if="Object.keys(value).includes('required')"
      :value="value.required"
      :label="t('hit-base.form.required')"
      :validation-state="validationState.required"
      @change="onConstraintChange($event, 'required')"
    >
      <template #help>
        <hit-help
          :text="t('hit-base.form.required-help')"
          :label="t('hit-base.form.required')"
        />
      </template>
    </hit-input-checkbox>

    <hit-input
      v-if="Object.keys(value).includes('minLength')"
      type="int"
      :value="value.minLength"
      :label="t('hit-base.form.min-length')"
      :validation-state="validationState.minLength"
      @change="onConstraintChange($event, 'minLength')"
      @input="onConstraintInput($event, 'minLength')"
    >
      <template #help>
        <hit-help
          :text="t('hit-base.form.min-length-help')"
          :label="t('hit-base.form.min-length')"
        />
      </template>
    </hit-input>

    <hit-input
      v-if="Object.keys(value).includes('maxLength')"
      type="int"
      :value="value.maxLength"
      :label="t('hit-base.form.max-length')"
      :validation-state="validationState.maxLength"
      @change="onConstraintChange($event, 'maxLength')"
      @input="onConstraintInput($event, 'maxLength')"
    >
      <template #help>
        <hit-help
          :text="t('hit-base.form.max-length-help')"
          :label="t('hit-base.form.max-length')"
        />
      </template>
    </hit-input>

    <hit-input
      v-if="Object.keys(value).includes('minValue')"
      :type="componentType === 'int-input' ? 'int' : 'number'"
      :value="value.minValue"
      :label="t('hit-base.form.min-value')"
      :validation-state="validationState.minValue"
      @change="onConstraintChange($event, 'minValue')"
      @input="onConstraintInput($event, 'minValue')"
    >
      <template #help>
        <hit-help
          :text="t('hit-base.form.min-value-help')"
          :label="t('hit-base.form.min-value')"
        />
      </template>
    </hit-input>

    <hit-input
      v-if="Object.keys(value).includes('maxValue')"
      :type="componentType === 'int-input' ? 'int' : 'number'"
      :value="value.maxValue"
      :label="t('hit-base.form.max-value')"
      :validation-state="validationState.maxValue"
      @change="onConstraintChange($event, 'maxValue')"
      @input="onConstraintInput($event, 'maxValue')"
    >
      <template #help>
        <hit-help
          :text="t('hit-base.form.max-value-help')"
          :label="t('hit-base.form.max-value')"
        />
      </template>
    </hit-input>

    <hit-input-checkbox
      v-if="Object.keys(value).includes('multiple')"
      :value="value.multiple"
      :label="t('hit-base.form.multiple')"
      :validation-state="validationState.multiple"
      @change="onConstraintChange($event, 'multiple')"
    >
      <template #help>
        <hit-help
          :text="t('hit-base.form.multiple-help')"
          :label="t('hit-base.form.multiple')"
        />
      </template>
    </hit-input-checkbox>
  </div>
</template>

<script>
import {HitInputMixin, HitFormValidationMixin} from '@hit/components';
import {useI18n} from 'vue-i18n';

export default {
  name: 'HitFormDefinitionBuilderConstraintsComponent',
  components: {},
  mixins: [HitInputMixin, HitFormValidationMixin],
  props: {
    /**
     * Component of the builder to edit
     */
    value: {
      type: Object,
      required: true,
    },
    /**
     * Type of the builder component
     */
    componentType: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  methods: {
    onConstraintInput($event, constraintName) {
      this.value[constraintName] = $event;
      this.fireInputInput(this.value);
      if (this.validationState) {
        this.validationState.$touch();
      }
    },
    onConstraintChange($event, constraintName) {
      this.value[constraintName] = $event;
      this.fireInputChange(this.value);
    },
  },
};
</script>

<style scoped></style>
