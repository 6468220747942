<template>
  <hit-input-radio-button
    v-if="!inTable"
    :options="optionsSorted"
    :value="container.data[attribute]"
    :validation-state="validate ? container.validationState[attribute] : null"
    :disabled="container.attributes[attribute].readOnly"
    @change="(value) => container.handleChange(attribute, value)"
  />
  <hit-select
    v-else
    :options="optionsSorted"
    :value="container.data[attribute]"
    :validation-state="validate ? container.validationState[attribute] : null"
    :disabled="container.attributes[attribute].readOnly"
    @change="(value) => container.handleChange(attribute, value)"
  />
</template>
<script>
import {HitInputRadioButton, HitSelect} from '@hit/components';
//@change="value=>container.handleChange(attribute, value)"
export default {
  name: 'HitContainerInputRadioButton',
  components: {
    HitInputRadioButton,
    HitSelect,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
    options: {
      type: [Array, Object],
      required: true,
    },
    validate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    optionsSorted() {
      try {
        if (typeof this.options !== 'object') {
          return this.options;
        }
        return Object.keys(this.options)
          .map((key) => {
            return {...this.options[key], id: key};
          })
          .sort((a, b) => a.order - b.order);
      } catch (e) {
        return this.options;
      }
    },

    /**
     * We need to check if the options select is inside a table. In this case, we
     * display it as a HitSelect with a unique value
     */
    inTable() {
      return this.$parent.$parent.$parent.$options.name === 'HitTableRow';
    },
  },
};
</script>
