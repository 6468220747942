<template>
  <div class="border-t border-table" />
</template>

<script>
export default {
  name: 'HitDropdownSeparator',
  components: {},
  props: {},
};
</script>
