export const ADD_QUERY = 'ADD_QUERY';
export const ADD_ITEM = 'ADD_ITEM';

export const RECEIVE_ADD_ITEM_ERROR = 'RECEIVE_ADD_ITEM_ERROR';
export const RECEIVE_ADD_ITEM_SUCCESS = 'RECEIVE_ADD_ITEM_SUCCESS';
export const REQUEST_ADD_ITEM = 'REQUEST_ADD_ITEM';
export const RECEIVE_DELETE_ITEM_ERROR = 'RECEIVE_DELETE_ITEM_ERROR';
export const RECEIVE_DELETE_ITEM_SUCCESS = 'RECEIVE_DELETE_ITEM_SUCCESS';
export const REQUEST_DELETE_ITEM = 'REQUEST_DELETE_ITEM';
export const REQUEST_DELETE_ITEM_FROM_STORE = 'REQUEST_DELETE_ITEM_FROM_STORE';
export const RECEIVE_FETCH_ITEM_ERROR = 'RECEIVE_FETCH_ITEM_ERROR';
export const RECEIVE_FETCH_ITEM_SUCCESS = 'RECEIVE_FETCH_ITEM_SUCCESS';
export const REQUEST_FETCH_ITEM = 'REQUEST_FETCH_ITEM';
export const RECEIVE_FETCH_ITEMS_ERROR = 'RECEIVE_FETCH_ITEMS_ERROR';
export const RECEIVE_FETCH_ITEMS_SUCCESS = 'RECEIVE_FETCH_ITEMS_SUCCESS';
export const REQUEST_FETCH_ITEMS = 'REQUEST_FETCH_ITEMS';
export const RECEIVE_UPDATE_ITEM_ERROR = 'RECEIVE_UPDATE_ITEM_ERROR';
export const RECEIVE_UPDATE_ITEM_SUCCESS = 'RECEIVE_UPDATE_ITEM_SUCCESS';
export const REQUEST_UPDATE_ITEM = 'REQUEST_UPDATE_ITEM';
