<template>
  <hit-entity-browser
    :title="
      onlyImages
        ? t('hit-base.common.browser-title-file')
        : t('hit-base.common.browser-title-img')
    "
    route="document"
    :table-attributes="attributesDoc"
    auto-complete-id="file"
    :data-list-config="dataListConfig"
    :default-sort="{property: 'filename', order: 'asc'}"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
//dont shorten import
import {
  HitContainerAttribute,
  ATTRIBUTE_TYPES,
} from '../../container/HitContainerAttribute';
import {useI18n} from 'vue-i18n';
import TagList from 'hit-online-web-ui/src/components/hit/list/TagList.vue';
export default {
  name: 'HitFileBrowser',
  components: {
    HitEntityBrowser,
  },
  props: {
    onlyImages: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      attributesDoc: {
        designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-app.common.designation'),
            maxWidth: '2fr',
            mainSearch: true,
          },
        }),
        filename: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-app.view.filename'),
            maxWidth: '2fr',
          },
        }),
        id: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING),
        mime_type: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: 'Mime',
            maxWidth: '1fr',
          },
        }),
        size: new HitContainerAttribute(ATTRIBUTE_TYPES.INT, {
          transform: (x) => this.convertFileSize(x),
          tableProperties: {
            header: this.t('hit-app.view.size'),
            maxWidth: '1fr',
          },
        }),
      },
      dataListConfig: {
        zone2Field: 'filename',
      },
    };
  },
  methods: {
    convertFileSize(sizeInKb) {
      if (sizeInKb < 1000) {
        return sizeInKb + ' B';
      } else if (sizeInKb < 1000000) {
        return (sizeInKb / 1000).toFixed(2) + ' kB';
      } else if (sizeInKb < 1000000000) {
        return (sizeInKb / 1000000).toFixed(2) + ' MB';
      } else if (sizeInKb < 1000000000000) {
        return (sizeInKb / 1000000000).toFixed(2) + ' GB';
      }
    },
  },
};
</script>
