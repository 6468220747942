<template>
  <hit-input-checkbox
    :name="name"
    :label="label"
    :disabled="disabled"
    :readonly="readonly"
    :inline-input="inlineInput"
    :inline-label-width="inlineLabelWidth"
    :item-id-property="itemIdProperty"
    :display-label-column="displayLabelColumn"
    :reset-validation-if-valid="resetValidationIfValid"
    :use-custom-height="useCustomHeight"
    :values="values"
    :value="value"
    :remove-padding="removePadding"
    :indeterminate="indeterminate"
    :get-localized-value="getLocalizedValue"
    @click="onClick"
    @change="onChange"
  />
</template>

<script>
import {HitFormValidationMixin, HitInputMixin} from '@hit/components';

export default {
  name: 'HitAppInputCheckbox',
  components: {},
  mixins: [HitInputMixin, HitFormValidationMixin],
  inject: ['tHitValue'],
  props: {
    /**
     * Values of the checkbox
     */
    values: {
      type: [String, Array, Object],
      required: false,
      default: () => {
        return {
          default: {
            id: 'default',
          },
        };
      },
    },
    /**
     * Whether the checkbox is checked or list of checked values
     */
    value: {
      type: [Array, Boolean],
      required: false,
      default: false,
    },
    /**
     * Remove the checkbox padding
     */
    removePadding: {
      type: Boolean,
      default: false,
    },
    /**
     * Enable indeterminate state
     */
    indeterminate: Boolean,
  },
  methods: {
    getLocalizedValue(item) {
      return this.tHitValue(item);
    },
    onChange($event) {
      this.$emit('change', $event);
    },
    onClick($event) {
      this.$emit('click', $event);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
[type='checkbox'].hit-checkbox {
  @apply h-4 w-4 text-default rounded focus:ring-transparent border-input;
}

.hit-checkbox:indeterminate {
  border: none;
  @apply bg-no-repeat bg-center bg-checkbox-checked;

  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='2' x='4' y='7' rx='1'/%3E%3C/svg%3E");
}
</style>
