import {maxLength, required, helpers} from '@vuelidate/validators';
import CommunicationMode from './CommunicationMode';

import {
  BaseConstraint,
  getRequiredValidations,
  mail,
  phone,
  ValidatorFactory as HitComponentsValidatorFactory,
} from '@hit/components';

function ValidatorFactory(constraints, isList = false) {
  let result = {};
  for (const constraintId of Object.keys(constraints)) {
    const foundConstraint = HitComponentsValidatorFactory(
      result,
      constraints,
      constraintId,
      isList
    );
    if (!foundConstraint) {
      switch (constraintId) {
        case 'communicationMode':
          result['communicationMode'] = communicationMode(
            constraints.communicationMode
          );
          break;
      }
    }
  }
  return result;
}
const communicationMode = (field) =>
  helpers.withParams(
    {type: 'communicationMode', field: field},
    function (value, parentVm) {
      return parentVm[field] === CommunicationMode.ID_EMAIL
        ? mail(value)
        : phone(value);
    }
  );

class CommunicationModeConstraint extends BaseConstraint {
  constructor(communicationModeField) {
    super();
    this.communicationModeField = communicationModeField;
  }

  addToValidator(validator) {
    if (this.communicationModeField) {
      super.addToValidator(
        validator,
        'communicationMode',
        communicationMode(this.communicationModeField)
      );
    }
  }

  getJsonAttribute() {
    return 'communicationMode';
  }

  toJson() {
    return this.communicationModeField;
  }
}

function getDesignationValidations() {
  return getRequiredValidations();
}

function getFollowUpValidations() {
  return {
    staff: {
      id: getRequiredValidations(),
    },
    designation: getDesignationValidations(),
    description: {},
  };
}

function getCommunicationModeDataValidations() {
  const validations = {
    communicationMode: getRequiredValidations(),
    value: getRequiredValidations(),
    comment: {},
  };
  new CommunicationModeConstraint('communicationMode').addToValidator(
    validations.value
  );
  return validations;
}

function getTagValidations() {
  return {
    designation: getDesignationValidations(),
  };
}

function getCountryValidations(getLocalizedFieldFn) {
  const validations = {
    id: {
      maxLength: maxLength(2),
      required: required,
    },
    //no: getRequiredValidations(),
    no: {required},
    active: {},
  };

  let designationField;
  designationField = getLocalizedFieldFn('designation');

  validations[designationField] = {required};

  return validations;
}

function getMemoValidations() {
  return {
    memo: {},
  };
}

function addLocalizedFieldsValidation(
  validations,
  field,
  languages,
  getLocalizedFieldFn
) {
  if (languages && languages.length > 0) {
    let localizedFields = languages.map((language) =>
      getLocalizedFieldFn(field, language)
    );
    for (let localizedField of localizedFields) {
      if (!Object.prototype.hasOwnProperty.call(validations, localizedField)) {
        validations[localizedField] = {};
      }
    }
    //TODO migr probably needs fixing
    if (
      !Object.prototype.hasOwnProperty.call(validations, '$validationGroups')
    ) {
      validations['$validationGroups'] = {};
    }
    validations['$validationGroups'][field] = [...localizedFields]; // We need a copy of the array because HitForm will modify it
  }
}

export {
  ValidatorFactory,
  getFollowUpValidations,
  getCommunicationModeDataValidations,
  getTagValidations,
  getCountryValidations,
  getRequiredValidations,
  getDesignationValidations,
  addLocalizedFieldsValidation,
  getMemoValidations,
};
