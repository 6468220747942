export function getHitColorProps() {
    return {
        /**
         * Color of the component
         */
        color: {
            type: String,
            default: null,
            validator: function (value) {
                // The value must match one of these strings
                return ([
                    'ficos',
                    'background',
                    'header',
                    'sidebar',
                    'sidebar-selected',
                    'panel',
                    'panel-light',
                    'table',
                    'tag',
                    'accent',
                    'input',
                    'button',
                    'button-floating',
                    'danger',
                    'success',
                    'warning',
                ].indexOf(value) !== -1);
            },
        },
    };
}
