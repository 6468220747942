import BaseModel from '../BaseModel';

export default class Country extends BaseModel {
  constructor({
    id,
    no,
    active,
    designationFrBe,
    designationDeBe,
    designationNlBe,
    designationEnGb,
  }) {
    super();
    this.id = id;
    this.no = no;
    this.active = active;
    this.designationDeBe = designationDeBe;
    this.designationFrBe = designationFrBe;
    this.designationNlBe = designationNlBe;
    this.designationEnGb = designationEnGb;
  }

  static factory() {
    return new Country({active: true});
  }
}
