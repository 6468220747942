<template>
  <div
    :style="iconCutAroundStyle"
    :class="{
      [additionalClasses]: true,
      'flex items-center': cutWidth <= 0 && cutHeight <= 0,
      'justify-center': alignment === 'center',
      'justify-start': alignment === 'start',
      'justify-end': alignment === 'end',
      'hover:bg-button-hover rounded': shadowOnHover,
    }"
  >
    <i
      style="font-variation-settings: 'FILL' 0, 'wght' 250, 'GRAD' 0, 'opsz' 0"
      :style="{...iconCutTransformStyle, ...iconColorStyle}"
      class="material-symbols-outlined leading-none remove-built-in-padding"
      :class="[
        {
          'text-danger text-6xl': !iconFound,
          'cursor-pointer': clickable || tooltip,
          'remove-padding-manually': inTable,
        },
        getTailwindClassFromSize,
        getTailwindClassFromColor,
      ]"
      :title="tooltip"
      @click="onClick"
    >{{ materialIcon }}</i>
  </div>
</template>

<script>
/**
 * A simple icon component
 */
import {ICONS_MAPPING, DEFAULT_ICON} from './HitIcon.js';

export default {
  name: 'HitIcon',
  components: {},
  inheritAttrs: false, //needed to make onClick not fall through to <i> which would cause two calls to onClick
  props: {
    /**
     * Display pointer cursor on hover
     */
    clickable: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable click
     */
    disabled: Boolean,
    /**
     * Icon tooltip
     */
    tooltip: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Icon name to display
     */
    icon: {
      type: String,
      required: true,
      default: DEFAULT_ICON,
    },
    /**
     * Icon size. Available values : 2xs, xs, sm, base, md, lg, xl.
     */
    size: {
      type: String,
      default: 'base',
      validator: function (value) {
        // The value must match one of these strings
        return (
          ['2xs', 'xs', 'sm', 'base', 'md', 'lg', 'xl'].indexOf(value) !== -1
        );
      },
    },
    /**
     * Icon color.
     */
    color: {
      type: String,
      default: 'card',
    },
    iconColorStyle: {
      type: Object,
      required: false,
    },
    /**
     * Specifies if the icon is used in a table so that we have to remove the empty space manually
     */
    inTable: {
      type: Boolean,
      default: false,
    },
    /**
     * removes some of the padding inherent to the icon by making the containing div smaller and cutting overflow
     * specified in rem
     */
    cutWidth: {
      type: Number,
      default: 0,
    },
    /**
     * removes some of the padding inherent to the icon by making the containing div smaller and cutting overflow
     * specified in rem
     */
    cutHeight: {
      type: Number,
      default: 0,
    },
    additionalClasses: {
      type: String,
      default: '',
    },
    alignment: {
      type: String,
      required: false,
      default: 'center',
    },
    shadowOnHover: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    materialIcon() {
      if (this.icon in ICONS_MAPPING.material) {
        return ICONS_MAPPING.material[this.icon];
      } else {
        return ICONS_MAPPING.material[DEFAULT_ICON];
      }
    },
    iconFound() {
      return this.materialIcon !== ICONS_MAPPING.material[DEFAULT_ICON];
    },
    getRemFromSize() {
      switch (this.size) {
        case '2xs':
          return 1;
        case 'xs':
          return 1.125;
        case 'sm':
          return 1.25;
        case 'base':
          return 1.5;
        case 'md':
          return 1.875;
        case 'lg':
          return 2.25;
        case 'xl':
          return 3.75;
        default:
          return 1.5;
      }
    },
    getTailwindClassFromSize() {
      switch (this.size) {
        case '2xs':
          return 'text-base';
        case 'xs':
          return 'text-lg';
        case 'sm':
          return 'text-xl';
        case 'base':
          return 'text-2xl';
        case 'md':
          return 'text-3xl';
        case 'lg':
          return 'text-4xl';
        case 'xl':
          return 'text-6xl';
        default:
          return 'text-2xl';
      }
    },
    getTailwindClassFromColor() {
      if (this.iconColorStyle) {
        return '';
      }
      switch (this.color) {
        case 'light':
          return 'text-light';
        case 'light-2':
          return 'text-light-2';
        case 'warning':
          return 'text-warning-icon';
        case 'danger':
          return 'text-danger-icon';
        case 'white':
          return 'text-white';
        case 'default':
          return 'text-default';
        case 'success':
          return 'text-success-icon';
        case 'clickable':
          return 'text-clickable';
        case 'header':
          return 'text-header';
        case 'card':
          return 'text-card';
        case 'input':
          return 'text-input';
        default:
          return this.color;
      }
    },
    iconCutAroundStyle() {
      if (this.cutWidth <= 0 && this.cutHeight <= 0) {
        return {};
      } else {
        let newSizeX = this.getRemFromSize - this.cutWidth;
        let newSizeY = this.getRemFromSize - this.cutHeight;
        return {
          width: newSizeX + 'rem',
          height: newSizeY + 'rem',
          overflow: 'hidden',
        };
      }
    },
    iconCutTransformStyle() {
      if (this.cutWidth <= 0 && this.cutHeight <= 0) {
        return {};
      } else {
        let transformAmountX = this.cutWidth / 2 + 'rem';
        let transformAmountY = this.cutHeight / 2 + 'rem';
        return {
          transform:
            'translate(-' + transformAmountX + ',-' + transformAmountY + ')',
        };
      }
    },
  },
  methods: {
    onClick: function ($event) {
      if (!this.disabled && this.clickable) {
        /**
         * When the icon is clicked
         */
        this.$emit('click', $event);
      }
    },
  },
};
</script>

<style>
.remove-padding-manually {
  transform: translateX(-5px);
}
</style>
