import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        ref: (ref) => (_ctx.rootDomEl = ref),
        class: _normalizeClass(["rounded overflow-visible", {
                shadow: _ctx.displayShadow,
                'bg-panel': !_ctx.hideBackground,
            }]),
        onClick: _cache[0] || (_cache[0] =
            //@ts-ignore
            (...args) => (_ctx.onDivClick && _ctx.onDivClick(...args)))
    }, [
        _renderSlot(_ctx.$slots, "default")
    ], 2));
}
