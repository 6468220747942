import {BaseRootEntityService} from '../../../api';
import {makeEntityStore} from '../../helpers/factory';
import tag from './tag';
import {Equipment, equipmentSchema} from '../../../models';

export default makeEntityStore({
  modelCls: Equipment,
  service: new BaseRootEntityService('equipment', Equipment),
  schema: equipmentSchema,
  tag,
});
