<template>
  <hit-input-radio-button
    :name="name"
    :label="label"
    :disabled="disabled"
    :readonly="readonly"
    :inline-input="inlineInput"
    :inline-label-width="inlineLabelWidth"
    :item-id-property="itemIdProperty"
    :display-label-column="displayLabelColumn"
    :reset-validation-if-valid="resetValidationIfValid"
    :use-custom-height="useCustomHeight"
    :validation-state="validationState"
    :options="options"
    :value="value"
    :get-localized-value="getLocalizedValue"
    @change="onChange"
  />
</template>

<script>
import {HitFormValidationMixin, HitInputMixin} from '@hit/components';

export default {
  name: 'HitAppInputRadioButton',
  components: {},
  mixins: [HitInputMixin, HitFormValidationMixin],
  inject: ['tHitValue'],
  props: {
    /**
     * Options of the input
     */
    options: {
      type: [Array, Object],
      required: true,
    },
    /**
     * Value of the input
     */
    value: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    getLocalizedValue(item) {
      return this.tHitValue(item);
    },
    onChange($event) {
      this.$emit('change', $event);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
