<template>
  <hit-select
    :label-fields="concatenatedLabelFields"
    :fall-back-config="fallBackConfig"
    :fall-back-labels="labelFieldsLocalised"
    :options="options"
    :options-to-exclude="optionToExclude"
    :value="container.data[attribute]"
    :disabled="container.attributes[attribute].readOnly"
    :multiple="multiple"
    :sort-config="sortConfigLocalised"
    :validation-state="
      container.validationState ? container.validationState[attribute] : null
    "
    @change="handleChange"
    @remove="handleRemove"
  />
</template>

<script>
import {HitSelect} from '@hit/components';
import {DataService, useConfigurationStore} from '@hit/base/src';

export default {
  name: 'HitContainerEntitySelect',
  components: {HitSelect},
  props: {
    /**
     * Parent container where the EntitySelect is used in
     */
    container: {
      type: Object,
      required: true,
    },
    /**
     * Name of the attribute from the container the select should read and modify
     */
    attribute: {
      type: String,
      required: true,
    },
    /**
     * Api route to load dropdown options (name of the table)
     */
    route: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: false,
      default: () => [],
    },
    idField: {
      type: String,
      required: false,
      default: 'id',
    },
    labelFields: {
      type: Array,
      default() {
        return [];
      },
    },
    labelFieldsLocalised: {
      type: Array,
      default() {
        return [];
      },
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    sortConfig: {
      type: Object,
      required: false,
      default: null,
    },
    /**
     * Sometimes we do not want that the update is directly done in this component
     * Like this we can make some checks. Listen for the @add event in the parent component
     */
    preventContainerUpdate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      optionToExclude: [],
      configStore: useConfigurationStore(),
    };
  },
  computed: {
    fallBackConfig() {
      return {
        fallBackLabels: this.labelFieldsLocalised,
        userLanguage: this.configStore.userLanguageSnakeCase,
        mainLanguage: this.configStore.mainLanguageSnakeCase,
      };
    },
    attributes() {
      let resultSet = new Set(this.fields);
      resultSet.add(this.idField);
      resultSet.add(this.concatenatedLabelFields);
      if (this.hasActiveAttribute) {
        resultSet.add('active');
      }
      return Array.from(resultSet);
    },
    concatenatedLabelFields() {
      const labelFieldsResult = [...this.labelFields];
      this.labelFieldsLocalised.forEach((field) => {
        labelFieldsResult.push(
          field + '_' + this.configStore.userLanguageSnakeCase
        );
        labelFieldsResult.push(
          field + '_' + this.configStore.mainLanguageSnakeCase
        );
      });
      return labelFieldsResult;
    },
    sortConfigLocalised() {
      if (this.sortConfig && this.sortConfig.localised) {
        let result = JSON.parse(JSON.stringify(this.sortConfig)); // copy object
        result.field =
          result.field + '_' + this.configStore.mainLanguageSnakeCase;
        return result;
      }
      return this.sortConfig;
    },
    hasActiveAttribute() {
      return ['country', 'unit'].includes(this.route);
    },
  },
  watch: {
    // We need to wait until the container is ready
    // Otherwise, we do not have the value of the selected item to include in the filter query
    'container.ready'() {
      this.loadOptions();
    },
  },
  mounted() {
    this.loadOptions();
  },
  methods: {
    loadOptions() {
      let filter = {};
      if (this.hasActiveAttribute) {
        const selectedValue = this.container.data[this.attribute];
        filter = selectedValue
          ? {or: '(active.is.true,id.eq.' + selectedValue + ')'}
          : {active: 'is.true'};
      }
      DataService.read(this.route, {
        attributes: this.attributes.join(),
        filters: filter,
      }).then((response) => {
        this.options = response.data;
        response.data.forEach((option) => {
          if ('active' in option && !option.active) {
            this.optionToExclude.push(option.id);
          }
        });
      }); //TODO error handling
    },
    handleChange(value) {
      if (this.multiple || this.container.multiple) {
        this.$emit('add', value);
      } else {
        if (!this.preventContainerUpdate) {
          this.container.handleChange(this.attribute, value);
        }
      }
    },
    handleRemove(value) {
      this.$emit('remove', value);
    },
  },
};
</script>
