import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "grid items-center alert gap-1" };
const _hoisted_2 = {
    key: 0,
    class: "circle"
};
const _hoisted_3 = {
    key: 1,
    class: "col-start-2"
};
const _hoisted_4 = {
    key: 2,
    class: "col-start-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_hit_icon = _resolveComponent("hit-icon");
    const _component_hit_button = _resolveComponent("hit-button");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["rounded-md mx-3 mt-2 px-2 py-1 shadow bg-panel border", _ctx.alertBorderClass])
    }, [
        _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_hit_icon, {
                icon: _ctx.icon,
                color: _ctx.iconColor
            }, null, 8, ["icon", "color"]),
            _createElementVNode("div", null, [
                _createElementVNode("p", {
                    class: _normalizeClass(["text-sm leading-5 font-semibold", _ctx.textColor])
                }, [
                    _renderSlot(_ctx.$slots, "message")
                ], 2)
            ]),
            (_ctx.bannerCount > 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.bannerCount), 1)
                ]))
                : _createCommentVNode("", true),
            _createVNode(_component_hit_icon, {
                icon: "clear",
                clickable: true,
                color: _ctx.iconColor,
                onClick: _ctx.close
            }, null, 8, ["color", "onClick"]),
            (_ctx.displayDetails)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _renderSlot(_ctx.$slots, "details")
                ]))
                : _createCommentVNode("", true),
            (_ctx.hasDetailsSlot)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_hit_button, {
                        label: _ctx.buttonLabel,
                        class: "w-auto",
                        color: _ctx.buttonColor,
                        onClick: _ctx.toggleDetailsDisplay
                    }, null, 8, ["label", "color", "onClick"])
                ]))
                : _createCommentVNode("", true)
        ])
    ], 2));
}
