<template>
  <hit-base-input
    :label="label"
    :use-custom-height="
      displayLayout === 'column' ? true : !largeEnoughForGroup
    "
    :full-width="false"
  >
    <div
      :style="cssStyles"
      :class="groupClass"
    >
      <slot :display-layout="displayLayout" />
    </div>
  </hit-base-input>
</template>

<script>
import HitFormValidationMixin from '../../../mixins/form/HitFormValidationMixin';
import HitInputMixin from '../../../mixins/form/HitInputMixin';
import HitBaseInput from './HitBaseInput';

export default {
  name: 'HitGroupInput',
  components: {
    HitBaseInput,
  },
  mixins: [HitInputMixin, HitFormValidationMixin],
  props: {
    displayLayout: {
      type: String,
      default: 'row',
      validator(value) {
        return ['row', 'column'].indexOf(value) !== -1;
      },
    },
    largeEnoughForGroup: {
      type: Boolean,
      required: false,
      default: true,
    },
    inputDistribution: {
      type: String,
      required: false,
      default: '2fr 1fr 2fr',
    },
  },
  computed: {
    groupClass() {
      if (!this.largeEnoughForGroup) {
        return '';
      } else if (this.displayLayout === 'column') {
        return 'hit-input-group-column';
      } else {
        return 'hit-input-group';
      }
    },
    cssStyles() {
      return {
        '--grid-col-distribution': this.inputDistribution,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.hit-input-group {
  @apply grid gap-1;
  grid-auto-columns: max-content;
}

.hit-input-group-column {
  grid-auto-columns: max-content;
  grid-auto-rows: max-content;
}

.large-enough-for-form {
  .hit-input-group {
    @apply grid-flow-col;
    grid-template-columns: var(--grid-col-distribution);
  }
}
</style>
