<template>
  <hit-browser-selection-display
    v-if="!editActive"
    :designation="designation"
    :clearable="true"
    :label="label"
    :disabled="disabled"
    :only-load-active-records="onlyLoadActiveRecords"
    :editable="editable"
    :validation-state="validate ? container.validationState[attribute] : null"
    :show-redirect="showRedirect"
    @clear="container.handleChange(attribute, null)"
    @search="modalVisible = true"
    @edit="handleEdit"
    @redirect="redirectToLinkedEntity"
  />
  <hit-input
    v-else
    :ref="(ref) => (designationInput = ref)"
    :value="designation"
    @keyup.enter="handleChangeDesignation"
    @blur="handleChangeDesignation"
  />
  <hit-equipment-browser
    v-if="route === 'equipment'"
    :visible="modalVisible"
    :only-load-active-records="onlyLoadActiveRecords"
    :filter-on-tags="tagFilters"
    :custom-filters="customFilters"
    :for-injection="forInjection"
    :transform-selected-item-to-db-column-values="
      transformSelectedItemToDbColumnValues
    "
    @item-selected="selectItem"
    @click-outside="modalVisible = false"
  />
  <hit-address-browser
    v-else-if="route === 'address'"
    :visible="modalVisible"
    :only-load-active-records="onlyLoadActiveRecords"
    :filter-on-tags="tagFilters"
    :custom-filters="customFilters"
    :transform-selected-item-to-db-column-values="
      transformSelectedItemToDbColumnValues
    "
    @item-selected="selectItem"
    @click-outside="modalVisible = false"
  />
  <hit-staff-browser
    v-else-if="route === 'staff'"
    :visible="modalVisible"
    :only-load-active-records="onlyLoadActiveRecords"
    :filter-on-tags="tagFilters"
    :custom-filters="customFilters"
    :for-injection="forInjection"
    :transform-selected-item-to-db-column-values="
      transformSelectedItemToDbColumnValues
    "
    @item-selected="selectItem"
    @click-outside="modalVisible = false"
  />
  <hit-project-browser
    v-else-if="route === 'project'"
    :visible="modalVisible"
    :only-load-active-records="onlyLoadActiveRecords"
    :filter-on-tags="tagFilters"
    :custom-filters="customFilters"
    :transform-selected-item-to-db-column-values="
      transformSelectedItemToDbColumnValues
    "
    @item-selected="selectItem"
    @click-outside="modalVisible = false"
  />
  <hit-project-part-browser
    v-else-if="route === 'project_part'"
    :visible="modalVisible"
    :only-load-active-records="onlyLoadActiveRecords"
    :filter-on-tags="tagFilters"
    :custom-filters="customFilters"
    :transform-selected-item-to-db-column-values="
      transformSelectedItemToDbColumnValues
    "
    @item-selected="selectItem"
    @click-outside="modalVisible = false"
  />
  <hit-resource-browser
    v-else-if="route === 'resource'"
    :visible="modalVisible"
    :only-load-active-records="onlyLoadActiveRecords"
    :filter-on-tags="tagFilters"
    :custom-filters="customFilters"
    :transform-selected-item-to-db-column-values="
      transformSelectedItemToDbColumnValues
    "
    @item-selected="selectItem"
    @click-outside="modalVisible = false"
  />
  <hit-journal-browser
    v-else-if="route === 'journal'"
    :visible="modalVisible"
    :only-load-active-records="onlyLoadActiveRecords"
    :filter-on-tags="tagFilters"
    :custom-filters="customFilters"
    :transform-selected-item-to-db-column-values="
      transformSelectedItemToDbColumnValues
    "
    @item-selected="selectItem"
    @click-outside="modalVisible = false"
  />
  <hit-tax-code-browser
    v-else-if="route === 'tax_code'"
    :visible="modalVisible"
    :only-load-active-records="onlyLoadActiveRecords"
    :filter-on-tags="tagFilters"
    :custom-filters="customFilters"
    :transform-selected-item-to-db-column-values="
      transformSelectedItemToDbColumnValues
    "
    @item-selected="selectItem"
    @click-outside="modalVisible = false"
  />
  <hit-transaction-browser
    v-else-if="route === 'transaction'"
    :visible="modalVisible"
    :only-load-active-records="false"
    :filter-on-tags="tagFilters"
    :custom-filters="customFilters"
    :transform-selected-item-to-db-column-values="
      transformSelectedItemToDbColumnValues
    "
    @item-selected="selectItem"
    @click-outside="modalVisible = false"
  />
  <hit-due-date-browser
    v-else-if="route === 'due_date'"
    :visible="modalVisible"
    :only-load-active-records="onlyLoadActiveRecords"
    :filter-on-tags="tagFilters"
    :custom-filters="customFilters"
    :transform-selected-item-to-db-column-values="
      transformSelectedItemToDbColumnValues
    "
    @item-selected="selectItem"
    @click-outside="modalVisible = false"
  />
</template>

<script>
import HitEquipmentBrowser from './HitEquipmentBrowser.vue';
import HitAddressBrowser from './HitAddressBrowser.vue';
import HitBrowserSelectionDisplay from './HitBrowserSelectionDisplay.vue';
import HitStaffBrowser from './HitStaffBrowser.vue';
import HitProjectBrowser from './HitProjectBrowser.vue';
import {DataService, useConfigurationStore} from '../../../index';
import HitProjectPartBrowser from './HitProjectPartBrowser.vue';
import HitResourceBrowser from './HitResourceBrowser.vue';
import {nextTick} from 'vue';
import {HitInput} from '@hit/components';
import HitJournalBrowser from './HitJournalBrowser.vue';
import HitTaxCodeBrowser from './HitTaxCodeBrowser.vue';
import HitDueDateBrowser from './HitDueDateBrowser.vue';
import {HitUtils} from '@hit/components/src/utils/hit/HitUtils';
import HitTransactionBrowser from './HitTransactionBrowser.vue';
export default {
  name: 'HitContainerEntityBrowser',
  components: {
    HitEquipmentBrowser,
    HitAddressBrowser,
    HitBrowserSelectionDisplay,
    HitStaffBrowser,
    HitProjectBrowser,
    HitProjectPartBrowser,
    HitResourceBrowser,
    HitJournalBrowser,
    HitTaxCodeBrowser,
    HitDueDateBrowser,
    HitTransactionBrowser,
    HitInput,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    attribute: {
      type: String,
      required: false,
    },
    container: {
      type: Object,
      required: false,
    },
    route: {
      type: String,
      required: true,
    },
    customDesignationFields: {
      type: Array,
      required: false,
    },
    designationFunction: {
      type: Function,
      default: null,
    },
    idField: {
      type: String,
      default: 'id',
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
    storeWholeObject: {
      type: Boolean,
      default: false,
    },
    storeInSnakeCase: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    transformSelectedItemToDbColumnValues: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    tagFilters: {
      type: Array,
      required: false,
      default: null,
    },
    forInjection: {
      type: Boolean,
      required: false,
      default: false,
    },
    customFilters: {
      type: Object,
      required: false,
      default: null,
    },
    validate: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * Displays an icon before the clear icon that redirects the page to the selected entity
     */
    showRedirect: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * In some cases, we want to check the content of a selected record before
     * triggering the update event
     * When function returns false, update is skipped
     * (Example: staff used in injection form needs to have a cost_resource_id)
     */
    browserSelectValidation: {
      type: Function,
      required: false,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      modalVisible: false,
      designation: '',
      configStore: useConfigurationStore(),
      editActive: false,
      designationInput: null,
    };
  },
  computed: {
    editable() {
      return (
        this.route === 'resource' &&
        this.storeWholeObject &&
        this.container.data[this.attribute] &&
        this.container.data[this.attribute].customisable
      );
    },
    entityId() {
      return this.storeWholeObject && this.container.data[this.attribute]
        ? this.container.data[this.attribute].id
        : this.container.data[this.attribute];
    },
    designationFields() {
      if (this.customDesignationFields) {
        return this.customDesignationFields;
      } else if (
        [
          'address',
          'equipment',
          'staff',
          'journal',
          'project',
          'project_part',
          'tax_code',
        ].includes(this.route)
      ) {
        return ['no', 'designation'];
      }
      if (['resource', 'due_date'].includes(this.route)) {
        return ['no', 'designation_' + this.configStore.userLanguageSnakeCase];
      } else {
        return ['designation'];
      }
    },
    finalDesignationFunction() {
      return this.designationFunction
        ? this.designationFunction
        : this.defaultDesignationFunction;
    },
  },

  watch: {
    entityId: {
      immediate: true,
      handler(newId) {
        if (!newId) {
          this.designation = '';
        } else {
          this.fetchDesignation();
        }
      },
    },
  },

  methods: {
    /**
     * Method executed when the user clicks on the redirect icon inside the selection display
     */
    redirectToLinkedEntity() {
      const view = `${HitUtils.snakeToCamel(this.route)}Detail`;
      this.$router.push({name: view, params: {id: this.entityId}});
    },

    /**
     * Generates the designation of the selected entity. We have two options:
     * - pass an object in the container attribute that contains the different fields
     * - when only the id is passed, the designation is fetched from the database
     */
    fetchDesignation() {
      try {
        this.designation = this.finalDesignationFunction(
          this.container.data[this.attribute]
        );
        return;
      } catch {
        /** We do nothing in the catch block **/
      }

      DataService.read(this.route, {
        attributes: this.designationFields.join(','),
        filters: {[this.idField]: 'eq.' + this.entityId},
      }).then((response) => {
        if (response && response.data && response.data[0]) {
          this.designation = this.finalDesignationFunction(response.data[0]);
        }
      });
    },

    /**
     * Default generation of the displayed entity designation
     */
    defaultDesignationFunction(item) {
      if (typeof item === 'string') {
        throw Error(
          'Default designation function needs an object to create the designation'
        );
      }
      const data = this.designationFields.map((field) => item[field]);
      if (data.some((element) => element === undefined)) {
        throw Error('Not all components are defined to create the designation');
      }
      return data.join(' - ');
    },

    transformKeys(item) {
      if (!this.storeInSnakeCase) {
        return item;
      }
      let result = {};
      for (let key in item) {
        let keySnake = key.replace(
          /[A-Z]/g,
          (match) => `_${match.toLowerCase()}`
        );
        result[keySnake] = item[key];
      }
      return result;
    },

    handleEdit() {
      this.editActive = true;
      const self = this;
      nextTick(() => {
        if (self?.designationInput?.$refs?.input) {
          this.designationInput.$refs.input.select();
        }
      });
    },

    handleChangeDesignation(value) {
      if (
        this.container.data[this.attribute] &&
        this.container.data[this.attribute].designation
      ) {
        //if we would pass the same object with different designation, it wouldn't do anything because HitContainer doesn't detect change
        let result = JSON.parse(
          JSON.stringify(this.container.data[this.attribute])
        );
        result.designation = value;
        this.container.handleChange(this.attribute, result);
        this.designation = value;
      }
      this.editActive = false;
    },

    selectItem(item) {
      this.modalVisible = false;
      try {
        if (this.browserSelectValidation) {
          const result = this.browserSelectValidation({
            item,
            route: this.route,
          });
          if (!result) return;
        }
      } catch (e) {
        console.error('Error during browser validations', e);
      }
      this.container.handleChange(
        this.attribute,
        this.storeWholeObject ? this.transformKeys(item) : item.id
      );
      try {
        this.designation = this.finalDesignationFunction(item);
      } catch {
        this.fetchDesignation();
      }
    },
  },
};
</script>
