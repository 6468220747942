import HitLoading from '../../components/loading/HitLoading.vue';
import {createApp, defineComponent} from 'vue';
export default function (app) {
  const getProperties = (value) => {
    if (typeof value === 'boolean') {
      return value;
    }

    if (value instanceof Object) {
      if (Object.keys(value) !== 0) {
        return value;
      } else {
        return {};
      }
    }

    return false;
  };

  const hasPropertyChanged = (oldProperties, newProperties) => {
    oldProperties = getProperties(oldProperties);
    newProperties = getProperties(newProperties);

    return (
      getLoadingVisibility(oldProperties) !==
        getLoadingVisibility(newProperties) ||
      (oldProperties.label || null) !== (newProperties.label || null) ||
      (oldProperties.mode || null) !== (newProperties.mode || null) ||
      (oldProperties.showMask || null) !== (newProperties.showMask || null)
    );
  };

  const getLoadingVisibility = (value) => {
    if (!value) {
      return false;
    }

    if (value.isLoading !== undefined) {
      return value.isLoading;
    } else {
      if (value.constructor === Object) {
        return false;
      } else {
        return value;
      }
    }
  };

  const updateLoading = (el, binding) => {
    // Check if the loading should appear or not
    if (getLoadingVisibility(binding.value)) {
      insertDom(el, binding);
    } else {
      // Remove relative class from parent as it is no longer required
      el.classList.remove('relative');

      // Set loading to invisible
      el.loadingInstance._instance.props.visible = false;
    }
  };

  const insertDom = (el, binding) => {
    // Set loading to visible
    el.loadingInstance._instance.props.visible = true;
    if (binding.value.label) {
      el.loadingInstance._instance.props.label = binding.value.label;
    }
    if (binding.value.mode) {
      el.loadingInstance._instance.props.mode = binding.value.mode;
    }
    if (typeof binding.value.showMask !== 'undefined') {
      el.loadingInstance._instance.props.showMask = binding.value.showMask;
    }

    // Add relative to parent to display loading correctly with absolute
    if (
      !el.classList.contains('relative') &&
      el.loadingInstance._instance.props.showMask
    ) {
      el.classList.add('relative');
    }
    // If the elements added by appendChild are the same one, they will not be added repeatedly
    el.appendChild(el.loadingMask);
  };

  app.directive('hitLoading', {
    mounted: function (el, binding) {
      // Create mask that will be appended to html element
      const mask = createApp(defineComponent({extends: HitLoading}));
      const maskMount = document.createElement('div');
      mask.mount(maskMount);

      //store component instance to access it later
      el.loadingInstance = mask;
      //store component dom to access it later
      el.loadingMask = maskMount;
      // If the value of binding is truthy, run updateLoading
      getLoadingVisibility(binding.value) && updateLoading(el, binding);
    },
    updated: function (el, binding) {
      if (hasPropertyChanged(binding.oldValue, binding.value)) {
        // update display
        updateLoading(el, binding);
      }
    },
    unmounted: function (el, binding) {
      el.loadingInstance.unmount();
    },
  });
}
