import {defineStore} from 'pinia';
import {DataService, useNotificationsStore} from '@hit/base';
import {translate} from '@hit/base/src/plugins/i18n/i18n';
import {globals} from 'hit-online-web-ui/src/globals';
import Axios from 'axios';
import {Base64} from 'js-base64';
import {HitUtils} from '@hit/components/src/utils/hit/HitUtils';

export const useUserProfileStore = defineStore('userProfile', {
  state: () => ({
    uuid: '',
    hitOnlineStaffId: '',
    username: '',
    locale: 'en-GB',
    roles: [],
    modules: [],
  }),
  getters: {
    /**
     * Returns the keycloak ID of the connected user (loaded during mounted of App)
     */
    keycloakId(state) {
      return state.uuid;
    },

    /**
     * Returns the staffId of the connected user (loaded during mounting of App)
     */
    staffId(state) {
      return state.hitOnlineStaffId;
    },

    /**
     * Returns the selected locale of the user in snake case format
     */
    localeSnakeCase(state) {
      return state.locale.replace('-', '_').toLowerCase();
    },
  },
  actions: {
    /**
     * Method executed when the application is loaded to only load the staffId once
     */
    async initUserProfileStore() {
      return Promise.all([
        this.loadStaffId(),
        this.setKeycloakId(),
        this.loadModules(),
      ]);
    },

    /**
     * Sets the staffID of the user
     */
    async loadStaffId() {
      const res = await DataService.read('staff', {
        attributes: 'id,staff_qualification(qualification_id)',
        filters: {
          user_id: `eq.${globals.$keycloak.idTokenParsed.sub}`,
        },
      });
      if (res && res.data && res.data.length > 0) {
        this.hitOnlineStaffId = res.data[0].id;
        this.roles = res.data[0].staff_qualification.map(
          (item) => item.qualification_id
        );
      }
    },

    async loadModules() {
      try {
        // Fetch user modules
        const userUrl = `${process.env.VUE_APP_ADMIN_API_URL}user/data`;
        const userConfig = {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            Authorization: `Bearer ${globals.$keycloak.token}`,
          },
        };

        const userResponse = await Axios.get(userUrl, userConfig);
        const userModules = userResponse.data[0].modules;
        const userModulesSet = new Set(userModules.map((m) => m.module_id));

        // Fetch all modules
        const moduleUrl = `${process.env.VUE_APP_ADMIN_API_URL}module/list`;
        const concatCredentials = `${process.env.VUE_APP_ADMIN_API_BASIC_AUTH_USERNAME}:${process.env.VUE_APP_ADMIN_API_BASIC_AUTH_PASSWORD}`;
        const token = Base64.encode(concatCredentials);
        const moduleConfig = {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            Authorization: `Basic ${token}`,
          },
        };

        const moduleResponse = await Axios.get(moduleUrl, moduleConfig);
        const modules = moduleResponse.data.map((module) => ({
          ...module,
          selected: !module.editable ? true : userModulesSet.has(module.id),
          description_key: module.designation_key,
        }));

        // Assign to the store's state using $patch to avoid issues with the Proxy
        this.$patch((state) => {
          state.modules = modules;
        });
      } catch (error) {
        console.error('Error loading modules:', error);
      }
    },

    async setKeycloakId() {
      return new Promise((resolve, reject) => {
        try {
          this.uuid = globals.$keycloak.idTokenParsed.sub;
          resolve(this.uuid);
        } catch (error) {
          reject(error);
        }
      });
    },

    setRoles(roles) {
      this.roles = roles;
    },

    /**
     * Checks if the user own the passed module to enable/disable features in UI
     */
    ownsModule(moduleName) {
      return this.modules.some(
        (obj) => obj['description_key'] === moduleName && obj['selected']
      );
    },
  },
});
