<template>
  <div v-if="visible">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'HitTab',
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onTabClick($event) {
      this.$emit('click', this.title);
      $event.stopImmediatePropagation();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
