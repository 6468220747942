<template>
  <div
    v-show="saving"
    class="absolute right-0"
  >
    <div class="saving-ficos">
      <hit-icon
        icon="save"
        color="accent"
      />
      {{ savingText }}
      <span class="dots-ficos"><span>.</span><span>.</span><span>.</span></span>
    </div>
  </div>
</template>

<script>
import {HitIcon} from '../icon';
import {translate} from '../../plugins/i18n/i18n.js';
export default {
  name: 'HitSaving',
  components: {
    HitIcon,
  },
  data() {
    return {
      saving: false,
    };
  },
  computed: {
    savingText() {
      return translate('hit-components.common.saving');
    },
  },
};
</script>

<style lang="scss">
.saving-ficos {
  @apply grid items-center gap-1 text-default;
  grid-template-columns: repeat(5, max-content);
}

.dots-ficos span {
  /**
     * Use the blink animation, which is defined above
     */
  animation-name: blink-ficos;
  /**
     * The animation should take 1.4 seconds
     */
  animation-duration: 1.4s;
  /**
     * It will repeat itself forever
     */
  animation-iteration-count: infinite;
  /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
  animation-fill-mode: both;
}

.dots-ficos span:nth-child(2) {
  /**
     * Starts the animation of the second dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: 0.4s;
}

.dots-ficos span:nth-child(3) {
  /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: 0.6s;
}

@keyframes blink-ficos {
  /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
  0% {
    opacity: 0.2;
  }
  /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
  20% {
    opacity: 1;
  }
  /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
  100% {
    opacity: 0.2;
  }
}
</style>
