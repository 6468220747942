import * as types from '../mutation-types';
import {normalize} from 'normalizr';
import {I18nKey} from '../../models';
import {HitArrayUtils, HitUUIDUtils} from '@hit/components';
import {BaseRootEntityService} from '../../api';
import {useNotificationsStore} from '@hit/base';
import {HitBannerNotificationItem} from '../pinia/notifications';

function normalizeData(data, schema, store) {
  let normalizedData = normalize(data, schema);
  for (const schemaKey in normalizedData.entities) {
    let schemaData = [];
    for (const entityId in normalizedData.entities[schemaKey]) {
      schemaData.push(normalizedData.entities[schemaKey][entityId]);
    }
    if (schemaData.length > 0) {
      store.commit(`${schemaKey}/${types.ADD_ITEM}`, schemaData);
    }
  }
  return normalizedData.result;
}

function denormalizeData(data, schema) {
  let result = {...data};
  for (const schemaKey in schema.schema) {
    if (
      Object.prototype.hasOwnProperty.call(result, schemaKey) &&
      result[schemaKey] != undefined
    ) {
      if (Array.isArray(result[schemaKey])) {
        result[schemaKey] = result[schemaKey].map((elt) => {
          return {
            id: elt,
          };
        });
      } else {
        result[schemaKey] = {id: result[schemaKey]};
      }
    }
  }
  return result;
}

//TODO refactor or rename function, since it makes changes to store without saying
function normalizeResponseData(response, schema, store) {
  response.data = normalizeData(response.data, schema, store);
}

function translateResponseData(response, modelCls) {
  let modelKeyTranslations = {};
  if (
    modelCls &&
    modelCls.getKeyTranslations &&
    modelCls.getKeyTranslations()
  ) {
    modelKeyTranslations = modelCls.getKeyTranslations();
  }
  for (let item of response.data) {
    for (let key in item) {
      if (key in modelKeyTranslations) {
        item[modelKeyTranslations[key]] = item[key];
        delete item[key];
      } else if (key !== snakeToCamelCase(key)) {
        item[snakeToCamelCase(key)] = item[key];
        delete item[key];
      }
    }
  }
}

function snakeToCamelCase(s) {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
}

//TODO service refactor
const pathTranslations = {
  'common/tag': 'tag',
  'address/communicationModeData': 'address_communication_mode_data',
  'address/followUp': 'address_follow_up',
  'common/communicationMode': 'communication_mode',
  'common/country': 'country',
  'form/formDefinition': 'form_definition',
  'form/form': 'form',
  'form/formTemplate': 'document',
};

async function createAllSubItems(
  item,
  schema,
  modelCls,
  service,
  dispatch,
  parentId
) {
  return queryAllSubItems(
    item,
    schema,
    modelCls,
    service,
    dispatch,
    'create',
    parentId
  );
}

async function updateAllSubItems(item, schema, modelCls, service, dispatch) {
  return queryAllSubItems(item, schema, modelCls, service, dispatch, 'update');
}

async function deleteRemovedItems(
  service,
  tableName,
  parentIdColumn,
  parentId,
  childIdColumn,
  idsToKeep
) {
  //TODO service error handling and more testing
  if (!(service instanceof BaseRootEntityService)) {
    return;
  }
  const params = {
    [childIdColumn]: 'not.in.(' + idsToKeep.join(',') + ')',
    [parentIdColumn]: 'eq.' + parentId + '',
  };
  return service.deleteItems({path: tableName, params});
}

async function queryAllSubItems(
  item,
  schema,
  modelCls,
  service,
  dispatch,
  queryType,
  parentId
) {
  let queryId = HitUUIDUtils.generate();
  let errorLogItem = item;
  let action;
  if (queryType === 'update') {
    action = 'Update';
  } else if (queryType === 'create') {
    action = 'Add';
  }
  dispatch(`request${action}Item`, {queryId});
  try {
    //generate id for inserting item into db
    if (queryType === 'create' && !item[schema._idAttribute]) {
      item[schema._idAttribute] = HitUUIDUtils.generate();
      if (parentId && service.parentIdColumn) {
        item[service.parentIdColumn] = parentId;
      }
    }
    //proxy instead of array
    let normData = normalize(item, schema);
    for (const schemaKey in normData.entities) {
      for (const entityId in normData.entities[schemaKey]) {
        const item = normData.entities[schemaKey][entityId];
        let filteredItem = {};
        const mtmRelations = modelCls.getMTMRelations();
        const mtoRelations = modelCls.getMTORelations();
        for (const field in item) {
          if (
            field in mtmRelations ||
            (queryType !== 'create' && field in mtoRelations)
          ) {
            continue;
          } else if (field in mtoRelations) {
            // when creating an item, this field may be required and needs to be specified at creation,
            // when updating it can be done in multiple steps
            if (queryType === 'create') {
              //TODO service if trying to create parent and child in one go, it will fail in the current order. Shouldn't happen because it would also be a problem without transactions, but if it does, order needs to be changed.
              filteredItem[mtoRelations[field].childIdColumn] = extractMTOid(
                item[field]
              );
            }
            continue;
          }
          filteredItem[field] = item[field];
        }
        //if filteredItem contains more than id field, query update
        if (Object.keys(filteredItem).length > 1) {
          errorLogItem = filteredItem;
          let path = pathTranslations[schemaKey];
          if (!path) {
            path = schemaKey;
          }
          if (path) {
            if (queryType === 'update') {
              await service.update({item: filteredItem, path: path});
            } else if (queryType === 'create') {
              await service.create({item: filteredItem, path: path});
            }
          }
        }
        for (const mtmKey in mtmRelations) {
          if (mtmKey in item) {
            const mtmRelation = mtmRelations[mtmKey];
            if (
              typeof item[mtmKey] === 'string' ||
              item[mtmKey] instanceof String
            ) {
              const mtmId = item[mtmKey];
              let mtmItem = {};
              mtmItem[mtmRelation.parentIdColumn] = entityId;
              mtmItem[mtmRelation.childIdColumn] = mtmId;
              errorLogItem = mtmItem;
              await service.upsert({
                item: mtmItem,
                path: mtmRelation.pivotTableName,
              });
              //delete items not preset in updated object
              await deleteRemovedItems(
                service,
                mtmRelation.pivotTableName,
                mtmRelation.parentIdColumn,
                entityId,
                mtmRelation.childIdColumn,
                [mtmId]
              );
            } else if (
              typeof item[mtmKey] === 'object' &&
              item[mtmKey] !== null
            ) {
              let idsToKeep = [];
              for (const index in item[mtmKey]) {
                //TODO service make "id" key dynamic
                const mtmId = item[mtmKey][index];
                let mtmItem = {};
                mtmItem[mtmRelation.parentIdColumn] = entityId;
                mtmItem[mtmRelation.childIdColumn] = mtmId;
                errorLogItem = mtmItem;
                await service.upsert({
                  item: mtmItem,
                  path: mtmRelation.pivotTableName,
                });
                idsToKeep.push(mtmId);
                //delete items not preset in updated object
              }
              await deleteRemovedItems(
                service,
                mtmRelation.pivotTableName,
                mtmRelation.parentIdColumn,
                entityId,
                mtmRelation.childIdColumn,
                idsToKeep
              );
            }
          }
        }
        for (const mtoKey in mtoRelations) {
          if (mtoKey in item) {
            const mtoRelation = mtoRelations[mtoKey];
            const mtoId = extractMTOid(item[mtoKey]);
            if (!mtoId) {
              continue;
            }
            let mtoItem = {};
            mtoItem[modelCls.getIdColumn()] = entityId;
            mtoItem[mtoRelation.childIdColumn] = mtoId;
            errorLogItem = mtoItem;
            await service.update({item: mtoItem});
          }
        }
      }
    }
    //TODO service fix dirty solution
    let response = {};
    response.data = item;
    dispatch(`receive${action}ItemSuccess`, {
      response: response,
      queryId: queryId,
      item: item,
    });
  } catch (error) {
    console.error('updateItem', error, errorLogItem);
    dispatch(`receive${action}ItemError`, {
      queryId: queryId,
      error: error,
      item: errorLogItem,
    });
    throw error;
  }
  return item;
}

function extractMTOid(item) {
  if (typeof item === 'string' || item instanceof String) {
    return item;
  } else if (typeof item === 'object' && item !== null) {
    for (const index in item) {
      return item[index];
    }
  }
  return null;
}

function getItemById(state, rootGetters, primaryStoreNamespace, itemId) {
  if (primaryStoreNamespace) {
    return rootGetters[`${primaryStoreNamespace}/item`](itemId);
  } else {
    return state.byId[itemId];
  }
}

export function makeEntityStore({
  modelCls,
  service,
  schema,
  ...childDataModels
}) {
  return makeStore({
    modelCls: modelCls,
    service: service,
    schema: schema,
    primaryStoreNamespace: undefined,
    ...childDataModels,
  });
}

export function makeForeignStore({
  modelCls,
  service,
  schema,
  primaryStoreNamespace,
  ...childDataModels
}) {
  return makeStore({
    modelCls: modelCls,
    service: service,
    schema: schema,
    primaryStoreNamespace: primaryStoreNamespace,
    ...childDataModels,
  });
}

function makeStore({
  modelCls,
  service,
  schema,
  primaryStoreNamespace,
  ...childDataModels
}) {
  return {
    namespaced: true,
    modules: {
      ...childDataModels,
    },
    actions: {
      deleteFromStore({commit}, id) {
        commit(types.REQUEST_DELETE_ITEM_FROM_STORE, id);
      },
      refreshListeningQueries({state, dispatch}) {
        Object.keys(state.queriesStatus.listening).forEach((queryId) => {
          dispatch('fetchItems', state.queriesStatus.listening[queryId]);
        });
      },
      requestAddItem({commit}, {queryId}) {
        commit(types.REQUEST_ADD_ITEM, queryId);
      },
      receiveAddItemSuccess({commit, dispatch}, {response, queryId}) {
        normalizeResponseData(response, schema, this);
        commit(types.RECEIVE_ADD_ITEM_SUCCESS, {response, queryId});
        dispatch('refreshListeningQueries', {});
      },
      receiveAddItemError({commit}, {queryId, error}) {
        commit(types.RECEIVE_ADD_ITEM_ERROR, {queryId, error});
        useNotificationsStore().insertBannerNotification(
          new I18nKey('hit-base.common.adding-or-creation-error', 1),
          HitBannerNotificationItem.DANGER,
          [error.response.data.errors]
        );
      },
      async addItem({state, dispatch, rootGetters}, {item, parentId}) {
        return await createAllSubItems(
          denormalizeData(item, schema),
          schema,
          modelCls,
          service,
          dispatch,
          parentId
        );
      },
      requestDeleteItem({commit}, {queryId}) {
        commit(types.REQUEST_DELETE_ITEM, queryId);
      },
      receiveDeleteItemSuccess({commit}, {queryId, response, item}) {
        commit(types.RECEIVE_DELETE_ITEM_SUCCESS, {queryId, response, item});
      },
      receiveDeleteItemError({commit}, {queryId, error, item}) {
        commit(types.RECEIVE_DELETE_ITEM_ERROR, {queryId, error, item});
        useNotificationsStore().insertBannerNotification(
          new I18nKey('common.deletion-error', 1),
          HitBannerNotificationItem.DANGER,
          [error.response.data.errors]
        );
      },
      async deleteItem({dispatch}, {item}) {
        const queryId = HitUUIDUtils.generate();
        dispatch('requestDeleteItem', {queryId});
        try {
          const response = await service.delete({item});
          dispatch('receiveDeleteItemSuccess', {
            response: response,
            queryId: queryId,
            item: item,
          });
          return true;
        } catch (error) {
          console.error('deleteItem', error, item);
          dispatch('receiveDeleteItemError', {
            queryId: queryId,
            error: error,
            item: item,
          });
          throw error;
        }
      },
      requestFetchItems({commit}, queryId) {
        commit(types.REQUEST_FETCH_ITEMS, queryId);
      },
      receiveFetchItemsError({commit}, {queryId, error}) {
        commit(types.RECEIVE_FETCH_ITEMS_ERROR, queryId, error);
        useNotificationsStore().insertBannerNotification(
          new I18nKey('common.loading-error', 1),
          HitBannerNotificationItem.DANGER,
          [error.response.data.errors]
        );
      },
      receiveFetchItemsSuccess({commit}, {response, id, query}) {
        translateResponseData(response, modelCls);
        normalizeResponseData(response, [schema], this);
        commit(types.RECEIVE_FETCH_ITEMS_SUCCESS, {response, id, query});
      },
      fetchItems({state, dispatch}, {query, listenAdd = false}) {
        const queryId = `ITEMS_${JSON.stringify(query)}`;
        dispatch('requestFetchItems', {queryId, query, listenAdd});

        // if (listenAdd) {
        //     state.queriesStatus.listening[queryId] = { query, listenAdd };
        // } else {
        //     delete state.queriesStatus.listening[queryId];
        // }
        service
          .fetchItems(query)
          .then((response) => {
            dispatch('receiveFetchItemsSuccess', {
              response,
              id: queryId,
              query,
            });
          })
          .catch((error) =>
            dispatch('receiveFetchItemsError', {queryId: queryId, error: error})
          );
        return queryId;
      },
      requestFetchItem({commit}, queryId) {
        commit(types.REQUEST_FETCH_ITEM, queryId);
      },
      receiveFetchItemError({commit}, {queryId, error}) {
        commit(types.RECEIVE_FETCH_ITEM_ERROR, queryId, error);
        useNotificationsStore().insertBannerNotification(
          new I18nKey('common.loading-error', 1),
          HitBannerNotificationItem.DANGER,
          [error.response.data.errors]
        );
      },
      receiveFetchItemSuccess({commit}, {response, id, query}) {
        translateResponseData(response, modelCls);
        normalizeResponseData(response, [schema], this);
        commit(types.RECEIVE_FETCH_ITEM_SUCCESS, {response, id, query});
      },
      fetchItem({dispatch}, {query}) {
        const queryId = `ITEM_${JSON.stringify(query)}`;
        dispatch('requestFetchItem', queryId);

        service
          .fetchItem(query)
          .then((response) => {
            dispatch('receiveFetchItemSuccess', {response, id: queryId, query});
          })
          .catch((error) =>
            dispatch('receiveFetchItemError', {queryId: queryId, error: error})
          );
        return queryId;
      },
      requestUpdateItem({commit}, {queryId}) {
        commit(types.REQUEST_UPDATE_ITEM, queryId);
      },
      receiveUpdateItemSuccess({commit}, {queryId, response, item}) {
        if (response.data instanceof Array) {
          if (response.data.length > 0) {
            response.data = response.data[0];
          }
        }
        normalizeResponseData(response, schema, this);
        commit(types.RECEIVE_UPDATE_ITEM_SUCCESS, {queryId, response, item});
      },
      receiveUpdateItemError({commit}, {queryId, error, item}) {
        commit(types.RECEIVE_UPDATE_ITEM_ERROR, {queryId, error, item});
        useNotificationsStore().insertBannerNotification(
          new I18nKey('hit-base.common.update-error', 1),
          HitBannerNotificationItem.DANGER,
          [error.response.data.errors]
        );
      },
      async updateItem({dispatch}, {item}) {
        return await updateAllSubItems(
          denormalizeData(item, schema),
          schema,
          modelCls,
          service,
          dispatch
        );
      },
      // FIXME : Extract those methods for forms only
      async submitItem({dispatch}, {item, parentId}) {
        const queryId = HitUUIDUtils.generate();
        dispatch('requestUpdateItem', {queryId});
        try {
          const denormalizedItem = denormalizeData(item, schema);
          const response = await service.submit({
            item: denormalizedItem,
            parentId,
          });
          dispatch('receiveUpdateItemSuccess', {
            response: response,
            queryId: queryId,
            item: item,
          });
          return response.data;
        } catch (error) {
          console.error('submitItem', error, item);
          dispatch('receiveUpdateItemError', {
            queryId: queryId,
            error: error,
            item: item,
          });
          throw error;
        }
      },
      // FIXME : Extract those methods for forms only
      async validateItem({dispatch}, {item, parentId, staffId}) {
        const queryId = HitUUIDUtils.generate();
        dispatch('requestUpdateItem', {queryId});
        try {
          const denormalizedItem = denormalizeData(item, schema);
          const response = await service.validate({
            item: denormalizedItem,
            parentId,
            staffId,
          });
          dispatch('receiveUpdateItemSuccess', {
            response: response,
            queryId: queryId,
            item: item,
          });
          return response.data;
        } catch (error) {
          console.error('validateItem', error, item);
          dispatch('receiveUpdateItemError', {
            queryId: queryId,
            error: error,
            item: item,
          });
          throw error;
        }
      },
    },
    mutations: {
      [types.REQUEST_ADD_ITEM](state, queryId) {
        state.queriesStatus.creating[queryId] = true;
      },
      [types.RECEIVE_ADD_ITEM_ERROR](state, {queryId, error}) {
        state.queriesStatus.creating[queryId] = false;
        state.queriesStatus.errors[queryId] = error;
      },
      [types.RECEIVE_ADD_ITEM_SUCCESS](state, {response, queryId}) {
        const item = response.data;
        state.queriesStatus.creating[queryId] = item.id;
        delete state.queriesStatus.errors[queryId];
      },
      [types.REQUEST_DELETE_ITEM_FROM_STORE](state, id) {
        delete state.byId[id];
      },
      [types.REQUEST_DELETE_ITEM]({queryId}) {
        console.debug('request_delete_item', queryId);
      },
      [types.RECEIVE_DELETE_ITEM_ERROR]({queryId, error, item}) {
        console.error('request_delete_item', error, queryId, item);
      },
      [types.RECEIVE_DELETE_ITEM_SUCCESS](state, {item}) {
        // Remove item from query response
        //TODO Optimize
        Object.keys(state.cache.queries).forEach((cacheQueryId) => {
          const query = state.cache.queries[cacheQueryId];
          if (Array.isArray(query.response.data)) {
            query.response.data = query.response.data.filter(
              (x) => x != item.id
            );
          } else {
            if (query.response.data == item.id) {
              delete state.cache.queries[cacheQueryId];
            }
          }
        });
        // remove item from state
        if (state.byId) {
          delete state.byId[item.id];
        }
      },
      [types.REQUEST_FETCH_ITEMS](state, {queryId, query, listenAdd}) {
        state.queriesStatus.loading[queryId] = true;

        if (listenAdd) {
          state.queriesStatus.listening[queryId] = {query, listenAdd};
        } else {
          delete state.queriesStatus.listening[queryId];
        }
      },
      [types.RECEIVE_FETCH_ITEMS_ERROR](state, {queryId, error}) {
        state.queriesStatus.loading[queryId] = false;
        state.queriesStatus.errors[queryId] = error;
      },
      [types.RECEIVE_FETCH_ITEMS_SUCCESS](state, {response, id, query}) {
        delete state.queriesStatus.errors[id];
        state.queriesStatus.loading[id] = false;
        const queryDetails = {
          createdAt: Date.now(),
          id,
          response,
          query,
        };
        state.cache.queries[id] = queryDetails;
      },
      [types.REQUEST_FETCH_ITEM](state, queryId) {
        state.queriesStatus.loading[queryId] = true;
      },
      [types.RECEIVE_FETCH_ITEM_ERROR](state, {queryId, error}) {
        state.queriesStatus.loading[queryId] = false;
        state.queriesStatus.errors[queryId] = error;
      },
      [types.RECEIVE_FETCH_ITEM_SUCCESS](state, {response, id, query}) {
        delete state.queriesStatus.errors[id];
        state.queriesStatus.loading[id] = false;
        const queryDetails = {
          createdAt: Date.now(),
          id,
          response,
          query,
        };
        state.cache.queries[id] = queryDetails;
      },
      [types.ADD_ITEM](state, data) {
        HitArrayUtils.asArray(data).forEach((item) => {
          // Keep fields to update since fromJson will create object with all fields of the model class
          let fieldsToUpdate = Object.keys(item);

          if (!(item instanceof modelCls)) {
            item = modelCls.fromJson(item);
          }

          if (state.byId[item.id]) {
            //update existing item
            fieldsToUpdate.forEach(
              (property) => (state.byId[item.id][property] = item[property])
            );
          } else {
            //add item
            state.byId[item.id] = item;
          }
        });
      },
      [types.REQUEST_UPDATE_ITEM]({queryId}) {
        // console.debug('request_update_item', queryId);
      },
      [types.RECEIVE_UPDATE_ITEM_ERROR]({queryId, error, item}) {
        // console.error('request_update_item', error, queryId, item);
      },
      [types.RECEIVE_UPDATE_ITEM_SUCCESS](state, {item}) {
        // console.debug('RECEIVE_UPDATE_ITEM_SUCCESS', item);
      },
    },
    getters: {
      items: (state, getters, rootState, rootGetters) => (queryId) => {
        if (!state.cache.queries[queryId]) return [];
        let result = state.cache.queries[queryId].response.data.map((id) =>
          getItemById(state, rootGetters, primaryStoreNamespace, id)
        );
        return result;
      },
      itemCreating: (state) => (queryId) => {
        if (!state.queriesStatus.creating[queryId]) return false;
        else return state.creating.loading[queryId];
      },
      itemsLoading: (state) => (queryId) => {
        if (!state.queriesStatus.loading[queryId]) return false;
        else return state.queriesStatus.loading[queryId];
      },
      item: (state, getters, rootState, rootGetters) => (id) => {
        return (
          getItemById(state, rootGetters, primaryStoreNamespace, id) || null
        );
      },
      response: (state, getters, rootState, rootGetters) => (queryId) => {
        if (!state.cache.queries[queryId]) return null;
        const response = state.cache.queries[queryId].response;
        const data = response.data;
        return {
          ...response,
          data: Array.isArray(data)
            ? data.map((id) =>
                getItemById(state, rootGetters, primaryStoreNamespace, id)
              )
            : getItemById(state, rootGetters, primaryStoreNamespace, data.id),
        };
      },
    },
    state: {
      cache: {
        usage: {},
        queries: {},
      },
      queriesStatus: {
        creating: {},
        errors: {},
        listening: {},
        loading: {},
        updating: {},
      },
      byId: primaryStoreNamespace ? undefined : {}, // use primary store getter instead
    },
  };
}
