<template>
  <hit-input-signature
    :value="container.data[attribute]"
    :disabled="container.attributes[attribute].readOnly"
    @change="(value) => container.handleChange(attribute, value)"
  />
</template>
<script>
import {HitInputSignature} from '@hit/components';
export default {
  name: 'HitContainerInputSignature',
  components: {
    HitInputSignature,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
  },
};
</script>
