import BaseModel from '../BaseModel';

export default class Equipment extends BaseModel {
  constructor({
    id,
    no,
    designation,
    active,
    custom_data,
    creationDate,
    modificationDate,
  }) {
    super();
    this.id = id;
    this.no = no;
    this.designation = designation;
    this.active = active;
    this.custom_data = custom_data;
    this.creationDate = creationDate;
    this.modificationDate = modificationDate;
  }
  static factory() {
    return new Equipment({active: true});
  }

  static getMTMRelations() {
    return {
      tags: {
        pivotTableName: 'equipment_tag',
        parentIdColumn: 'equipment_id',
        childIdColumn: 'tag_id',
        childTableName: 'tag',
      },
    };
  }

  static getKeyTranslations() {
    return {
      tag: 'tags',
    };
  }
}
