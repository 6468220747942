import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "h-screen w-screen flex flex-col overflow-hidden" };
const _hoisted_2 = { class: "h-10/100 w-full" };
const _hoisted_3 = { class: "flex-grow overflow-scroll bg-backgroundOfForm" };
const _hoisted_4 = { class: "content" };
const _hoisted_5 = { class: "content-container" };
const _hoisted_6 = { class: "card-container" };
const _hoisted_7 = { class: "card-title" };
const _hoisted_8 = { class: "card-container" };
const _hoisted_9 = { class: "adminui-card" };
const _hoisted_10 = { class: "subtext" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AdminUIHeader = _resolveComponent("AdminUIHeader");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_AdminUIHeader)
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("h2", null, _toDisplayString(_ctx.t('admin-ui.welcome_on_admin_part')), 1),
                        _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("h6", null, _toDisplayString(_ctx.t('admin-ui.click_on_card')), 1)
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("span", {
                                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('clickOnSignUp', _ctx.$i18n.locale)))
                            }, [
                                _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("h1", null, _toDisplayString(_ctx.t('admin-ui.sign_up')), 1),
                                    _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t('admin-ui.click_here_to_signup')), 1)
                                ])
                            ])
                        ])
                    ])
                ])
            ])
        ])
    ]));
}
