import BaseModel from '../BaseModel';

export default class ProjectPart extends BaseModel {
  // méthode constructor
  constructor({id, no, designation, active}) {
    super();
    this.id = id;
    this.no = no;
    this.designation = designation;
    this.active = active;
  }

  static factory() {
    return new ProjectPart({active: true});
  }

  static getMTMRelations() {
    return {
      tags: {
        pivotTableName: 'project_part_tag',
        parentIdColumn: 'project_id',
        childIdColumn: 'tag_id',
        childTableName: 'tag',
      },
    };
  }

  static getKeyTranslations() {
    return {
      tag: 'tags',
    };
  }
}
