import {translate} from '@hit/base/src/plugins/i18n/i18n';
import {HitDateUtils} from '@hit/components/src/utils/date/HitDateUtils';

export class PrintConfigService {
  static REUSABLE_CONFIGS = {
    storeFile: {
      id: 'store-file',
      type: 'boolean', // The type needs to match the used type in hit-input-generic component
      label: 'hit-app.printer-configuration.store-file',
      default: false,
    },
  };

  static TEMPLATE_CONFIGS = {
    actionSummary: [
      {
        id: 'fromDate',
        type: 'datetime-local', // The type needs to match the used type in hit-input-generic component
        label: 'hit-base.common.from',
        default: HitDateUtils.setToLocalMidnight(new Date()),
      },
      {
        id: 'toDate',
        type: 'datetime-local',
        label: 'hit-base.common.to',
        default: HitDateUtils.setToLocalEndOfDay(new Date()),
      },
    ],
  };

  /**
   * Returns a list of objects with one object per config that can be set for this template
   */
  static getTemplateConfigs(templateType) {
    return this.TEMPLATE_CONFIGS?.[templateType] ?? [];
  }
}
