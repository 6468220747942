<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-journal')"
    route="journal"
    auto-complete-id="journal"
    :table-attributes="tableAttributes"
    :default-sort="{property: 'no', order: 'asc'}"
    :filter-id="filterId"
    :filter-id-column="filterIdColumn"
    :only-load-active-records="onlyLoadActiveRecords"
    :custom-filters="customFilters"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
//dont shorten import
import {
  HitContainerAttribute,
  ATTRIBUTE_TYPES,
} from '../../container/HitContainerAttribute';
import {useI18n} from 'vue-i18n';
export default {
  name: 'HitJournalBrowser',
  components: {
    HitEntityBrowser,
  },
  props: {
    filterId: {
      type: String,
      required: false,
    },
    filterIdColumn: {
      type: String,
      required: false,
    },
    customFilters: {
      type: Object,
      required: false,
      default: null,
    },
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      tableAttributes: {
        number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'no',
          tableProperties: {
            header: this.t('hit-app.common.number'),
            maxWidth: '0.5fr',
          },
        }),
        designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          tableProperties: {
            header: this.t('hit-base.common.designation'),
            mainSearch: true,
            maxWidth: '1fr',
          },
        }),
        journal_type: new HitContainerAttribute(ATTRIBUTE_TYPES.ENUMERATION, {
          enumType: 'JOURNAL_TYPE',
          tableProperties: {
            header: this.t('hit-app.journal.journal-type'),
            maxWidth: '1fr',
          },
        }),
        transaction_type: new HitContainerAttribute(
          ATTRIBUTE_TYPES.ENUMERATION,
          {
            enumType: 'TRANSACTION_TYPE',
            tableProperties: {
              header: this.t('hit-app.journal.transaction-type'),
              maxWidth: '1fr',
            },
          }
        ),
      },
    };
  },
};
</script>
