import {schema} from 'normalizr';
import {communicationModeSchema, tagSchema} from '../common/schema';
import {staffSchema} from '../staff/schema';

const addressCommunicationModeDataSchema = new schema.Entity(
  'address/communicationModeData',
  {
    communicationMode: communicationModeSchema,
  }
);
const addressFollowUpSchema = new schema.Entity('address/followUp', {
  communicationMode: communicationModeSchema,
  author: staffSchema,
});
const addressSchema = new schema.Entity('address', {
  communicationModeData: [addressCommunicationModeDataSchema],
  followUps: [addressFollowUpSchema],
  tags: [tagSchema],
});

export {
  addressSchema,
  addressCommunicationModeDataSchema,
  addressFollowUpSchema,
};
