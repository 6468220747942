<template>
  <div class="hit-module-list-row">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'HitModuleListRow',
};
</script>

<style scoped lang="scss">
.hit-module-list-row {
  @apply grid h-auto min-h-8;
  grid-template-columns: var(--grid-template-columns);
}
</style>
