import {makeEntityStore} from '../../../helpers/factory';
import {User} from '../../../../models';
import {keycloakUserSchema} from '../../../../models';
import KeycloakUserService from '../../../../api/KeycloakUserService';

export default makeEntityStore({
  modelCls: User,
  service: new KeycloakUserService(),
  schema: keycloakUserSchema,
});
