import {
  minValue,
  maxValue,
  maxLength,
  minLength,
  required,
  integer,
  helpers,
} from '@vuelidate/validators';

const TRANSLATION_KEY_MAP = {
  required: 'hit-components.common.required-field',
  minLength: 'hit-components.common.min-length-field',
  minListLength: 'hit-components.common.min-list-length-field',
  minValue: 'hit-components.common.min-value-field',
  maxLength: 'hit-components.common.max-length-field',
  maxListLength: 'hit-components.common.max-list-length-field',
  maxValue: 'hit-components.common.max-value-field',
  integer: 'hit-components.common.integer-field',
  requiredInteger: 'hit-components.common.required-integer-field',
  phone: 'hit-components.common.phone-field',
  mail: 'hit-components.common.mail-field',
  atLeastOneLocalizedDesignation:
    'hit-components.common.at-least-one-localized-designation-required',
  validYaml: 'hit-components.common.valid-yaml',
  mustBeSamePassword: 'hit-components.common.must-be-same-password',
  mustBeSameMail: 'hit-components.common.must-be-same-mail',
};

function ValidatorFactory(result, constraints, constraintId, isList = false) {
  let constraintFound = false;
  switch (constraintId) {
    case 'required':
      result['required'] = required;
      constraintFound = true;
      break;
    case 'minLength':
      result[isList ? 'minListLength' : 'minLength'] = minLength(
        constraints.minLength
      );
      constraintFound = true;
      break;
    case 'minValue':
      result['minValue'] = minValue(constraints.minValue);
      constraintFound = true;
      break;
    case 'maxLength':
      result[isList ? 'maxListLength' : 'maxLength'] = maxLength(
        constraints.maxLength
      );
      constraintFound = true;
      break;
    case 'maxValue':
      result['maxValue'] = maxValue(constraints.maxValue);
      constraintFound = true;
      break;
    case 'multipleValues':
      result['multipleValues'] = multipleValues(constraints.multipleValues);
      constraintFound = true;
      break;
    case 'integer':
      result['integer'] = integer;
      constraintFound = true;
      break;
    case 'mail':
      result['mail'] = mail(constraints.mail);
      constraintFound = true;
      break;
    case 'phone':
      result['phone'] = phone(constraints.phone);
      constraintFound = true;
      break;
  }
  return constraintFound;
}

class BaseConstraint {
  addToValidator(validator, key, value) {
    validator[key] = value;
  }
}

class RequiredConstraint extends BaseConstraint {
  constructor(required) {
    super();
    this.required = required;
  }

  addToValidator(validator) {
    if (this.required) {
      super.addToValidator(validator, 'required', required);
    }
  }

  getJsonAttribute() {
    return 'required';
  }

  toJson() {
    return true;
  }
}

const multipleValues = (value, vm) =>
  !helpers.req(value) ||
  (vm
    ? Array.isArray(value) && value.length > 0
    : !Array.isArray(value) && value.length === 1);

class MultipleValuesConstraint extends BaseConstraint {
  constructor(multiple) {
    super();
    this.multiple = multiple;
  }

  addToValidator(validator) {
    if (this.multiple) {
      super.addToValidator(validator, 'multiple', multipleValues);
    }
  }

  getJsonAttribute() {
    return 'multiple';
  }

  toJson() {
    return this.multiple;
  }
}

class MinLengthConstraint extends BaseConstraint {
  constructor(minLength) {
    super();
    this.minLength = minLength;
  }

  addToValidator(validator) {
    if (this.minLength !== undefined) {
      super.addToValidator(validator, 'minLength', minLength(this.minLength));
    }
  }

  getJsonAttribute() {
    return 'minLength';
  }

  toJson() {
    return this.minLength;
  }
}

class MaxLengthConstraint extends BaseConstraint {
  constructor(maxLength) {
    super();
    this.maxLength = maxLength;
  }

  addToValidator(validator) {
    if (this.maxLength !== undefined) {
      super.addToValidator(validator, 'maxLength', maxLength(this.maxLength));
    }
  }

  getJsonAttribute() {
    return 'maxLength';
  }

  toJson() {
    return this.maxLength;
  }
}

class MinListLengthConstraint extends BaseConstraint {
  constructor(minLength) {
    super();
    this.minLength = minLength;
  }

  addToValidator(validator) {
    if (this.minLength !== undefined) {
      super.addToValidator(
        validator,
        'minListLength',
        minLength(this.minLength)
      );
    }
  }

  getJsonAttribute() {
    return 'minLength';
  }

  toJson() {
    return this.minLength;
  }
}

class MaxListLengthConstraint extends BaseConstraint {
  constructor(maxLength) {
    super();
    this.maxLength = maxLength;
  }

  addToValidator(validator) {
    if (this.maxLength !== undefined) {
      super.addToValidator(
        validator,
        'maxListLength',
        maxLength(this.maxLength)
      );
    }
  }

  getJsonAttribute() {
    return 'maxLength';
  }

  toJson() {
    return this.maxLength;
  }
}

class MinValueConstraint extends BaseConstraint {
  constructor(minValue) {
    super();
    this.minValue = minValue;
  }

  addToValidator(validator) {
    if (this.minValue !== undefined) {
      super.addToValidator(validator, 'minValue', minValue(this.minValue));
    }
  }

  getJsonAttribute() {
    return 'minValue';
  }

  toJson() {
    return this.minValue;
  }
}
class MaxValueConstraint extends BaseConstraint {
  constructor(maxValue) {
    super();
    this.maxValue = maxValue;
  }

  addToValidator(validator) {
    if (this.maxValue !== undefined) {
      super.addToValidator(validator, 'maxValue', maxValue(this.maxValue));
    }
  }

  getJsonAttribute() {
    return 'maxValue';
  }

  toJson() {
    return this.maxValue;
  }
}

class IntegerConstraint extends BaseConstraint {
  constructor(integer) {
    super();
    this.integer = integer;
  }

  addToValidator(validator) {
    if (this.integer) {
      super.addToValidator(validator, 'integer', integer);
    }
  }

  getJsonAttribute() {
    return 'integer';
  }

  toJson() {
    return this.integer;
  }
}

const phone = helpers.regex(/^([0-9+-.\s/])*$/);

class PhoneConstraint extends BaseConstraint {
  constructor(phone) {
    super();
    this.phone = phone;
  }

  addToValidator(validator) {
    if (this.phone) {
      super.addToValidator(validator, 'phone', phone);
    }
  }

  getJsonAttribute() {
    return 'phone';
  }

  toJson() {
    return this.phone;
  }
}

const mail = helpers.regex(/^.+@.+\..+$/);

class MailConstraint extends BaseConstraint {
  constructor(mail) {
    super();
    this.mail = mail;
  }

  addToValidator(validator) {
    if (this.mail) {
      super.addToValidator(validator, 'mail', mail);
    }
  }

  getJsonAttribute() {
    return 'mail';
  }

  toJson() {
    return this.mail;
  }
}

const validYaml = () =>
  helpers.withParams({type: 'validYaml'}, function (value) {
    try {
      window.jsyaml.load(value);
      return true;
    } catch (e) {
      return false;
    }
  });

class ValidYamlConstraint extends BaseConstraint {
  constructor(validYaml) {
    super();
    this.validYaml = validYaml;
  }

  addToValidator(validator) {
    if (this.validYaml) {
      super.addToValidator(validator, 'validYaml', validYaml());
    }
  }

  getJsonAttribute() {
    return 'validYaml';
  }

  toJson() {
    return this.validYaml;
  }
}

function getValidationErrors(validationState) {
  // seen at https://medium.com/@crishellco/vuelidate-displaying-errors-simplified-156147b123d6
  if (!validationState || !validationState.$error) {
    return [];
  }

  const validationKeys = validationState.$errors.map((x) => x.$validator);

  // const validationKeys = Object.keys(validationState.$params);
  const isIntegerAndRequired =
    validationKeys.includes('integer') && validationKeys.includes('required');
  const isRequired = validationKeys.includes('required');

  return validationState.$errors.reduce((errors, $error) => {
    const validatorName = $error.$validator;
    let translationKey;

    if (isIntegerAndRequired && validatorName === 'required') {
      translationKey = TRANSLATION_KEY_MAP['requiredInteger'];
    } else if (validatorName === 'sameAsPassword') {
      translationKey = TRANSLATION_KEY_MAP['mustBeSamePassword'];
    } else if (validatorName === 'sameAsMail') {
      translationKey = TRANSLATION_KEY_MAP['mustBeSameMail'];
    } else if (validatorName === 'email') {
      translationKey = TRANSLATION_KEY_MAP['mail'];
    }
    // else if (validatorName === 'communicationMode' && validationState.$params[validatorName]["$sub"] &&
    //     validationState.$params[validatorName]["$sub"].length > 0 && validationState.$params[validatorName]["$sub"][0]["type"]) {
    //     translationKey = TRANSLATION_KEY_MAP[validationState.$params[validatorName]["$sub"][0]["type"]];
    // } // TODO migr test and replace
    else {
      translationKey = TRANSLATION_KEY_MAP[validatorName];
    }
    let message;
    let params = {};
    if (translationKey) {
      const paramKeys = Object.keys($error.$params);

      params = paramKeys.reduce((obj, key) => {
        obj[key] = $error.$params[key];
        if (obj[key])
          if (isIntegerAndRequired && validatorName === 'required') {
            obj[key] = Object.assign(obj[key], $error.$params['integer']);
          }
        return obj;
      }, {});

      message = {
        translationKey,
        params,
      };
    } else {
      message = {
        translationKey: 'hit-components.common.validation-error',
        params,
      };
    }
    errors.push(message);
    return errors;
  }, []);
}

/**
 * To be used instead of helpers.req(value) to reject whitespaces-only strings.
 * inspired from internal code of required(), see https://github.com/vuelidate/vuelidate/blob/master/src/validators/required.js
 */
function req_not_blank(value) {
  if (typeof value === 'string') {
    return helpers.req(value.trim());
  } else {
    return helpers.req(value);
  }
}

const atLeastOneFieldSet = (fields) =>
  helpers.withParams(
    {type: 'atLeastOneFieldSet', fields: fields},
    function (value, parentVm) {
      return (
        fields.map((field) => parentVm[field]).filter(req_not_blank).length > 0
      );
    }
  );

function getRequiredValidations() {
  let validations = {};
  new RequiredConstraint(true).addToValidator(validations);
  return validations;
}

function addAtLeastOneLocalizedFieldConstraints(
  validations,
  field,
  languages,
  constraintName,
  getLocalizedFieldFn
) {
  if (languages && languages.length > 0) {
    let localizedFields = languages.map((language) =>
      getLocalizedFieldFn(field, language)
    );
    for (let localizedField of localizedFields) {
      if (!Object.prototype.hasOwnProperty.call(validations, localizedField)) {
        validations[localizedField] = {};
      }
      validations[localizedField][constraintName] = atLeastOneFieldSet([
        ...localizedFields,
      ]);
    }
    //TODO migr probably needs fixing
    if (
      !Object.prototype.hasOwnProperty.call(validations, '$validationGroups')
    ) {
      validations['$validationGroups'] = {};
    }
    validations['$validationGroups'][field] = [...localizedFields]; // We need a copy of the array because HitForm will modify it
  }
}

function allCompanyLanguagesFieldsRequired(
  validations,
  field,
  requiredLocales,
  constraintName,
  getLocalizedFieldFn
) {
  if (requiredLocales.length > 0) {
    let localizedFields = requiredLocales.map((language) =>
      getLocalizedFieldFn(field, language)
    );
    for (let localizedField of localizedFields) {
      validations[localizedField] = {required};
    }
  }
}

export {
  BaseConstraint,
  getValidationErrors,
  RequiredConstraint,
  MinLengthConstraint,
  MaxLengthConstraint,
  MinListLengthConstraint,
  MaxListLengthConstraint,
  MinValueConstraint,
  MaxValueConstraint,
  MultipleValuesConstraint,
  IntegerConstraint,
  PhoneConstraint,
  MailConstraint,
  ValidYamlConstraint,
  atLeastOneFieldSet,
  phone,
  mail,
  ValidatorFactory,
  getRequiredValidations,
  addAtLeastOneLocalizedFieldConstraints,
  allCompanyLanguagesFieldsRequired,
};
