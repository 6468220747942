<template>
  <div
    v-show="visible"
    class="grid items-center justify-center grid-flow-col"
    :class="{
      'loading-mask-ficos': showMask,
    }"
  >
    <div
      v-if="mode === 'spinner'"
      class="spinner-loading-ficos ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"
      :class="{
        'mr-2': label,
      }"
    />

    {{ label }}
    <div
      v-if="mode === 'dots'"
      class="dots-loading-ficos"
    />
  </div>
</template>

<script>
//can be tested with v-hit-loading="{
// isLoading: true,
//     mode: 'spinner',
//     label: 'tesadhgateht',
//     showMask: true
// }"
export default {
  name: 'HitLoading',
  props: {
    /**
     * Visibility of the loading
     */
    visible: {
      type: Boolean,
      default: false,
    },
    /**
     * Mode of the loading
     */
    mode: {
      type: String,
      default: 'dots',
      validator(value) {
        return ['dots', 'spinner'].indexOf(value) !== -1;
      },
    },
    /**
     * Label of the loading
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Display mask of the loading
     */
    showMask: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.loading-mask-ficos {
  @apply z-50 absolute bg-default m-0 top-0 right-0 bottom-0 left-0 transition-opacity opacity-75;
}

.spinner-loading-ficos {
  border-top-color: var(
    --color-accent-light
  ) !important; /* gets overriden by .border-gray-200 otherwise */
  -webkit-animation: spinner-ficos 1.5s linear infinite;
  animation: spinner-ficos 1.5s linear infinite;
}

@-webkit-keyframes spinner-ficos {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner-ficos {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dots-loading-ficos:after {
  @apply inline-block text-light;
  animation: dotty-ficos steps(1, end) 2000ms infinite;
  content: '';
}

@keyframes dotty-ficos {
  0% {
    content: '.\00a0\00a0';
  }
  33% {
    content: '..\00a0';
  }
  66% {
    content: '...';
  }
  100% {
    content: '.\00a0\00a0';
  }
}
</style>
