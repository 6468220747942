<template>
  <div
    :style="dropdownStyle"
    class="absolute rounded-md bg-panel ring-1 ring-black ring-opacity-5 text-panel-light py-1 dropdown-content w-40 z-40"
  >
    <hit-dropdown-item
      v-if="searchable(itemProperty)"
      :close-on-click="false"
    >
      <hit-input
        :instant-focus="true"
        width-class="w-2/3"
        :reset-after-change="true"
        @change="(v) => $emit('addFilter', '∋', v)"
      />
    </hit-dropdown-item>
    <template v-if="sortable(itemProperty)">
      <hit-dropdown-item
        :close-on-click="false"
        :icon="sortingState === NOT_SORTING ? 'check' : null"
        :label="t('hit-components.common.unsorted')"
        @item-clicked="
          sortingState !== NOT_SORTING && $emit('setSort', null);
          additionalFiltersVisible = false;
        "
      />
      <hit-dropdown-item
        :close-on-click="false"
        :icon="sortingState === SORTING_ASC ? 'check' : null"
        :label="t('hit-components.common.ascending')"
        @item-clicked="
          sortingState !== SORTING_ASC && $emit('setSort', 'asc');
          additionalFiltersVisible = false;
        "
      />
      <hit-dropdown-item
        :close-on-click="false"
        :icon="sortingState === SORTING_DESC ? 'check' : null"
        :label="t('hit-components.common.descending')"
        @item-clicked="
          sortingState !== SORTING_DESC && $emit('setSort', 'desc');
          additionalFiltersVisible = false;
        "
      />
    </template>
    <hit-dropdown-item
      v-else
      :close-on-click="false"
      :label="t('hit-components.common.not-sortable')"
    />
    <hit-dropdown-separator />
    <div v-if="itemProperty.type === 'bool' && filterable(itemProperty)">
      <div class="flex flex-row items-center justify-around">
        <span
          class="text-panel cursor-pointer"
          @click="toggleBooleanFilterTrue"
        >
          {{ t('hit-components.common.show-active') }}
        </span>
        <hit-input-checkbox
          :value="boolFilterDisplay[0]"
          @change="toggleBooleanFilterTrue"
        />
      </div>
      <div class="flex flex-row items-center justify-around">
        <span
          class="text-panel cursor-pointer"
          @click="toggleBooleanFilterFalse"
        >
          {{ t('hit-components.common.show-inactive') }}
        </span>
        <hit-input-checkbox
          :value="boolFilterDisplay[1]"
          @change="toggleBooleanFilterFalse"
        />
      </div>
    </div>
    <template
      v-else-if="itemProperty.type !== 'enum' && filterable(itemProperty)"
    >
      <div
        v-for="filter in filtersToDisplay"
        :key="
          filter.index !== undefined ? filter.type + filter.index : filter.type
        "
        class="active-filter-container align-center w-full"
      >
        <span>{{ filter.type }}</span>
        <hit-input
          :instant-focus="!searchable(itemProperty)"
          :value="filter.value"
          :type="
            ['date', 'datetime'].includes(itemProperty.type) ? 'date' : 'text'
          "
          width-class="w-24"
          @change="
            (value) => $emit('changeFilter', value, filter.type, filter.index)
          "
          @focus="additionalFiltersVisible = false"
        />
        <hit-icon
          icon="clear"
          clickable
          @click="
            $emit('deleteFilter', filter.type, filter.index);
            additionalFiltersVisible = false;
          "
        />
      </div>
      <hit-dropdown-separator v-if="filtersToDisplay.length > 0" />
      <hit-dropdown-item
        :label="t('hit-components.common.add-filter')"
        class=""
        :close-on-click="false"
        :force-display-hover-content="additionalFiltersVisible"
        @item-clicked="additionalFiltersVisible = !additionalFiltersVisible"
      >
        <template #hoverContent>
          <hit-dropdown-item
            v-if="
              (!filters || filters['>'] === undefined) &&
                ['decimal', 'int', 'date', 'datetime'].includes(itemProperty.type)
            "
            :label="t('hit-components.common.filter-label-greater')"
            :close-on-click="false"
            @item-clicked="
              $emit('addFilter', '>');
              additionalFiltersVisible = false;
            "
          />
          <hit-dropdown-item
            v-if="
              (!filters || filters['<'] === undefined) &&
                ['decimal', 'int', 'date', 'datetime'].includes(itemProperty.type)
            "
            :label="t('hit-components.common.filter-label-less')"
            :close-on-click="false"
            @item-clicked="
              $emit('addFilter', '<');
              additionalFiltersVisible = false;
            "
          />
          <hit-dropdown-item
            v-if="
              (!filters || filters['≥'] === undefined) &&
                ['decimal', 'int'].includes(itemProperty.type)
            "
            :label="t('hit-components.common.filter-label-greater-equal')"
            :close-on-click="false"
            @item-clicked="
              $emit('addFilter', '≥');
              additionalFiltersVisible = false;
            "
          />
          <hit-dropdown-item
            v-if="
              (!filters || filters['≤'] === undefined) &&
                ['decimal', 'int'].includes(itemProperty.type)
            "
            :label="t('hit-components.common.filter-label-less-equal')"
            :close-on-click="false"
            @item-clicked="
              $emit('addFilter', '≤');
              additionalFiltersVisible = false;
            "
          />
          <hit-dropdown-item
            v-if="!filters || filters['='] === undefined"
            :label="t('hit-components.common.filter-label-equal')"
            :close-on-click="false"
            @item-clicked="
              $emit('addFilter', '=');
              additionalFiltersVisible = false;
            "
          />
          <hit-dropdown-item
            :label="t('hit-components.common.filter-label-not-equal')"
            :close-on-click="false"
            @item-clicked="
              $emit('addFilter', '≠');
              additionalFiltersVisible = false;
            "
          />
          <hit-dropdown-item
            v-if="['standard', 'join'].includes(itemProperty.type)"
            :label="t('hit-components.common.filter-label-contains')"
            :close-on-click="false"
            @item-clicked="
              $emit('addFilter', '∋');
              additionalFiltersVisible = false;
            "
          />
          <hit-dropdown-item
            v-if="['standard', 'join'].includes(itemProperty.type)"
            :label="t('hit-components.common.filter-label-doesnt-contain')"
            :close-on-click="false"
            @item-clicked="
              $emit('addFilter', '∌');
              additionalFiltersVisible = false;
            "
          />
        </template>
      </hit-dropdown-item>
    </template>
    <div v-else-if="filterable(itemProperty) && itemProperty.type === 'enum'">
      <div
        id="enum-select"
        class="p-1 bt-2"
      >
        <hit-select
          multiple
          :value="filters?.['∈'] ?? []"
          :clearable="false"
          :options="enumOptions"
          @add="(v) => $emit('addFilter', '∈', v)"
          @remove="(v) => $emit('deleteFilter', '∈', v)"
        />
      </div>
    </div>
    <hit-dropdown-item
      v-else
      :close-on-click="false"
      :label="t('hit-components.common.not-filterable')"
    />
  </div>
</template>
<script>
import {HitDropdownItem, HitDropdownSeparator} from '../../dropdown';
import {HitInput, HitSelect} from '../../form';
import {useI18n} from 'vue-i18n';
import HitInputCheckbox from '../../form/input/HitInputCheckbox.vue';
import {HitOnlineEnums} from 'hit-online-web-ui/src/helpers/constants';
export default {
  name: 'HitHeaderControl',
  components: {
    HitDropdownItem,
    HitInput,
    HitDropdownSeparator,
    HitInputCheckbox,
    HitSelect,
  },
  props: {
    headerRootDom: null,
    itemProperty: null,
    sort: Object,
    filters: Object,
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      additionalFiltersVisible: false,
      NOT_SORTING: 'NOT_SORTING',
      SORTING_ASC: 'SORTING_ASC',
      SORTING_DESC: 'SORTING_DESC',
      boolFilterDisplay: [true, true],
    };
  },
  computed: {
    enumOptions() {
      return HitOnlineEnums.getEnumValues(this.itemProperty.enumType, this.t);
    },
    dropdownStyle() {
      let result = {};
      let rect = this.headerRootDom.getBoundingClientRect();
      result.top = rect.bottom + 'px';
      result.left = rect.left + 'px';
      return result;
    },
    sortingState() {
      if (!this.sort || !this.sort.property) {
        return this.NOT_SORTING;
      } else if (
        this.sort.property === this.itemProperty.propertyName &&
        this.sort.order === 'asc'
      ) {
        return this.SORTING_ASC;
      } else if (
        this.sort.property === this.itemProperty.propertyName &&
        this.sort.order === 'desc'
      ) {
        return this.SORTING_DESC;
      }
      return null;
    },
    filtersToDisplay() {
      if (!this.filters) {
        return [];
      } else {
        let filterList = [];
        Object.keys(this.filters).forEach((key) => {
          if (Array.isArray(this.filters[key])) {
            let index = 0;
            this.filters[key].forEach((filter) => {
              let result = {type: key};
              result.index = index;
              result.value = filter;
              filterList.push(result);
              index++;
            });
          } else {
            let result = {type: key};
            result.value = this.filters[key];
            filterList.push(result);
          }
        });
        return filterList;
      }
    },
  },
  watch: {
    filters: {
      handler() {
        this.setBoolFilterDisplay();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.filters;
  },
  methods: {
    searchable(itemProperty) {
      return ['standard', 'tag', 'join'].includes(itemProperty.type);
    },
    filterable(itemProperty) {
      return !['colour', 'function'].includes(itemProperty.type);
    },
    sortable(itemProperty) {
      return !['tag', 'join', 'function', 'enum'].includes(itemProperty.type);
    },
    toggleBooleanFilterTrue() {
      if (
        !this.filters ||
        !Object.prototype.hasOwnProperty.call(this.filters, '=')
      ) {
        this.$emit('addFilter', '=');
        this.$emit('changeFilter', false, '=');
      } else if (this.filters['='] === true) {
        this.$emit('changeFilter', false, '=');
      } else if (this.filters['='] === false) {
        this.$emit('deleteFilter', '=');
      }
    },
    toggleBooleanFilterFalse() {
      if (
        !this.filters ||
        !Object.prototype.hasOwnProperty.call(this.filters, '=')
      ) {
        this.$emit('addFilter', '=');
        this.$emit('changeFilter', true, '=');
      } else if (this.filters['='] === false) {
        this.$emit('changeFilter', true, '=');
      } else if (this.filters['='] === true) {
        this.$emit('deleteFilter', '=');
      }
    },
    setBoolFilterDisplay() {
      if (
        !this.filters ||
        !Object.prototype.hasOwnProperty.call(this.filters, '=')
      ) {
        this.boolFilterDisplay = [true, true];
      } else if (this.filters['='] === true) {
        this.boolFilterDisplay = [true, false];
      } else if (this.filters['='] === false) {
        this.boolFilterDisplay = [false, true];
      }
    },
  },
};
</script>
<style lang="scss">
.active-filter-container {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  place-items: center;
  @apply py-2;
}
</style>
