import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-cfcc745e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "fixed z-30 inset-0 overflow-y-auto"
};
const _hoisted_2 = { class: "fixed inset-0 transition-opacity" };
const _hoisted_3 = {
    class: "inline-grid align-bottom bg-panel rounded-lg px-4 py-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-32 sm:align-middle w-full grid-flow-row gap-2 modal",
    role: "dialog",
    "aria-modal": "true",
    "aria-labelledby": "modal-headline"
};
const _hoisted_4 = { key: 0 };
const _hoisted_5 = {
    key: 0,
    class: "hidden sm:block absolute top-0 right-0 pt-4 pr-4"
};
const _hoisted_6 = { class: "grid items-center md:items-start gap-2" };
const _hoisted_7 = { class: "text-lg leading-6 font-medium text-panel text-center sm:text-left" };
const _hoisted_8 = { class: "text-sm leading-5 font-light modal-body text-panel" };
const _hoisted_9 = { class: "grid grid-flow-col gap-1 md:justify-end modal-buttons" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_hit_icon = _resolveComponent("hit-icon");
    const _component_hit_button = _resolveComponent("hit-button");
    return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
            (_ctx.dialogVisible)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", {
                        class: _normalizeClass(["grid items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 w-full mx-auto", {
                                'sm:w-1/2 xl:w-4/12': _ctx.size === 'normal',
                                'sm:w-1/2 xl:w-8/12': _ctx.size === 'large',
                            }])
                    }, [
                        _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("div", {
                                class: "absolute inset-0 bg-gray-500 opacity-75",
                                onClick: _cache[0] || (_cache[0] =
                                    //@ts-ignore
                                    (...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
                            })
                        ]),
                        _createElementVNode("div", _hoisted_3, [
                            (_ctx.enableCloseDialog)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                    (_ctx.showClose)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                            _createVNode(_component_hit_icon, {
                                                icon: "clear",
                                                color: "text-panel",
                                                clickable: true,
                                                class: "hover:font-light focus:outline-none focus:font-light transition ease-in-out duration-150",
                                                onClick: _ctx.onCancel
                                            }, null, 8, ["onClick"])
                                        ]))
                                        : _createCommentVNode("", true)
                                ]))
                                : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_6, [
                                _createElementVNode("span", {
                                    class: "mx-auto grid items-center sm:mx-0 gap-2 justify-center md:justify-start",
                                    style: _normalizeStyle(_ctx.modalHeaderStyle)
                                }, [
                                    (_ctx.displayTitleIcon)
                                        ? (_openBlock(), _createBlock(_component_hit_icon, {
                                            key: 0,
                                            icon: _ctx.icon,
                                            class: "mx-auto md:mx-0",
                                            color: "text-panel"
                                        }, null, 8, ["icon"]))
                                        : _createCommentVNode("", true),
                                    _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.title), 1)
                                ], 4),
                                _createElementVNode("div", null, [
                                    _createElementVNode("div", _hoisted_8, [
                                        _renderSlot(_ctx.$slots, "body", {}, () => [
                                            _createTextVNode(_toDisplayString(_ctx.message), 1)
                                        ], true)
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_9, [
                                _renderSlot(_ctx.$slots, "footer", {}, () => [
                                    (_ctx.showCancelButton)
                                        ? (_openBlock(), _createBlock(_component_hit_button, {
                                            key: 0,
                                            label: _ctx.cancelButtonLabel === undefined
                                                ? _ctx.cancelString
                                                : _ctx.cancelButtonLabel,
                                            "prefix-icon": _ctx.cancelButtonIcon,
                                            color: 'button',
                                            onClick: _ctx.onCancel
                                        }, null, 8, ["label", "prefix-icon", "onClick"]))
                                        : _createCommentVNode("", true),
                                    (_ctx.showOkButton)
                                        ? (_openBlock(), _createBlock(_component_hit_button, {
                                            key: 1,
                                            label: _ctx.okButtonLabel,
                                            "prefix-icon": _ctx.okButtonIcon,
                                            color: _ctx.okButtonColor,
                                            disabled: _ctx.okButtonDisabled,
                                            onClick: _ctx.onOk
                                        }, null, 8, ["label", "prefix-icon", "color", "disabled", "onClick"]))
                                        : _createCommentVNode("", true)
                                ], true)
                            ])
                        ])
                    ], 2)
                ]))
                : _createCommentVNode("", true)
        ]),
        _: 3
    }));
}
