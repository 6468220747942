<template>
  <hit-dialog
    color="panel"
    :visible="visible"
    ok-button-icon="check"
    ok-button-color="accent"
    :ok-button-label="t('hit-app.common.share')"
    :ok-button-disabled="!formIsValid"
    :cancel-button-label="t('hit-components.common.cancel')"
    cancel-button-icon="clear"
    :show-cancel-button="true"
    :title="t('hit-app.share.dialog-title')"
    :enable-close-dialog="false"
    @ok="postSharing"
    @cancel="closeShareDialog"
  >
    <template #body>
      {{ t('hit-app.share.dialog-text') }}
      <div class="hit-form mt-4">
        <hit-input
          :label="t('hit-app.share.amount-items')"
          :value="sharedRecords.length"
          disabled
        />
        <hit-input
          :value="mailAddress"
          :label="t('hit-app.common.email-address')"
          placeholder="user@example.com"
          @input="inputMail"
        >
          <template #customErrorMessage>
            <span
              v-if="errorType === 'unregistered-email'"
              class="text-danger-icon"
            >
              {{ t('hit-app.share.email-not-exist', {email: mailAddress}) }}
            </span>
          </template>
        </hit-input>
        <hit-group-input
          :label="t('hit-app.share.sharable-columns')"
          display-layout="column"
        >
          <hit-input-checkbox
            v-for="(value, key) in sharableColumns"
            :key="key"
            :values="value"
            :value="true"
            @change="toggleCheckbox(key)"
          />
        </hit-group-input>
      </div>
    </template>
  </hit-dialog>
</template>

<script>
import {
  HitDialog,
  HitForm,
  HitGroupInput,
  HitInput,
  HitInputCheckbox,
} from '@hit/components/src';
import {useI18n} from 'vue-i18n';
import {ActionService, NotificationService} from '@hit/base/src';
import {globals} from '../../../globals';

export default {
  name: 'ShareDialog',
  components: {HitDialog, HitGroupInput, HitInputCheckbox, HitInput, HitForm},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    sharedEntity: {
      type: String,
      required: true,
    },
    sharableColumns: {
      // key = name of column in database and value is translation of the column in the user language
      type: Object,
      required: true,
    },
    sharedRecords: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      mailAddress: '',
      sharedColumns: [],
      errorType: '',
    };
  },
  computed: {
    formIsValid() {
      return this.sharedColumns.length > 0 && this.mailAddress;
    },
  },
  mounted() {
    for (let key in this.sharableColumns) {
      this.sharedColumns.push(key);
    }
  },
  methods: {
    inputMail(value) {
      this.errorType = '';
      this.mailAddress = value;
    },
    closeShareDialog() {
      this.$emit('close');
      this.errorType = '';
      this.mailAddress = '';
    },
    toggleCheckbox(column) {
      if (this.sharedColumns.includes(column)) {
        this.sharedColumns = this.sharedColumns.filter(
          (item) => item !== column
        );
      } else {
        this.sharedColumns.push(column);
      }
    },
    async postSharing() {
      const sharingData = [
        {
          entity: this.sharedEntity,
          columns: this.sharedColumns,
          records: this.sharedRecords,
        },
      ];
      ActionService.post('share/', {
        params: {recipient: this.mailAddress},
        values: sharingData,
        customErrorMessage: true,
      })
        .then((response) => {
          if (response.status === 200) {
            NotificationService.send({
              user_id: globals.$keycloak.idTokenParsed.sub,
              title: 'hit-app.share.deposit-notification-title',
              body: {
                message: 'hit-app.share.deposit-notification-text',
                payload: {
                  recipient_mail: this.mailAddress,
                },
              },
              type: 'success',
            });
            this.$emit('shared');
          }
        })
        .catch((error) => {
          if (error?.response?.status === 490) {
            this.errorType = 'unregistered-email';
            return;
          }
          console.error(error);
        });
    },
  },
};
</script>

<style scoped></style>
