<template>
  <hit-input
    :value="displayedValue"
    :validation-state="validate ? container.validationState[attribute] : null"
    :enable-errors="showErrors"
    :text-alignment="textAlignment"
    :disabled="container.attributes[attribute].readOnly"
    :select-entire-content-on-focus="selectOnFocus"
    @change="(value) => container.handleChange(attribute, value)"
  >
    <template
      v-for="({}, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </hit-input>
</template>
<script>
import {HitInput} from '@hit/components';
import {ATTRIBUTE_TYPES} from '../HitContainerAttribute';
import {Decimal} from 'decimal.js';
export default {
  name: 'HitContainerInput',
  components: {
    HitInput,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
    validate: {
      type: Boolean,
      required: false,
      default: false,
    },
    inForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valueSet: false,
    };
  },
  computed: {
    /**
     * Checks the ready flag of the container set when all the data has been processed
     */
    containerReady() {
      return this.container?.ready ?? false;
    },

    /**
     * Workaround to be sure that the data from the container has been loaded
     * into the input to avoid the flickering of the inputs
     * @returns {boolean}
     */
    showErrors() {
      return (
        this.validate && this.containerReady && (this.valueSet || this.inForm)
      );
    },

    textAlignment() {
      if (
        this.container.attributes[this.attribute].dataType ===
        ATTRIBUTE_TYPES.DECIMAL
      ) {
        return 'text-right';
      } else {
        return null;
      }
    },
    selectOnFocus() {
      return (
        [ATTRIBUTE_TYPES.DECIMAL, ATTRIBUTE_TYPES.INT].includes(
          this.container.attributes[this.attribute].dataType
        ) && !this.container.attributes[this.attribute].readOnly
      );
    },
    displayedValue() {
      let value = this.container.data[this.attribute];
      if (
        this.container.attributes[this.attribute].dataType ===
        ATTRIBUTE_TYPES.DECIMAL
      ) {
        if (Decimal.isDecimal(value)) {
          return value.toFixed(
            this.container.attributes[this.attribute].decimalPrecision
          );
        } else {
          return new Decimal(value).toFixed(
            this.container.attributes[this.attribute].decimalPrecision
          );
        }
      } else if (
        this.container.attributes[this.attribute].dataType ===
        ATTRIBUTE_TYPES.INT
      ) {
        value = parseInt(String(value));
      }
      return value;
    },
  },

  /**
   * Watchers used to avoid the flickering of the inputs during the init of the inputs
   */
  watch: {
    displayedValue(newValue, oldValue) {
      this.valueSet = true;
    },
  },
};
</script>
