import Axios from 'axios';
import BaseGeolocationServiceAPI from './BaseGeolocationServiceAPI';
import GeolocationAddress from './GeolocationAddress';

export default class NominatimService extends BaseGeolocationServiceAPI {
  constructor() {
    super();
    this.path = 'https://nominatim.openstreetmap.org/reverse';
    this.api = Axios.create({
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  async reverseGeocoding({lon, lat}) {
    return await this.api
      .get(`${this.path}?lon=${lon}&lat=${lat}&format=json`)
      .then((response) => {
        return new GeolocationAddress({
          streetNumber: response.data.address.house_number,
          street: response.data.address.road,
          city: response.data.address.village || response.data.address.city,
          postalCode: response.data.address.postcode,
          country: response.data.address.country,
        });
      })
      .catch((error) => {
        console.error('Error in reverseGeocoding():', error);
        throw error;
      });
  }
}
