<template>
  <hit-input-multi-language
    :values="multiLanguageValues"
    :validation-states="
      validate
        ? container.validationState[attribute]
        : emptyValidationState
    "
    :locales="configStore.companyLanguagesSnakeCase"
    :parse-locale-function="parseLocaleFunction"
    :disabled="container.attributes[attribute].readOnly"
    :enable-errors="showErrors"
    @change="
      (value) => {
        container.handleChange(attribute, value);
        $emit('change', container.data);
      }
    "
  />
</template>
<script>
import {HitInputMultiLanguage} from '@hit/components';
import {useConfigurationStore} from '@hit/base';
import {parseLocale} from '@hit/base';

export default {
  name: 'HitContainerInputMultiLanguage',
  components: {
    HitInputMultiLanguage,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
    validate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      configStore: useConfigurationStore(),
      parseLocaleFunction: parseLocale,
      valueSet: false,
    };
  },
  computed: {
    /**
     * Object with the different languages extracted as computed prop to set up watcher
     */
    multiLanguageValues() {
      return this.container.data[this.attribute];
    },

    /**
     * Checks the ready flag of the container set when all the data has been processed
     */
    containerReady() {
      return this.container?.ready ?? false;
    },

    /**
     * Workaround to be sure that the data from the container has been loaded
     * into the input to avoid the flickering of the inputs
     */
    showErrors() {
      return this.validate && this.containerReady && this.valueSet;
    },

    /**
     * When the prop validate is set to false, we need to pass an empty validation object
     */
    emptyValidationState() {
      const validations = {};
      for (const lan in this.multiLanguageValues) {
        validations[lan] = null;
      }
      return validations;
    },
  },

  /**
   * Watchers used to avoid the flickering of the inputs during the init of the inputs
   */
  watch: {
    multiLanguageValues(newValue, oldValue) {
      this.valueSet = true;
    },
  },
};
</script>
