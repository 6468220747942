<template>
  <div
    :ref="(ref) => (rootDom = ref)"
    class="hit-table-cell"
    :class="{
      'hit-table-cell-no-input': !input,
      'hit-table-cell-with-input': input,
      'overflow-visible': editMode,
      'overflow-hidden': !editMode,
      'whitespace-nowrap': disableLineBreak || !header,
      'text-right': rightAlignment,
      'overflow-x-clip overflow-y-visible': header,
    }"
    @click="$emit('clickHeader', rootDom)"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'HitTableCell',
  props: {
    input: Boolean,
    header: Boolean,
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    rightAlignment: {
      type: Boolean,
      required: false,
      default: false,
    },
    reverseItems: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableLineBreak: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      rootDom: null,
    };
  },
};
</script>

<style lang="scss">
.hit-table-cell {
  @apply overflow-visible flex items-center;

  &.hit-table-cell-no-input {
    padding: 0 8px; /* compensate input's padding + border */
  }
}

.hit-table-cell-header {
  @apply grid items-center;
  grid-template-columns: min-content max-content;

  &.hit-table-cell-no-input {
    padding: 6px 8px;
  }
}
</style>
