import {createI18n} from 'vue-i18n';

const europeDateFormat = {
  longSeconds: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  long: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  short: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
};

const datetimeFormats = {
  'fr-BE': europeDateFormat,
  'nl-BE': europeDateFormat,
  'de-BE': europeDateFormat,
  'en-GB': europeDateFormat,
};

const numberFormats = {
  'en-GB': {
    useGrouping: true,
    price: {
      style: 'currency',
      currency: 'EUR',
    },
    priceWithoutDecimal: {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    },
    decimal0Digits: {
      type: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal2Digits: {
      type: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    decimal3Digits: {
      type: 'decimal',
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    decimal4Digits: {
      type: 'decimal',
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    },
  },
  'fr-BE': {
    useGrouping: true,
    price: {
      style: 'currency',
      currency: 'EUR',
    },
    priceWithoutDecimal: {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    },
    decimal0Digits: {
      type: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal2Digits: {
      type: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    decimal3Digits: {
      type: 'decimal',
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    decimal4Digits: {
      type: 'decimal',
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    },
  },
  'de-BE': {
    useGrouping: true,
    price: {
      style: 'currency',
      currency: 'EUR',
    },
    priceWithoutDecimal: {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    },
    decimal0Digits: {
      type: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal2Digits: {
      type: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    decimal3Digits: {
      type: 'decimal',
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    decimal4Digits: {
      type: 'decimal',
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    },
  },
  'nl-BE': {
    useGrouping: true,
    price: {
      style: 'currency',
      currency: 'EUR',
    },
    priceWithoutDecimal: {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    },
    decimal0Digits: {
      type: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal2Digits: {
      type: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    decimal3Digits: {
      type: 'decimal',
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    decimal4Digits: {
      type: 'decimal',
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    },
  },
};

//needs to be the same as hit-base language store
export const availableLocales = ['fr-BE', 'nl-BE', 'de-BE', 'mr-IN', 'en-GB'];

function loadLocaleMessages() {
  const locales = require.context(
    '../../locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export const getLocale = () => {
  let locale =
    window.navigator.userLanguage ||
    window.navigator.language ||
    process.env.VUE_APP_I18N_LOCALE ||
    'en-GB';
  if (availableLocales.includes(locale)) {
    return locale;
  }
  let language = locale.split('-')[0];
  for (let i = 0; i < availableLocales.length; i++) {
    if (language === availableLocales[i].split('-')[0]) {
      return availableLocales[i];
    }
  }
  return 'en-GB';
};

export const i18n = createI18n({
  locale: getLocale(),
  allowComposition: true,
  globalInjection: true,
  legacy: false,
  fallbackLocale: {
    fr: ['fr-BE'],
    de: ['de-BE'],
    nl: ['nl-BE'],
    en: ['en-GB'],
    default: ['en-GB'],
  },
  numberFormats,
  datetimeFormats,
  messages: loadLocaleMessages(),
});

export const translate = (key) => {
  if (!key) {
    return '';
  }
  return i18n.global.t(key);
};

export default function installVueI18N(app) {
  app.use(i18n);
}

export const $d = i18n.global.d;
