import BaseRootEntityService from '../../../api/base/BaseRootEntityService';
import {ProjectPart, projectPartSchema} from '../../../models';
import tag from './tag';
import {makeEntityStore} from '../../helpers/factory';

export default makeEntityStore({
  modelCls: ProjectPart,
  service: new BaseRootEntityService('project_part', ProjectPart),
  schema: projectPartSchema,
  tag,
});
