import BaseRootEntityService from '../../../api/base/BaseRootEntityService';
import {Address, addressSchema} from '../../../models';
import communicationModeData from './communication-mode-data';
import tag from './tag';
import followUp from './follow-up/';
import {makeEntityStore} from '../../helpers/factory';

export default makeEntityStore({
  modelCls: Address,
  service: new BaseRootEntityService('address', Address),
  schema: addressSchema,
  communicationModeData,
  tag,
  followUp,
});
