// @ts-nocheck
import axios from 'axios';
import { useActionStore, useConfigurationStore, useNotificationsStore, } from '../../store';
import { globals } from '../../../../app/src/globals';
import { translate } from '../../plugins/i18n/i18n';
import { HitBannerNotificationItem } from '../../store/pinia/notifications';
const defaultPostOptions = {
    params: {},
    values: {},
    config: {},
    customErrorMessage: false,
    downloadAsPdf: false,
};
export class ActionService {
    static get baseUrl() {
        return process.env.VUE_APP_ACTION_URL;
    }
    static downloadAsPdf(response) {
        let blob = new Blob([response.data], {
            type: response.headers['content-type'],
        });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let filename = '';
        let contentDisposition = response.headers['content-disposition']; // ex: "attachment;filename='FormDefinition complete example 21/08/2020.pdf'"
        if (contentDisposition) {
            filename = ((contentDisposition.split('filename=')[1] || '').split(';')[0] || '')
                .replace(/'/g, '')
                .replace(/"/g, '');
        }
        if (!filename) {
            filename = 'form.pdf';
        }
        link.download = filename;
        link.click();
    }
    static get(route, config = {}, downloadAsPdf = false) {
        const path = this.baseUrl + route;
        if (!config.headers) {
            config.headers = {};
        }
        if (downloadAsPdf) {
            config['responseType'] = 'arraybuffer';
        }
        config.headers['Authorization'] = 'Bearer ' + globals.$keycloak.token;
        config.headers['Accept-Profile'] = globals.$keycloak.tokenParsed.schema_id;
        try {
            config.headers['Accept-Language'] = [
                useConfigurationStore().userLanguageSnakeCase,
                useConfigurationStore().mainLanguageSnakeCase,
            ].join(',');
        }
        catch {
            /** During the init of the config store, the get method is already used but
            at this moment, the languages are not available -> throws an error **/
        }
        return axios
            .get(path, config)
            .then((response) => {
            if (downloadAsPdf) {
                this.downloadAsPdf(response);
            }
            else {
                return response;
            }
        })
            .catch((error) => {
            if (error.response) {
                if (error.response.status === 444) {
                    // The keys returned by the API need to be defined in the hit-base language dictionary
                    useNotificationsStore().insertBannerNotification(translate(error.response.data));
                    return;
                }
                else if (error.response.status === 500) {
                    useNotificationsStore().insertBannerNotification(translate('hit-base.common.error-500'));
                    return;
                }
            }
            throw error;
        });
    }
    static post(route, options) {
        const mergedOptions = { ...defaultPostOptions, ...options };
        const { params, values, config, customErrorMessage, downloadAsPdf, } = mergedOptions;
        const path = this.baseUrl + route;
        if (!config.headers) {
            config.headers = {};
        }
        config.headers['Authorization'] = 'Bearer ' + globals.$keycloak.token;
        config.headers['Accept-Profile'] = globals.$keycloak.tokenParsed.schema_id;
        config.headers['Accept-Language'] = [
            useConfigurationStore().userLanguageSnakeCase,
            useConfigurationStore().mainLanguageSnakeCase,
        ].join(',');
        if (downloadAsPdf) {
            config['responseType'] = 'arraybuffer';
        }
        config.params = params;
        return axios
            .post(path, values, config)
            .then((response) => {
            if (downloadAsPdf) {
                this.downloadAsPdf(response);
            }
            else {
                if (response.status === 244) {
                    const banner = typeof response.data === 'string'
                        ? translate(response.data)
                        : translate(response.data.message, response.data.payload);
                    useNotificationsStore().insertBannerNotification(banner, HitBannerNotificationItem.SUCCESS);
                }
                else {
                    return response;
                }
            }
        })
            .catch((error) => {
            if (!customErrorMessage) {
                if (error.response) {
                    if (error.response.status === 444 ||
                        error.response.status === 445) {
                        let bannerData = error.response.data;
                        if (downloadAsPdf) {
                            const decoder = new TextDecoder('utf-8');
                            bannerData = decoder.decode(error.response.data);
                            try {
                                bannerData = JSON.parse(bannerData);
                            }
                            catch {
                                /*eslint no-empty: "error"*/
                            }
                        }
                        const banner = typeof bannerData === 'string'
                            ? translate(bannerData)
                            : translate(bannerData.message, bannerData.payload);
                        useNotificationsStore().insertBannerNotification(banner);
                        if (error.response.status === 445) {
                            useActionStore().updateAssignedActions();
                        }
                        return 'Error';
                    }
                }
                useNotificationsStore().insertBannerNotification(translate('hit-base.banners.unknown-error-title'));
                return;
            }
            throw error;
        });
    }
}
