import {createI18n} from 'vue-i18n';
import {getLocale} from '@hit/components/src/plugins/i18n/i18n';

const europeDateFormat = {
  longSeconds: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  long: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  short: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
};

const datetimeFormats = {
  'fr-BE': europeDateFormat,
  'nl-BE': europeDateFormat,
  'de-BE': europeDateFormat,
  'en-GB': europeDateFormat,
};

function loadLocaleMessages() {
  const locales = require.context(
    '../locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  const componentsLocaleMessages = [loadComponentsLocaleMessages()];
  const messagesToImport = componentsLocaleMessages;

  messagesToImport.forEach((toImport) => {
    Object.keys(toImport).forEach((locale) => {
      if (messages[locale] === undefined) {
        messages[locale] = {};
      }
      Object.keys(toImport[locale]).forEach((key) => {
        if (messages[locale][key] === undefined) {
          messages[locale][key] = toImport[locale][key];
        }
      });
    });
  });

  return messages;
}

function loadComponentsLocaleMessages() {
  const locales = require.context(
    '../../../hit-components/src/locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export const i18n = createI18n({
  legacy: false,
  locale: getLocale(),
  fallbackLocale: {
    fr: ['fr-BE'],
    de: ['de-BE'],
    nl: ['nl-BE'],
    en: ['en-GB'],
    default: ['en-GB'],
  },
  datetimeFormats: datetimeFormats,
  messages: loadLocaleMessages(),
});

export const translate = (key) => {
  if (!key) {
    return '';
  }
  return i18n.global.t(key);
};

export const translatec = (key) => {
  if (!key) {
    return '';
  }
  return i18n.global.tc(key);
};

export default function installVueI18N(app) {
  app.use(i18n);
}
