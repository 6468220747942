export default {
  inject: {
    unregisterLoadingEmitter: {
      default: undefined, // a default value is required to suppress warning if not found
    },
    registerLoadingEmitter: {
      default: undefined,
    },
  },
  methods: {
    setLoading(isLoading) {
      if (isLoading) {
        if (this.registerLoadingEmitter) {
          this.registerLoadingEmitter();
        }
      } else {
        if (this.unregisterLoadingEmitter) {
          this.unregisterLoadingEmitter();
        }
      }
    },
  },
};
