<template>
  <OnClickOutside @trigger="hideTooltip">
    <div class="grid">
      <!-- @slot Element on which the tooltip will be applied -->
      <slot :toggle-tooltip="toggleTooltipVisible" />
      <div
        v-if="tooltipVisible"
        class="p-4 z-50 w-72 text-justify inline-block tooltip-text bg-header border rounded"
        :class="{
          '-mt-8 ml-6 absolute': responsiveBreakpointSm,
          'left-3 mt-8 fixed': !responsiveBreakpointSm,
        }"
      >
        <div class="inline-grid grid-flow-row gap-2">
          <div class="block absolute top-0 right-0 pt-4 pr-4">
            <hit-icon
              icon="clear"
              :clickable="true"
              color="white"
              class="focus:outline-none"
              @click="hideTooltip"
            />
          </div>
          <div class="text-lg leading-6 font-medium text-white mr-6">
            {{ title }}
          </div>
          <div
            v-hit-sanitized-html="content"
            class="text-sm leading-5 text-white"
          />
        </div>
      </div>
    </div>
  </OnClickOutside>
</template>

<script>
import HitIcon from '../icon/HitIcon.vue';
import {OnClickOutside} from '@vueuse/components';
import {HitBreakpointsMixin} from '../../mixins';
export default {
  name: 'HitTooltip',
  components: {OnClickOutside, HitIcon},
  mixins: [HitBreakpointsMixin],
  props: {
    /**
     * Title of the tooltip
     */
    title: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Content of the tooltip
     */
    content: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      tooltipVisible: false,
    };
  },
  computed: {},
  methods: {
    toggleTooltipVisible() {
      this.tooltipVisible = !this.tooltipVisible;
      /**
       * When the visibility of the tooltip changes. True if visible, false if not.
       */
      this.$emit('tooltipVisible', this.tooltipVisible);
    },
    hideTooltip() {
      this.tooltipVisible = false;
      /**
       * When the visibility of the tooltip changes. True if visible, false if not.
       */
      this.$emit('tooltipVisible', false);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>

<style lang="scss">
.tooltip-text {
  ul {
    padding-left: 40px;

    li {
      padding-left: initial;
    }
  }
}
</style>
