import BaseModel from '../BaseModel';

const ID_PHONE = 'e74723ed-d24c-4ab1-8edc-a5f012dbe8ca';
const ID_FAX = 'c163ebfd-9108-4a74-aa39-4554efbd410a';
const ID_MOBILE_PHONE = '2291c24e-52de-40be-bbfc-c1dd9181717e';
const ID_EMAIL = 'c4d2a1b7-2bd3-4d3b-aea1-9136d25bfda3';

export default class CommunicationMode extends BaseModel {
  static get ID_PHONE() {
    return ID_PHONE;
  }

  static get ID_FAX() {
    return ID_FAX;
  }

  static get ID_MOBILE_PHONE() {
    return ID_MOBILE_PHONE;
  }

  static get ID_EMAIL() {
    return ID_EMAIL;
  }

  static getIcon(communicationModeId) {
    if (!communicationModeId) {
      return undefined;
    }
    switch (communicationModeId) {
      case CommunicationMode.ID_PHONE:
        return 'phone';
      case CommunicationMode.ID_MOBILE_PHONE:
        return 'mobile-phone';
      case CommunicationMode.ID_FAX:
        return 'printer';
      case CommunicationMode.ID_EMAIL:
        return 'email';
      case 'other':
        return 'info';
      default:
        return undefined;
    }
  }

  static getHref(communicationModeId, value) {
    switch (communicationModeId) {
      case CommunicationMode.ID_PHONE:
        return `tel:${value}`;
      case CommunicationMode.ID_MOBILE_PHONE:
        return `tel:${value}`;
      case CommunicationMode.ID_FAX:
        return `tel:${value}`;
      case CommunicationMode.ID_EMAIL:
        return `mailto:${value}`;
      default:
        return undefined;
    }
  }

  static factory() {
    return new CommunicationMode();
  }
}
