<template>
  <hit-base-input
    :label="label"
    :validation-state="validationState"
    use-custom-height
    class="hit-location-input"
  >
    <div
      v-if="value"
      class="inline-grid grid-flow-row gap-1 items-center mt-1"
    >
      <div class="inline-grid hit-location-input-grid gap-1 items-center">
        <div class="flex items-center">
          <a
            :href="googleMapUrl"
            target="_blank"
          >{{
            value ? `${latitude}, ${longitude}` : ''
          }}</a>
          <hit-icon
            icon="info"
            :tooltip="t('hit-base.form.location-info-tooltip')"
            color="accent"
            size="sm"
          />
        </div>
        <hit-button
          v-if="!readonly"
          prefix-icon="refresh"
          :label="t('hit-components.common.refresh')"
          :disabled="disabled"
          @click="getCurrentPosition"
        />
      </div>
      <hit-address
        v-if="address"
        :address="address"
      />
    </div>
    <hit-button
      v-if="!value && !readonly"
      :label="t('hit-components.common.localize-me')"
      prefix-icon="location"
      :disabled="disabled"
      class="mt-1"
      @click="getCurrentPosition"
    />
    <template #help>
      <slot name="help" />
    </template>
  </hit-base-input>
</template>

<script>
import HitBaseInput from './HitBaseInput';
import HitFormValidationMixin from '../../../mixins/form/HitFormValidationMixin';
import HitInputMixin from '../../../mixins/form/HitInputMixin';
import HitAddress from '../../address/HitAddress';
import {useI18n} from 'vue-i18n';
import HitIcon from '../../icon/HitIcon.vue';
import HitButton from '../../button/HitButton.vue';

export default {
  name: 'HitInputLocation',
  components: {HitBaseInput, HitAddress, HitIcon, HitButton},
  mixins: [HitFormValidationMixin, HitInputMixin],
  props: {
    /**
     * Value of the input
     */
    value: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      reverseGeocodingInProgress: false,
      address: null,
    };
  },
  computed: {
    longitude() {
      if (this.value) {
        return this.value.coordinates[0];
      } else {
        return null;
      }
    },
    latitude() {
      if (this.value) {
        return this.value.coordinates[1];
      } else {
        return null;
      }
    },
    googleMapUrl() {
      return this.value
        ? `https://maps.google.com/?q=${this.latitude},${this.longitude}`
        : '';
    },
  },
  watch: {
    value: {
      handler: function () {
        if (this.longitude && this.latitude) {
          this.reverseGeocodingInProgress = true;
          this.$geolocation
            .reverseGeocoding({lat: this.latitude, lon: this.longitude})
            .then((result) => {
              this.address = result;
              this.reverseGeocodingInProgress = false;
            });
        } else {
          return null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    getCurrentPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.savePosition);
      } else {
        console.error('Error while retrieving GPS position');
      }
    },
    savePosition(position) {
      if (position) {
        let geojsonPosition = {
          type: 'Point',
          coordinates: [position.coords.longitude, position.coords.latitude],
        };

        this.fireInputChange(geojsonPosition);
      }
    },
  },
};
</script>

<style lang="scss">
.hit-location-input.hit-form-row {
  .hit-form-row-label {
    margin-top: 0.55rem;
  }

  .hit-form-row-input {
    .inline-grid {
      @apply mt-0;
    }
  }
}

.hit-location-input {
  .hit-location-input-grid {
    @apply grid-flow-row;
  }
}

.large-enough-for-form {
  .hit-location-input {
    .hit-location-input-grid {
      @apply grid-flow-col;
    }
  }
}
</style>
