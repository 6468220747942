const REQUIRED_LIKE_VALIDATION_TYPES = [
  'required',
  'requiredIf',
  'requiredUnless',
  'atLeastOneFieldSet',
];

export default {
  props: {
    /**
     * Validation state of the component
     */
    validationState: {
      type: Object,
      required: false,
      validator(v$) {
        return Object.prototype.hasOwnProperty.call(v$, '$model');
      },
    },
  },
  computed: {
    valid() {
      return this.validationState && !this.validationState.$error;
    },
    invalid() {
      return this.validationState && this.validationState.$invalid;
    },
    anyDirty() {
      return this.validationState && this.validationState.$anyDirty;
    },
    dirty() {
      return this.validationState && this.validationState.$dirty;
    },
    pristine() {
      return this.validationState && !this.validationState.$dirty;
    },
    required() {
      if (!this.validationState) {
        return undefined;
      }
      for (let key in this.validationState.$params) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.validationState.$params,
            key
          ) &&
          this.validationState.$params[key] &&
          REQUIRED_LIKE_VALIDATION_TYPES.includes(
            this.validationState.$params[key].type
          )
        ) {
          return true;
        }
      }
      return false;
    },
  },
};
