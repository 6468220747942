import axios from 'axios';
import { MyLog } from '../tools/MyLog';
import { Base64 } from 'js-base64';
const codesHTTP = [
    { code: 100, message: 'Continue', messageFR: 'Continuer' },
    {
        code: 101,
        message: 'Switching Protocols',
        messageFR: 'Changement de protocole',
    },
    { code: 200, message: 'OK', messageFR: 'OK' },
    { code: 201, message: 'Created', messageFR: 'Créé' },
    { code: 204, message: 'No Content', messageFR: 'Pas de contenu' },
    { code: 400, message: 'Bad Request', messageFR: 'Requête incorrecte' },
    { code: 401, message: 'Unauthorized', messageFR: 'Non autorisé' },
    { code: 403, message: 'Forbidden', messageFR: 'Interdit' },
    {
        code: 405,
        message: 'Method Not Allowed',
        messageFR: 'Méthode non autorisée',
    },
    { code: 406, message: 'Not Acceptable', messageFR: 'Non acceptable' },
    {
        code: 408,
        message: 'Request Timeout',
        messageFR: "Temps d'attente de la requête expiré",
    },
    { code: 410, message: 'Gone', messageFR: 'Ressource introuvable' },
    {
        code: 413,
        message: 'Payload Too Large',
        messageFR: 'Charge de la requête trop volumineuse',
    },
    {
        code: 415,
        message: 'Unsupported Media Type',
        messageFR: 'Type de média non supporté',
    },
    { code: 429, message: 'Too Many Requests', messageFR: 'Trop de requêtes' },
    {
        code: 500,
        message: 'Internal Server Error',
        messageFR: 'Erreur interne du serveur',
    },
    { code: 502, message: 'Bad Gateway', messageFR: 'Passerelle incorrecte' },
    {
        code: 503,
        message: 'Service Unavailable',
        messageFR: 'Service non disponible',
    },
    {
        code: 504,
        message: 'Gateway Timeout',
        messageFR: "Temps d'attente de la passerelle expiré",
    },
    {
        code: 507,
        message: 'Insufficient Storage',
        messageFR: 'Espace de stockage insuffisant',
    },
];
export class MyHttpManager {
    /**********
     POST
     *********/
    static async post(url, data, config, remainingRetries = 3) {
        // Define 'default value'
        if (!data) {
            data = {};
        }
        try {
            const response = await axios.post(url, data, config);
            return response.data;
        }
        catch (error) {
            if (error.isAxiosError) {
                // Get the HTTP error code
                const httpCode = error.response?.status || 500;
                // Find the corresponding code in the codesHTTP list
                const foundCode = codesHTTP.find((code) => code.code === httpCode);
                if (foundCode) {
                    // Code found in the codesHTTP list, use its English message for the error
                    MyLog.showLog(`HTTP API : code HTTP found : ${foundCode.message}`);
                    throw new Error(foundCode.message);
                }
                else {
                    // Code not found in the codesHTTP list, use a generic error message
                    MyLog.showLog(`HTTP API : Error during POST request (HTTP Code: ${httpCode}).`);
                    throw new Error(`HTTP API : Error during POST request (HTTP Code: ${httpCode}).`);
                }
            }
            else {
                // Handle non AxiosError (e.g., network errors, connection errors, etc.)
                // Check if the error is a 504 error
                if (error.code === 'ECONNABORTED') {
                    // ECONNABORTED error code indicates a timeout (504 Gateway Timeout)
                    if (remainingRetries > 0) {
                        MyLog.showLog(`HTTP API : Timeout (504 Gateway Timeout) occurred. Retrying... (retries left: ${remainingRetries})`);
                        return this.post(url, data, remainingRetries - 1);
                    }
                    else {
                        MyLog.showLog('HTTP API : The request timed out after retries. Please try again later.');
                        throw new Error('HTTP API : The request timed out after retries. Please try again later.');
                    }
                }
                else {
                    // Other types of non AxiosError, handle them generically
                    MyLog.showLog('HTTP API : An unexpected error occurred during the POST request.');
                    throw new Error('HTTP API : An unexpected error occurred during the POST request.');
                }
            }
        }
    }
    /**********
     GET
     *********/
    static async get(url, successResultOperation, nullResultOperation, successHttpValue, retryCount, maxRetry) {
        // Define 'default usage' of these variables
        if (!nullResultOperation) {
            nullResultOperation = () => { };
        }
        if (!successHttpValue)
            successHttpValue = 200;
        if (!retryCount)
            retryCount = 0;
        if (!maxRetry)
            maxRetry = 3;
        try {
            const response = await axios.get(url);
            // Response received from API with status code 200
            if (response.status === successHttpValue) {
                successResultOperation(response);
                return response;
            }
            // Error case : status code of the response != 200
            else if (retryCount < maxRetry) {
                MyLog.showLog('Case D : try = ' + retryCount);
                // Try again to get informations
                await this.get(url, successResultOperation, nullResultOperation, successHttpValue, retryCount + 1, maxRetry);
            }
            else {
                MyLog.showLog('Case E');
                nullResultOperation();
            }
        }
        catch (error) {
            // Not response received from API
            if (retryCount < maxRetry) {
                MyLog.showLog('Case F: try = ' + retryCount);
                await this.get(url, successResultOperation, nullResultOperation, successHttpValue, retryCount + 1, maxRetry);
            }
            else {
                MyLog.showLog('Case G');
                nullResultOperation();
            }
        }
    }
    static generateAdminApiBase64Token() {
        const concatCredentials = `${process.env.VUE_APP_ADMIN_API_BASIC_AUTH_USERNAME}:${process.env.VUE_APP_ADMIN_API_BASIC_AUTH_PASSWORD}`;
        return Base64.encode(concatCredentials);
    }
}
