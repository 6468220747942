<template>
  <hit-input-hours
    :value="container.data[attribute]"
    :steps="steps"
    @changeTime="handleChangeTime"
  />
</template>
<script>
import {HitInputHours} from '@hit/components';
import {nextTick} from 'vue';

export default {
  name: 'HitContainerInputHours',
  components: {
    HitInputHours,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
    steps: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  methods: {
    handleChangeTime(value) {
      // needed to force reactivity, when for example value is 0 and user enters invalid input, the input value doesn't
      // get replaced by 0 otherwise
      if (this.container.data[this.attribute] === value) {
        this.container.data[this.attribute] = -1;
        let handleChangeFunction = () =>
          this.container.handleChange(this.attribute, value);
        nextTick(() => {
          handleChangeFunction();
        });
      } else {
        this.container.handleChange(this.attribute, value);
      }
    },
  },
};
</script>
