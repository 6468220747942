export default class Resource {
  // méthode constructor
  constructor({no, designation, unit, netPrice, active}) {
    this.no = no;
    this.designation = designation;
    this.unit = unit;
    this.netPrice = netPrice;
    this.grossPrice = netPrice * 1.2;
    this.active = active;
  }

  static factory() {
    return new Resource({active: true});
  }

  static getMTMRelations() {
    return {
      tags: {
        pivotTableName: 'resource_tag',
        parentIdColumn: 'resource_id',
        childIdColumn: 'tag_id',
        childTableName: 'tag',
      },
    };
  }

  static getKeyTranslations() {
    return {
      tag: 'tags',
    };
  }
}
