<template>
  <hit-app-select-entity
    :value="value"
    :label="label"
    options-store-namespace="equipment"
    :validation-state="validationState"
    :label-fields="['no', 'designation']"
    label-fields-separator=" - "
    :readonly="readonly"
    :disabled="disabled"
    :loading="loading"
    :clearable="clearable"
    :reset-validation-if-valid="resetValidationIfValid"
    :inline-input="inlineInput"
    @change="onSelectChange"
  >
    <template #help>
      <!-- @slot Help of the select -->
      <slot name="help" />
    </template>
  </hit-app-select-entity>
</template>

<script>
import {HitFormValidationMixin, HitAppSelectMixin} from '@hit/components';
import HitAppSelectEntity from '../app/form/HitAppSelectEntity';
import {useI18n} from 'vue-i18n';

export default {
  name: 'HitEquipmentSelect',
  components: {HitAppSelectEntity},
  mixins: [HitFormValidationMixin, HitAppSelectMixin],
  props: {
    /**
     * Label of the equipment select
     */
    label: {
      type: String,
      default: function () {
        return this.t('common.equipment', 1);
      },
    },
    /**
     * Selected value of the equipment select
     */
    value: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Enable loading of the select options
     */
    loading: Boolean,
    /**
     * Enable clearing of the selected option
     */
    clearable: Boolean,
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onSelectChange: function (value) {
      this.fireInputChange(value);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
