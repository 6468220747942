export function getHitButtonProps() {
    return {
        /**
         * Label of the button
         */
        label: {
            type: String,
            required: false,
            default: null,
        },
        /**
         * Disable button and display not allowed cursor
         */
        disabled: Boolean,
    };
}
export function useHitButton(emit) {
    const handleClick = function (evt) {
        /**
         * When the button is clicked
         */
        emit('click', evt);
    };
    return {
        handleClick,
    };
}
