<template>
  <hit-select
    :options="options"
    :options-to-exclude="optionsToExclude"
    selection-type="ENUM"
    :value="valueToDisplay"
    :full-width="fullWidth"
    :disabled="container.attributes[attribute].readOnly"
    @add="handleAdd"
    @remove="handleRemove"
    @clear="handleClear"
  />
</template>

<script>
import {HitSelect} from '@hit/components';
import {useI18n} from 'vue-i18n';
import {HitOnlineEnums} from 'hit-online-web-ui/src/helpers/constants';

export default {
  name: 'HitContainerEnumLabelSelect',
  components: {HitSelect},
  props: {
    container: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: false,
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    excludeKey: {
      type: String,
      required: false,
      default: null,
    },
    optionsToExcludeMapping: {
      type: Object,
      required: false,
      default: null,
    },
    value: {
      type: String,
      required: false,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  computed: {
    options() {
      return HitOnlineEnums.getEnumValues(
        this.container.attributes[this.attribute].enumType,
        this.t
      );
    },
    valueToDisplay() {
      const value = this.container.multiple
        ? this.value
        : this.container.data[this.attribute];
      return value ? value.id : null;
    },
    optionsToExclude() {
      if (
        this.excludeKey &&
        this.optionsToExcludeMapping &&
        this.excludeKey in this.optionsToExcludeMapping
      ) {
        const invalidOptions = this.optionsToExcludeMapping[this.excludeKey];
        return invalidOptions ? invalidOptions : [];
      }
      return [];
    },
  },

  methods: {
    handleAdd(value) {
      const enumVal = this.options.find((i) => i.id === value);
      if (this.container.multiple) {
        this.$emit('change-label', enumVal);
      } else {
        this.container.handleChange(this.attribute, enumVal);
        this.$emit('set-label', enumVal);
      }
    },
    handleRemove(value) {
      this.$emit('remove', value);
    },
    handleClear() {
      if (this.container.multiple) {
        console.error('Impossible to add clear prop to multiple select');
      } else {
        this.container.handleChange(this.attribute, null);
      }
    },
  },
};
</script>
