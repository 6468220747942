import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_hit_button = _resolveComponent("hit-button");
    const _component_hit_icon = _resolveComponent("hit-icon");
    const _component_hit_dialog = _resolveComponent("hit-dialog");
    return (_openBlock(), _createElementBlock("div", null, [
        (!_ctx.onlyIcon)
            ? (_openBlock(), _createBlock(_component_hit_button, {
                key: 0,
                label: _ctx.label,
                color: "danger",
                "prefix-icon": "trash",
                class: "hit-button-delete",
                tooltip: _ctx.tooltipString,
                disabled: _ctx.disabled,
                onClick: _ctx.openDeleteDialog
            }, null, 8, ["label", "tooltip", "disabled", "onClick"]))
            : _createCommentVNode("", true),
        (_ctx.onlyIcon)
            ? (_openBlock(), _createBlock(_component_hit_icon, {
                key: 1,
                clickable: true,
                icon: "trash",
                color: "danger",
                class: "hit-button-delete",
                tooltip: _ctx.tooltipString,
                disabled: _ctx.disabled,
                onClick: _ctx.openDeleteDialog
            }, null, 8, ["tooltip", "disabled", "onClick"]))
            : _createCommentVNode("", true),
        _createVNode(_component_hit_dialog, {
            visible: _ctx.showDeleteDialog,
            "onUpdate:visible": _cache[0] || (_cache[0] = ($event) => ((_ctx.showDeleteDialog) = $event)),
            title: _ctx.t('hit-base.common.delete-dialog-title'),
            color: "danger",
            message: _ctx.t('hit-base.common.delete-dialog-message'),
            "ok-button-label": _ctx.t('hit-components.common.delete'),
            "ok-button-icon": "trash",
            "ok-button-color": "danger",
            "show-cancel-button": true,
            "cancel-button-label": _ctx.t('hit-components.common.cancel'),
            "cancel-button-icon": "clear",
            onOk: _ctx.onConfirmDelete,
            onCancel: _ctx.onCancelDelete
        }, null, 8, ["visible", "title", "message", "ok-button-label", "cancel-button-label", "onOk", "onCancel"])
    ]));
}
