<template>
  <hit-base-input
    :label="label"
    :validation-state="validationState"
    use-custom-height
  >
    <div>
      <div
        v-if="!readonly"
        class="flex items-center justify-between mt-2"
        :style="{
          width: responsiveBreakpointXs ? '18.75rem' : '12.25rem',
        }"
      >
        <div class="flex">
          <hit-icon icon="signature" />
          <p class="ml-2 text-sm text-panel-light">
            {{ t('hit-components.common.signature') }}
          </p>
        </div>
        <button
          class="mr-2 items-center"
          type="button"
          @click="clearSignature()"
        >
          <hit-icon
            class="w-4 h-4"
            icon="trash"
          />
        </button>
      </div>
      <div
        class="border-2 border-gray-300 border-dashed rounded-lg bg-panel w-fit"
        :class="{'cursor-pointer': !readonly}"
      >
        <VPerfectSignature
          v-if="!readonly"
          ref="signaturePad"
          :width="
            responsiveBreakpointXs ? WIDTH_LG_REM + 'rem' : WIDTH_SM_REM + 'rem'
          "
          :height="
            responsiveBreakpointXs
              ? HEIGHT_LG_REM + 'rem'
              : HEIGHT_SM_REM + 'rem'
          "
          :stroke-options="signOptions"
          @onEnd="toDataURL()"
        />
        <img
          v-else
          :src="signatureImage"
          alt="Signature"
          :style="{
            width: responsiveBreakpointXs
              ? WIDTH_LG_REM + 'rem'
              : WIDTH_SM_REM + 'rem',
            height: responsiveBreakpointXs
              ? HEIGHT_LG_REM + 'rem'
              : HEIGHT_SM_REM + 'rem',
          }"
        >
      </div>
    </div>
    <template #help>
      <slot name="help" />
    </template>
  </hit-base-input>
</template>

<script>
import HitBaseInput from './HitBaseInput';
import HitFormValidationMixin from '../../../mixins/form/HitFormValidationMixin';
import HitInputMixin from '../../../mixins/form/HitInputMixin';
import {useI18n} from 'vue-i18n';
import VPerfectSignature, {strokeOptions} from 'v-perfect-signature';
import {HitBreakpointsMixin} from '../../../mixins';
import {nextTick, reactive} from 'vue';

export default {
  name: 'HitInputSignature',
  components: {HitBaseInput, VPerfectSignature},
  mixins: [HitFormValidationMixin, HitInputMixin, HitBreakpointsMixin],
  props: {
    /**
     * Value of the input
     */
    value: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      signOptions: {
        size: 6,
        thinning: 0.75,
        smoothing: 0.5,
        streamline: 0.5,
      },
      signatureImage: '',
      WIDTH_LG_REM: 18.75,
      WIDTH_SM_REM: 12.25,
      HEIGHT_LG_REM: 9.375,
      HEIGHT_SM_REM: 6.125,
    };
  },
  watch: {
    readonly: function () {
      if (this.readonly) {
        this.signatureImage = this.toDataURL();
      }
    },
    responsiveBreakpointXs: function () {
      if (!this.$refs.signaturePad) {
        return;
      }
      let dataURL = this.$refs.signaturePad.toDataURL();
      nextTick(() => {
        this.$refs.signaturePad.resizeCanvas(true);
        if (dataURL) {
          this.$refs.signaturePad.fromDataURL(dataURL);
        }
      });
    },
  },
  mounted() {
    if (this.value && !this.readonly) {
      this.$refs.signaturePad.fromDataURL(this.value.image);
    }
    if (this.value && this.readonly) {
      this.signatureImage = this.value.image;
    }
  },
  methods: {
    toDataURL() {
      const dataURL = this.$refs.signaturePad.toDataURL();
      const signatureObject = {
        image: dataURL,
      };
      this.fireInputChange(signatureObject);
      return dataURL;
    },
    clearSignature() {
      this.$refs.signaturePad.clear();
    },
  },
};
</script>

<style lang="scss">
.w-fit {
  width: fit-content;
}
</style>
