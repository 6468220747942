// loads users
import {mapActions, mapGetters} from 'vuex';

export default {
  data() {
    return {
      fetchAllUsersQueryId: null,
    };
  },
  methods: {
    ...mapActions('keycloak/user', {
      storeFetchAllUsers: 'fetchItems',
    }),
    fetchAllUsers() {
      this.storeFetchAllUsers({
        query: this.fetchAllUsersQueryParam,
      }).then((queryId) => (this.fetchAllUsersQueryId = queryId));
    },
    getUserLabel(item) {
      if (!this.isUsersLoading && item && item.userId) {
        let user = this.storeGetUser(item.userId);
        if (user) {
          return user.username;
        }
      }
      return null;
    },
  },
  computed: {
    ...mapGetters('keycloak/user', {
      storeGetUsers: 'items',
      storeIsUsersLoading: 'itemsLoading',
      storeGetUser: 'item',
    }),
    currentCompanyId() {
      return this.$store.getters['context/company/companyId'];
    },
    isUsersLoading() {
      return this.storeIsUsersLoading(this.fetchAllUsersQueryId);
    },
    fetchAllUsersQueryParam() {
      return {
        fields: ['id', 'username'],
        filters: {companyId: 'eq.' + this.currentCompanyId},
      };
    },
  },
  watch: {
    fetchAllUsersQueryParam: {
      handler() {
        this.fetchAllUsers();
      },
      immediate: true,
    },
  },
};
