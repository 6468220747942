<template>
  <div
    class="relative rounded items-center w-full h-full"
    :class="{
      'border-accent': focussed && borderVisible,
      'border border-input': borderVisible,
      'shadow-sm': shadowVisible,
      'bg-table': inTable,
      'bg-input': !inTable,
    }"
  >
    <div class="inline-grid focus-within:z-10 w-full hit-search">
      <div
        class="absolute inset-y-0 left-0 grid items-center pointer-events-none"
      >
        <hit-icon
          icon="search"
          :color="
            focussed || inputValue.length > 0
              ? 'text-input'
              : 'text-input-light'
          "
        />
      </div>
      <input
        :id="'search' + HitUUIDUtils.generate()"
        :ref="(ref) => (inputDomEl = ref)"
        :name="autoCompleteId"
        class="text-input leading-none appearance-none py-2 w-full rounded-r rounded-l-md pl-7 sm:text-sm sm:leading-5 h-8 focus:outline-none placeholder-light focus:ring-transparent border-none"
        :class="[inTable ? 'bg-table' : 'bg-input']"
        :placeholder="placeholder"
        :value="inputValue"
        @input="onInput"
        @keydown.enter.stop="onPressEnter"
        @change="onChange"
        @focus="onFocus"
        @blur="onBlur"
      >
      <div
        v-show="clearable && inputValue.length > 0"
        id="clearHitSearch"
        class="grid items-center mr-1"
      >
        <hit-icon
          id="clearHitSearch"
          icon="clear"
          :clickable="true"
          @click.stop="clearInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import HitIcon from '../icon/HitIcon.vue';
import {HitUUIDUtils} from '../../utils';

export default {
  name: 'HitSearch',
  components: {HitIcon},
  props: {
    /**
     * Value of the search
     */
    value: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Disable the search
     */
    disabled: Boolean,
    /**
     * Placeholder of the search
     */
    placeholder: {
      type: String,
      default: null,
    },
    /**
     * Enable clearing of the search
     */
    clearable: {
      type: Boolean,
      default: true,
    },
    /**
     * Set visibility of the search border
     */
    borderVisible: {
      type: Boolean,
      default: true,
    },
    /**
     * Set visibility of the border shadow
     */
    shadowVisible: {
      type: Boolean,
      default: true,
    },
    autoCompleteId: {
      type: String,
      required: false,
      default: 'default',
    },
    instantFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      focussed: false,
      inputDomEl: null,
    };
  },
  computed: {
    HitUUIDUtils() {
      return HitUUIDUtils;
    },
    inputValue() {
      if (this.value) {
        return this.value;
      }
      return '';
    },
    inTable() {
      return this.$parent.$options.name === 'HitTable';
    },
  },
  mounted() {
    if (this.instantFocus && this.inputDomEl) {
      this.inputDomEl.focus();
    }
  },
  methods: {
    clearInput() {
      this.$emit('change', '');
      this.$emit('input', '');
      this.$emit('clearInput', '');
    },
    onPressEnter($event) {
      $event.stopImmediatePropagation();
      const self = this;
      _.debounce(function () {
        self.$emit('pressEnter', $event.target.value);
        if (this.validationState) {
          this.validationState.$touch();
        }
      }, 100)();
    },
    onInput($event) {
      $event.stopImmediatePropagation();
      const self = this;
      _.debounce(function () {
        self.$emit('input', $event.target.value);
        if (this.validationState) {
          this.validationState.$touch();
        }
      }, 100)();
    },
    onChange($event) {
      this.$emit('change', $event.target.value);
      $event.stopImmediatePropagation();
    },
    onFocus($event) {
      this.focussed = true;
      this.$emit('focus');
      $event.stopImmediatePropagation();
    },
    onBlur($event) {
      this.focussed = false;
      this.$emit('blur', $event.target.value);
      $event.stopImmediatePropagation();
    },
  },
};
</script>
<style scoped>
.hit-search {
  grid-template-columns: minmax(0, 1fr) max-content;
}
</style>
