<template>
  <hit-input
    type="time"
    :value="timeValue"
    @change="onTimeChange"
  />
</template>
<script>
import HitInput from './HitInput.vue';

export default {
  name: 'HitInputTime',
  components: {HitInput},
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    timeValue() {
      const hours = Math.floor(this.value / 60 / 60);
      const minutes = (this.value / 60) % 60;
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      let result = `${formattedHours}:${formattedMinutes}`;
      return result;
    },
  },
  methods: {
    onTimeChange(timeValue) {
      const hours = parseInt(timeValue.split(':')[0]);
      const minutes = parseInt(timeValue.split(':')[1]);
      const result = (hours * 60 + minutes) * 60;
      this.$emit('changeTime', result);
    },
  },
};
</script>
