import yamlLoader from 'js-yaml';

export default class {
  static toJson(yaml) {
    return yamlLoader.load(yaml);
  }

  static toYaml(json) {
    return yamlLoader.dump(json);
  }
}
