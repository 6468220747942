import Axios from 'axios';
import {globals} from '@/../../app/src/globals.js';
import qs from 'qs';
import {v4 as uuidv4} from 'uuid';

export default class BaseService {
  constructor(modelCls) {
    if (modelCls !== undefined) {
      this.modelCls = modelCls;
    }
    this.api = Axios.create({
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      paramsSerializer: (params) =>
        qs.stringify(params, {arrayFormat: 'repeat'}),
    });
    this.api.interceptors.request.use(
      async (config) => {
        let baseURL = this.getBaseURL();
        if (baseURL) {
          config.baseURL = baseURL;
        }
        const token_is_valid =
          globals.$keycloak.token && !globals.$keycloak.isTokenExpired();
        if (token_is_valid) {
          config.headers['Authorization'] = `Bearer ${globals.$keycloak.token}`;
        } else {
          console.error(`Error : Session expired`);
          const msg = globals.$i18n.global.t(
            'hit-app.keycloak.session-expired-please-login'
          );
          if (confirm(msg)) {
            globals.$keycloak.logout();
          }
          throw new Axios.Cancel(msg);
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  getBaseURL() {
    if (globals.$appContext) {
      return globals.$appContext.postgrestUrl;
    }
    return undefined;
  }

  getReportURL() {
    if (globals.$appContext) {
      return globals.$appContext.reportUrl;
    }
    return undefined;
  }

  formatFieldsList(fields) {
    if (fields !== null && fields !== undefined && fields !== '') {
      if (
        (typeof fields === 'string' || fields instanceof String) &&
        fields.trim() !== ''
      ) {
        fields = fields.split(',');
      }
      if (Array.isArray(fields) && fields.length > 0) {
        let baseFields = [];
        let subFields = {};
        fields.forEach((field) => {
          let split = field.split('.');
          if (split.length > 1) {
            if (!(split[0] in subFields)) {
              subFields[split[0]] = [];
            }
            subFields[split[0]] = subFields[split[0]].concat(split[1]);
          } else {
            baseFields = baseFields.concat(split[0]);
          }
        });
        let result = '';
        if (!baseFields.includes('id')) {
          baseFields.push('id');
        }
        baseFields.forEach((field) => (result += field + ','));
        for (let key in subFields) {
          result += key + '(';
          subFields[key].forEach((field) => (result += field + ','));
          result = result.slice(0, -1);
          result += '),';
        }
        result = result.slice(0, -1);
        return this.translateStringToSnakeCase(result);
      }
    }
    return null;
  }

  formatAllFieldsList() {
    let result = '*';
    if (this.modelCls !== undefined) {
      const mtmRelations = this.modelCls.getMTMRelations();
      for (let key in mtmRelations) {
        result += ',' + mtmRelations[key].childTableName + '(*)';
      }
    }
    return result;
  }

  formatSortArg(sort) {
    if (!sort) {
      return null;
    }
    if (Array.isArray(sort)) {
      if (sort.length > 0) {
        return this.translateStringToSnakeCase(sort.join(','));
      }
      return null;
    }
    return this.translateStringToSnakeCase(sort);
  }

  formatQueryParams({fields, q, qFields, sort, filters, limit, offset}) {
    let params = {};

    let fieldsArg =
      this.formatFieldsList(fields) || this.formatAllFieldsList() || '*';
    params['select'] = fieldsArg;

    // if (q !== null && q !== undefined && q.trim() !== "") {
    //     params['q'] = q;
    //     let qFieldsArg = this.formatFieldsList(qFields);
    //     if (qFieldsArg) {
    //         params['qFields'] = this.formatFieldsList(qFields);
    //     }
    // }

    let sortArg = this.formatSortArg(sort);
    if (sortArg) {
      params['order'] = sortArg;
    }
    if (limit) {
      params['limit'] = limit;
    }
    if (offset) {
      params['offset'] = offset;
    }

    // FIXME using qs to bypass axios's nability to send the same query param twice: https://github.com/axios/axios/issues/709
    // TODO use it in a cleaner way (specify a serializer for axios)
    return {...filters, ...params};
  }

  translateKeysToSnakeCase(item) {
    let result = {};
    for (const [key, value] of Object.entries(item)) {
      let keySnake = this.translateStringToSnakeCase(key);
      result[keySnake] = value;
    }
    return result;
  }

  translateStringToSnakeCase(s) {
    if (s) {
      return s
        .trim()
        .split(/\.?(?=[A-Z])/)
        .join('_')
        .toLowerCase();
    }
    return null;
  }

  async $create({path, item}) {
    try {
      let config = {
        headers: {
          Authorization: 'Bearer ' + globals.$keycloak.token,
          'Content-Profile': globals.$keycloak.tokenParsed.schema_id,
        },
      };
      if (!item.id) {
        item.id = uuidv4();
      }
      return await Axios.post(
        this.getBaseURL() + path,
        this.translateKeysToSnakeCase(item),
        config
      );
    } catch (error) {
      console.error('Error in create():', error);
      throw error;
    }
  }

  async $deleteItems({path, params}) {
    try {
      let config = {
        params: params,
        headers: {
          Authorization: 'Bearer ' + globals.$keycloak.token,
          'Content-Profile': globals.$keycloak.tokenParsed.schema_id,
        },
      };
      // await this.api.delete(path, config);
      await Axios.delete(this.getBaseURL() + path, config);
      return true;
    } catch (error) {
      console.error('Error in delete():', error);
      throw error;
    }
  }

  async $delete({path, item}) {
    try {
      let config = {
        params: {id: 'eq.' + item.id},
        headers: {
          Authorization: 'Bearer ' + globals.$keycloak.token,
          'Content-Profile': globals.$keycloak.tokenParsed.schema_id,
        },
      };
      // await this.api.delete(path, config);
      await Axios.delete(this.getBaseURL() + path, config);
      return true;
    } catch (error) {
      console.error('Error in delete():', error);
      throw error;
    }
  }

  async $fetchItems({
    path,
    fields,
    q,
    qFields,
    sort,
    filters,
    limit,
    offset,
    getCount,
  }) {
    try {
      let config = {
        params: this.formatQueryParams({
          fields,
          q,
          qFields,
          sort,
          filters,
          limit,
          offset,
        }),
        headers: {
          Authorization: 'Bearer ' + globals.$keycloak.token,
          'Accept-Profile': globals.$keycloak.tokenParsed.schema_id,
        },
      };
      if (getCount) {
        config.headers['Prefer'] = 'count=exact'; //TODO check if count=planned should be used instead
      }
      // return await this.api.get(path, config);
      return await Axios.get(this.getBaseURL() + path, config);
    } catch (error) {
      console.error('Error in fetchItems():', error);
      throw error;
    }
  }

  async $fetchItem({path, id, fields, q, qFields, sort}) {
    try {
      let config = {
        params: this.formatQueryParams({fields, q, qFields, sort}),
        headers: {
          Authorization: 'Bearer ' + globals.$keycloak.token,
          'Accept-Profile': globals.$keycloak.tokenParsed.schema_id,
        },
      };
      config.params.id = 'eq.' + id;
      return await Axios.get(this.getBaseURL() + path, config);
    } catch (error) {
      console.error('Error in fetchItem():', error);
      throw error;
    }
  }

  async $update({path, item}) {
    try {
      let config = {
        params: {id: 'eq.' + item.id},
        headers: {
          Authorization: 'Bearer ' + globals.$keycloak.token,
          'Content-Profile': globals.$keycloak.tokenParsed.schema_id,
          Prefer: 'return=representation',
        },
      };
      return await Axios.patch(
        this.getBaseURL() + path,
        this.translateKeysToSnakeCase(item),
        config
      );
    } catch (error) {
      console.error('Error in update():', error);
      throw error;
    }
  }

  async $upsert({path, item}) {
    let config = {
      headers: {
        prefer: 'resolution=merge-duplicates',
        Authorization: 'Bearer ' + globals.$keycloak.token,
        'Content-Profile': globals.$keycloak.tokenParsed.schema_id,
      },
    };
    return await Axios.post(this.getBaseURL() + path, item, config);
  }

  async $print(
    id,
    template_id,
    page_layout,
    language,
    fallBackLanguage,
    address = {}
  ) {
    let config = {
      params: {
        id: 'eq.' + id,
        select:
          '*,author(first_name,last_name),' +
          'validator(first_name,last_name),' +
          'form_definition(designation_fr_be,' +
          'designation_de_be, ' +
          'designation_nl_be,' +
          'designation_fr_lu,' +
          'designation_de_lu,' +
          'designation_fr_fr,' +
          'designation_de_de,' +
          'designation_nl_nl,' +
          'designation_en_gb,' +
          'designation_en_in,' +
          'designation_mr_in,' +
          'designation_hi_in,' +
          'definition)',
      },
      headers: {
        Authorization: 'Bearer ' + globals.$keycloak.token,
        'Accept-Profile': globals.$keycloak.tokenParsed.schema_id,
        Prefer: 'return=representation',
      },
    };

    let self = this;
    let data = {};
    await Axios.get(this.getBaseURL() + 'form', config).then(async function (
      response
    ) {
      const userLocale =
        navigator.languages && navigator.languages.length
          ? navigator.languages[0]
          : navigator.language;

      let designation =
        'designation_' + userLocale.replace('-', '_').toLowerCase();

      let report_name = response.data[0][designation];
      if (!report_name) {
        report_name = response.data[0]['designation_en_gb'];
      }

      data = {
        name: report_name,
        template_id: template_id,
        page_layout: page_layout,
        data: JSON.parse(JSON.stringify(response.data[0])),
      };
      data.data['address'] = address;
    });
    config = {
      headers: {
        Authorization: 'Bearer ' + globals.$keycloak.token,
        'Accept-Profile': globals.$keycloak.tokenParsed.schema_id,
        'Accept-language': [language, fallBackLanguage].join(','),
      },
      responseType: 'arraybuffer',
    };

    return await Axios.post(self.getReportURL() + 'reports', data, config)
      .then(function (response) {
        let blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        let filename = '';
        let contentDisposition = response.headers['content-disposition']; // ex: "attachment;filename='FormDefinition complete example 21/08/2020.pdf'"
        if (contentDisposition) {
          filename = (
            (contentDisposition.split('filename=')[1] || '').split(';')[0] || ''
          )
            .replace(/'/g, '')
            .replace(/"/g, '');
        }
        if (!filename) {
          filename = 'form.pdf';
        }
        link.download = filename;

        link.click();
      })
      .catch(function (error) {
        console.error('Error in print():', error);
        throw error;
      });
  }
}
