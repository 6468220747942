<template>
  <hit-card
    class="border border-table flex flex-col cursor-pointer w-full px-2 pt-1 mb-1"
    :display-shadow="false"
  >
    <div
      v-for="itemProperty in itemPropertiesToDisplay"
      :key="itemProperty.propertyName"
      class="hit-form-row"
    >
      <div class="font-semibold pb-1">
        {{ itemProperty.header }} :
      </div>
      <div class="mb-2">
        <slot
          :name="generateCustomSlotName(itemProperty.propertyName)"
          :form-data="item"
          :item-property="itemProperty"
          :validation-state="{}"
        >
          <div>
            <hit-input-generic-read-only
              :value="item[itemProperty.propertyName]"
              :value-type="getValueType(itemProperty.propertyName, item)"
            />
          </div>
        </slot>
      </div>
    </div>
  </hit-card>
</template>
<script>
import {HitCard} from '../card';
import {HitInputGenericReadOnly} from '../form';
import HitTableDataMixin from './HitTableDataMixin';
export default {
  name: 'HitTableDataListDynamicCard',
  components: {HitInputGenericReadOnly, HitCard},
  mixins: [HitTableDataMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    itemPropertiesToDisplay: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
