import BaseModel from '../BaseModel';

export default class Staff extends BaseModel {
  constructor({
    id,
    no,
    userId,
    firstname,
    lastName,
    creationDate,
    modificationDate,
    active,
  }) {
    super();
    this.id = id;
    this.no = no;
    this.userId = userId;
    this.firstname = firstname;
    this.lastName = lastName;
    this.creationDate = creationDate;
    this.modificationDate = modificationDate;
    this.active = active;
  }

  static factory() {
    return new Staff({active: true});
  }

  static getMTMRelations() {
    return {
      tags: {
        pivotTableName: 'staff_tag',
        parentIdColumn: 'staff_id',
        childIdColumn: 'tag_id',
        childTableName: 'tag',
      },
    };
  }

  static getMTORelations() {
    return {
      communicationMode: {
        childIdColumn: 'communication_mode_id',
        childTableName: 'communication_mode',
      },
    };
  }

  static getKeyTranslations() {
    return {
      tag: 'tags',
    };
  }
}
