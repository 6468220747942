// @ is an alias to /src
import HitAppSelectMixin from './HitAppSelectMixin';
export default {
  mixins: [HitAppSelectMixin],
  props: {
    /**
     * Options of the select
     */
    options: {
      type: [Object, Array], // [{id='123', ...}, ... ] or { 123: {...}, ... }
      required: true,
    },
    /**
     * Selected options of the select
     */
    selectedOptions: {
      // FIXME used in conjunction with remote filter to be able to display selected but filtered items
      type: [Object, Array], // [{id='123', ...}, ... ] or { 123: {...}, ... }
      required: false,
      default: null,
    },
    /**
     * Fields used to render an item
     */
    dropdownLabelFields: {
      type: Array,
      default() {
        return [];
      },
    },
    /**
     * Separator used to render an item
     */
    labelSeparator: {
      type: String,
      default: ' ',
    },
    /**
     * Enable custom filter
     */
    customFilter: Boolean,
    /**
     * Adapt select width the selected value
     */
    adaptToContent: {
      type: Boolean,
      default: false,
    },
    /**
     * Enable loading of options
     */
    optionsLoading: Boolean,
    /**
     * Custom actions of the select
     * [{id: '...', 'buttonLabel': '...', buttonIcon: '...'}, {...} ]
     */
    customActions: {
      type: Array,
      default() {
        return [];
      },
    },
    /**
     * Set visibility of the placeholder
     */
    placeholderVisible: {
      type: Boolean,
      default: true,
    },
    /**
     * Set visibility of the borders
     */
    bordersVisible: {
      type: Boolean,
      default: true,
    },
    /**
     * Set visibility of the division line
     */
    divisionLine: {
      type: Boolean,
      default: true,
    },
    /**
     * Uses all width if true
     */
    fullWidth: {
      type: Boolean,
      default: true,
    },
    /**
     * Disable row label style if false
     */
    rowLabel: {
      type: Boolean,
      default: true,
    },
  },
};
