import i18n from '@/plugins/i18n'; //TODO check Super expression must either be null or a function

/**
 * needed to make keycloak, appcontext and i18n accessible in baseservice, which is in hit-base
 * @type {{$appContext: {}, $keycloak: {}, $i18n}}
 */
export const globals = {
  $i18n: i18n,
  $keycloak: {},
  $appContext: {},
};
