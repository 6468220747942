<template>
  <hit-container-entity-browser
    route="staff"
    :only-load-active-records="onlyLoadActiveRecords"
  />
</template>
<script>
import HitContainerEntityBrowser from './HitContainerEntityBrowser.vue';
export default {
  name: 'HitContainerStaffBrowser',
  components: {
    HitContainerEntityBrowser,
  },
  props: {
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
