<template>
  <hit-base-input
    :label="label"
    :validation-state="validationState"
    :display-label-column="displayLabelColumn"
    use-custom-height
    :full-width="false"
  >
    <textarea
      ref="input"
      :value="value"
      :rows="rows"
      :disabled="disabled"
      :readonly="readonly"
      class="override-default h-full p-2 resize border rounded focus:outline-none w-full focus:ring-transparent bg-input"
      :class="{
        'border-input focus:border-accent':
          (valid && pristine) || !validationState || (valid && dirty),
        'border-danger focus:border-danger': invalid && dirty,
      }"
      @keydown.tab="onTabKeyDown"
      @change="onTextAreaChange"
      @input="onTextAreaInput"
      @focus="onTextAreaFocus"
    />
    <template #help>
      <!-- @slot Help of the input -->
      <slot name="help" />
    </template>
  </hit-base-input>
</template>

<script>
import HitBaseInput from './HitBaseInput';
import HitFormValidationMixin from '../../../mixins/form/HitFormValidationMixin';
import HitInputMixin from '../../../mixins/form/HitInputMixin';
import _ from 'lodash';

function getTextAreaValue(value) {
  // TextArea value when empty is an empty string and not null
  return value || null;
}

export default {
  name: 'HitInputTextArea',
  components: {HitBaseInput},
  mixins: [HitInputMixin, HitFormValidationMixin],
  inheritAttrs: false,
  props: {
    /**
     * Value of the input
     */
    value: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Number of rows
     */
    rows: {
      type: Number,
      default: 3,
    },
    instantFocus: {
      type: Boolean,
      default: false,
    },
    useTabForIndent: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      cursorPosition: null,
    };
  },
  mounted() {
    if (this.instantFocus) {
      this.$refs.input.focus();
    }
  },
  methods: {
    onTabKeyDown(event) {
      if (this.useTabForIndent) {
        event.preventDefault();
        const textarea = event.target;
        const currentValue = textarea.value;
        textarea.value =
          currentValue.substring(0, textarea.selectionStart) +
          '\t' +
          currentValue.substring(textarea.selectionEnd);
        textarea.focus();
        const cursorPosition = textarea.selectionStart + '\t'.length;
        textarea.setSelectionRange(cursorPosition, cursorPosition);
      }
    },
    onTextAreaChange($event) {
      this.fireInputChange(getTextAreaValue($event.target.value));
      $event.stopImmediatePropagation();
    },
    onTextAreaInput: _.debounce(function ($event) {
      this.fireInputInput(getTextAreaValue($event.target.value));
      if (this.validationState) {
        this.validationState.$touch();
      }
    }, 100),
    onTextAreaFocus($event) {
      this.$emit('focus');
      $event.stopImmediatePropagation();
    },
  },
  watch: {
    value() {
      if (this.cursorPosition) {
        this.$refs.input.setSelectionRange(1, 1);
        this.cursorPosition = null;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
textarea {
  font-size: inherit;
  @apply py-1;
}

/* Otherwise the browser does not override the default color value for a textarea by using directly the tailwind class */
.override-default {
  @apply text-input;
}
</style>
