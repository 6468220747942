import {BaseChildEntityService} from '../../../../api';
import {makeForeignStore} from '../../../helpers/factory';
import Tag from '../../../../models/hit/common/Tag';
import {tagSchema} from '../../../../models/hit/common/schema';

export default makeForeignStore({
  modelCls: Tag,
  service: new BaseChildEntityService('address_tag', 'address_id', Tag),
  schema: tagSchema,
  primaryStoreNamespace: 'common/tag',
});
