<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-resource')"
    route="resource"
    auto-complete-id="resource"
    :table-attributes="tableAttributes"
    :default-sort="{property: 'no', order: 'asc'}"
    :only-load-active-records="onlyLoadActiveRecords"
    :data-list-config="listConfig"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {
  HitContainerAttribute,
  ATTRIBUTE_TYPES,
} from '../../container/HitContainerAttribute';
import {useI18n} from 'vue-i18n';
import {useConfigurationStore} from '../../../store';
export default {
  name: 'HitResourceBrowser',
  components: {
    HitEntityBrowser,
  },
  props: {
    customDesignationLocale: {
      type: String,
      required: false,
      default: null,
    },
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
    instantFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const configStore = useConfigurationStore();
    const {t, n} = useI18n();
    return {t, n, configStore};
  },
  data() {
    return {
      listConfig: {
        zone1Field: 'number',
        zone2Field: 'designation',
        zone3CustomFunction: (item) =>
          `${this.t('hit-app.common.price')}: ${this.n(
            parseFloat(item.price),
            'price'
          )}`,
      },
      tableAttributes: {
        number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'no',
          tableProperties: {
            header: this.t('hit-app.common.number'),
            maxWidth: '1fr',
          },
        }),
        designation: new HitContainerAttribute(
          ATTRIBUTE_TYPES.ML_WITH_FALLBACK,
          {
            tableProperties: {
              header: this.t('hit-base.common.designation'),
              mainSearch: true,
              maxWidth: '2fr',
            },
          }
        ),
        description: new HitContainerAttribute(
          ATTRIBUTE_TYPES.ML_WITH_FALLBACK
        ),
        unitId: new HitContainerAttribute(ATTRIBUTE_TYPES.FOREIGN_ID, {
          column: 'unit_id',
        }),
        unitUnit: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'unit(unit_' + this.configStore.mainLanguageSnakeCase + ')',
          transform: (unit) =>
            unit['unit_' + this.configStore.mainLanguageSnakeCase],
          tableProperties: {
            header: this.t('hit-app.common.unit'),
            maxWidth: '1fr',
          },
        }),
        categoryId: new HitContainerAttribute(ATTRIBUTE_TYPES.FOREIGN_ID, {
          column: 'resource_category_id',
        }),
        categoryName: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'resource_category(designation_' + this.configStore.mainLanguageSnakeCase + ')',
          transform: (resource_category) =>
              resource_category['designation_' + this.configStore.mainLanguageSnakeCase],
          tableProperties: {
            header: this.t('hit-app.common.category'),
            maxWidth: '1fr',
          },
        }),
        purchasePrice: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
          column: 'purchase_price',
          tableProperties: {
            header: this.t('hit-app.common.purchase-price'),
            maxWidth: '1fr',
          },
        }),
        price: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
          column: 'base_price',
          tableProperties: {
            header: this.t('hit-app.common.base-price'),
            maxWidth: '1fr',
          },
        }),
        categoryMargin: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
          column: 'resource_category(margin)',
          transform: (resource_category) =>
              resource_category['margin']
        }),
        salesMargin: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
          column: 'sales_margin',
        }),
        salesPrice: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
          column: 'sales_price',
        }),
        customisable: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
          tableProperties: {
            header: this.t('hit-app.resource.generic'),
            maxWidth: '1fr',
            hideByDefault: false,
          },
        }),
        composite: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
          tableProperties: {
            propertyName: 'composite',
            header: this.t('hit-app.resource.composite'),
            maxWidth: '1fr',
          },
        }),
        active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
          tableProperties: {
            propertyName: 'active',
            header: this.t('hit-app.common.active'),
            maxWidth: '1fr',
          },
        }),
        tags: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS, {
          tableProperties: {
            header: this.t('hit-base.common.tags'),
            maxWidth: '1fr',
          },
        }),
      },
    };
  },
};
</script>
