import BaseModel from '../hit/BaseModel';

class User extends BaseModel {
  constructor({id, username, firstname, lastName, email}) {
    super();
    this.id = id;
    this.username = username;
    this.firstname = firstname;
    this.lastName = lastName;
    this.email = email;
  }
  static factory() {
    return new User({});
  }
}
export {User};
