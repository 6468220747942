import {createI18n} from 'vue-i18n';
import {getLocale} from '@hit/components/src/plugins/i18n/i18n';

function loadLocaleMessages() {
  const locales = require.context(
    '../../locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  const messagesToImport = [loadComponentsLocaleMessages()];
  messagesToImport.forEach((toImport) => {
    Object.keys(toImport).forEach((locale) => {
      if (messages[locale] === undefined) {
        messages[locale] = {};
      }
      Object.keys(toImport[locale]).forEach((key) => {
        if (messages[locale][key] === undefined) {
          messages[locale][key] = toImport[locale][key];
        }
      });
    });
  });

  return messages;
}

function loadComponentsLocaleMessages() {
  const locales = require.context(
    '../../../../hit-components/src/locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const europeDateFormat = {
  longSeconds: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  long: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  short: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
};

const numberFormats = {
  'en-GB': {
    useGrouping: true,
    price: {
      style: 'currency',
      currency: 'EUR',
    },
    priceWithoutDecimal: {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    },
    decimal0Digits: {
      type: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal2Digits: {
      type: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    decimal3Digits: {
      type: 'decimal',
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    decimal4Digits: {
      type: 'decimal',
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    },
  },
  'fr-BE': {
    useGrouping: true,
    price: {
      style: 'currency',
      currency: 'EUR',
    },
    priceWithoutDecimal: {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    },
    decimal0Digits: {
      type: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal2Digits: {
      type: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    decimal3Digits: {
      type: 'decimal',
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    decimal4Digits: {
      type: 'decimal',
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    },
  },
  'de-BE': {
    useGrouping: true,
    price: {
      style: 'currency',
      currency: 'EUR',
    },
    priceWithoutDecimal: {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    },
    decimal0Digits: {
      type: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal2Digits: {
      type: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    decimal3Digits: {
      type: 'decimal',
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    decimal4Digits: {
      type: 'decimal',
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    },
  },
  'nl-BE': {
    useGrouping: true,
    price: {
      style: 'currency',
      currency: 'EUR',
    },
    priceWithoutDecimal: {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    },
    decimal0Digits: {
      type: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal2Digits: {
      type: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    decimal3Digits: {
      type: 'decimal',
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    decimal4Digits: {
      type: 'decimal',
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    },
  },
};

const datetimeFormats = {
  'fr-BE': europeDateFormat,
  'nl-BE': europeDateFormat,
  'de-BE': europeDateFormat,
  'en-GB': europeDateFormat,
};

export const i18n = createI18n({
  locale: getLocale(),
  allowComposition: true,
  globalInjection: true,
  legacy: false,
  fallbackLocale: {
    fr: ['fr-BE'],
    de: ['de-BE'],
    nl: ['nl-BE'],
    en: ['en-GB'],
    default: ['en-GB'],
  },
  numberFormats,
  datetimeFormats,
  messages: loadLocaleMessages(),
});

export const $n = i18n.global.n;
export const $d = i18n.global.d;

export const translate = (key, payload) => {
  if (!key) {
    return '';
  }
  return i18n.global.t(key, payload);
};

export default function installVueI18N(app) {
  app.use(i18n);
}
