<template>
  <hit-base-input
    use-custom-height
    :full-width="false"
  >
    <div
      v-if="type === 'text'"
      class="flex flex-col"
    >
      <div
        v-for="language in displayedLanguages"
        :key="language.id"
        class="mb-1"
      >
        <hit-input
          :value="values ? values[language.id] : ''"
          :inline-input="true"
          :validation-state="validationStates[language.id]"
          :disabled="disabled"
          :extra-slot-size="sortedLanguages.length > 1 ? '6rem' : 0"
          :enable-errors="enableErrors"
          @change="(value) => onChange(language.id, value)"
        >
          <template
            v-if="sortedLanguages.length > 1"
            #extra
          >
            <div class="flex items-center">
              <div class="ml-1 mr-1">
                {{ language.shortDesignation }}
              </div>
              <hit-button
                v-show="language.id === defaultLocale"
                class="h-8 w-8"
                :remove-margin="true"
                :remove-padding="true"
                prefix-icon="language"
                :tooltip="displayTranslationsTooltip"
                @click="allLanguagesVisible = !allLanguagesVisible"
              />
            </div>
          </template>
        </hit-input>
      </div>
    </div>
    <div v-else-if="type === 'textarea'">
      <hit-tabs
        v-if="sortedLanguages.length > 1"
        @tabSelect="onTabSelected"
      >
        <hit-tab
          v-for="language in sortedLanguages"
          :id="language.id"
          :key="language.id"
          :title="language.longDesignation"
          :visible="language.id === visibleLanguage"
        >
          <hit-input-text-area
            :value="values ? values[language.id] : ''"
            :rows="rows"
            :disabled="disabled"
            :inline-input="true"
            inline-label-width="1fr"
            @change="(value) => onChange(language.id, value)"
          />
        </hit-tab>
      </hit-tabs>
      <hit-input-text-area
        v-else
        :value="values ? values[defaultLocale] : ''"
        :rows="rows"
        :disabled="disabled"
        :inline-input="true"
        inline-label-width="1fr"
        @change="(value) => onChange(defaultLocale, value)"
      />
    </div>
    <div v-else-if="type === 'richtext'">
      <hit-tabs
        v-if="sortedLanguages.length > 1"
        @tabSelect="onTabSelected"
      >
        <hit-tab
          v-for="language in sortedLanguages"
          :id="language.id"
          :key="language.id"
          :title="language.longDesignation"
          :visible="language.id === visibleLanguage"
        >
          <hit-input-rich-text
            :value="values ? values[language.id] : ''"
            :rows="rows"
            :inline-input="true"
            :disabled="disabled"
            inline-label-width="1fr"
            @change="(value) => onChange(language.id, value)"
          />
        </hit-tab>
      </hit-tabs>
      <hit-input-rich-text
        v-else
        :value="values ? values[defaultLocale] : ''"
        :rows="rows"
        :inline-input="true"
        inline-label-width="1fr"
        @change="(value) => onChange(defaultLocale, value)"
      />
    </div>
  </hit-base-input>
</template>
<script>
import HitInput from './HitInput.vue';
import HitInputTextArea from './HitInputTextArea.vue';
import HitInputRichText from './HitInputRichText.vue';
import HitButton from '../../button/HitButton.vue';
import HitTab from '../../tabs/HitTab.vue';
import {useI18n} from 'vue-i18n';
import HitBaseInput from './HitBaseInput.vue';

export default {
  //TODO implement validation states
  name: 'HitInputMultiLanguage',
  components: {
    HitInput,
    HitButton,
    HitInputTextArea,
    HitTab,
    HitInputRichText,
    HitBaseInput,
  },
  inject: ['tHitField'],
  props: {
    values: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
      validator: function (value) {
        // The value must match one of these strings
        return ['text', 'textarea', 'richtext'].indexOf(value) !== -1;
      },
    },
    rows: {
      type: Number,
      required: false,
      default: null,
    },
    allLanguagesVisibleDefault: {
      type: Boolean,
      default: false,
    },
    validationStates: {
      type: Object,
      required: false,
      default: null,
    },
    locales: {
      type: Array,
      required: true,
    },
    parseLocaleFunction: {
      type: Function,
      required: false,
      default: (locale) => locale,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    enableErrors: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      allLanguagesVisible: this.allLanguagesVisibleDefault,
      selectedLanguageTab: null,
    };
  },
  computed: {
    displayedLanguages() {
      if (!this.allLanguagesVisible) {
        return [this.sortedLanguages[0]];
      } else {
        return this.sortedLanguages;
      }
    },
    sortedLanguages() {
      const parsedLocales = this.locales.map(this.parseLocaleFunction);
      return parsedLocales;
    },
    displayTranslationsTooltip() {
      if (this.allLanguagesVisible) {
        return this.t('hit-base.common.hide-translations');
      } else {
        return this.t('hit-base.common.show-translations');
      }
    },
    defaultLocale() {
      return this.sortedLanguages[0].id;
    },
    visibleLanguage() {
      if (this.selectedLanguageTab !== null) {
        return this.selectedLanguageTab;
      } else {
        return this.defaultLocale;
      }
    },
  },
  methods: {
    onChange(key, value) {
      const newValueObject = JSON.parse(JSON.stringify(this.values));
      newValueObject[key] = value;
      this.$emit('change', newValueObject);
    },
    onTabSelected($event) {
      this.selectedLanguageTab = $event;
    },
  },
};
</script>
