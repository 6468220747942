import {BaseRootEntityService} from '../../../../api';
import {makeEntityStore} from '../../../helpers/factory';
import CommunicationMode from '../../../../models/hit/common/CommunicationMode';
import {communicationModeSchema} from '../../../../models/hit/common/schema';

export default makeEntityStore({
  modelCls: CommunicationMode,
  service: new BaseRootEntityService('communication_mode', CommunicationMode),
  schema: communicationModeSchema,
});
