import axios from 'axios';
import {globals} from '../../../app/src/globals';
export default class KeycloakUserService {
  init(keycloak) {
    this.config = {
      headers: {
        Authorization: 'Bearer ' + keycloak.token,
      },
    };
    this.companyId = keycloak.tokenParsed.company_id;
  }

  async fetchItems() {
    if (!this.config) {
      this.init(globals.$keycloak);
    }
    return axios.get(
      `${process.env.VUE_APP_KEYCLOAK_AUTH_URL}admin/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/groups/${this.companyId}/members`,
      this.config
    );
  }
}
