import BaseRootEntityService from '../../../../api/base/BaseRootEntityService';

// @ is an alias to /src
export default {
  props: {
    tagId: {
      type: String,
      required: true,
    },
  },
  computed: {
    storeFilters() {
      return {
        select: this.fields,
        'tag.id': 'eq.' + this.tagId,
      };
    },
  },
  methods: {
    generateDeletePromises(selectedItems) {
      let promises = [];

      let baseRootEntityService = null;

      if (this.storeNamespace) {
        selectedItems.forEach((itemToDelete) => {
          baseRootEntityService = new BaseRootEntityService(
            `${this.storeNamespace}/${itemToDelete.id}/tags`
          );
          promises.push(baseRootEntityService.delete({item: {id: this.tagId}}));
        });
      } else {
        throw 'Store namespace not set';
      }

      return promises;
    },
  },
};
