// from https://www.xiegerts.com/post/creating-vue-component-library-plugin/

import '../../../tailwind.css';
import './assets/material-icons.css';
import * as components from './components';
import * as directiveRegisterFunctions from './directives';
import {
  installSanitize,
  installAxios,
  installAlert,
  installVueI18N,
} from './plugins';
import * as JsYaml from 'js-yaml';
import {GeolocationService, NominatimService} from './api/geolocation';
import 'material-symbols';

function translateStringToSnakeCase(s) {
  if (s) {
    return s
      .trim()
      .split(/\.?(?=[A-Z])/)
      .join('_')
      .toLowerCase();
  }
  return null;
}

const HitComponentsVuePlugin = {
  install(app /*, options = {}*/) {
    // 1. Register Component
    // componentName is not the same as component.name !

    for (const componentName in components) {
      const component = components[componentName];
      try {
        app.component(component.name, component);
      } catch (e) {
        console.error(
          `Error registrating component ${componentName} - ${component.name}`,
          component,
          e
        );
        throw e;
      }
    }
    for (const directiveRegisterFunctionName in directiveRegisterFunctions) {
      const directiveRegisterFunction =
        directiveRegisterFunctions[directiveRegisterFunctionName];
      if (typeof directiveRegisterFunction === 'function') {
        directiveRegisterFunction(app);
      } else {
        console.warn(
          `registrating directive function not a function :  ${directiveRegisterFunction} = ${typeof directiveRegisterFunction}`
        );
      }
    }

    installSanitize(app);
    installVueI18N(app);
    installAxios(app);
    installAlert(app);
    app.config.globalProperties.$geolocation = new GeolocationService({
      geolocationAPI: new NominatimService(),
    });
    app.provide('toSnakeCase', translateStringToSnakeCase);
    window.jsyaml = JsYaml;
  },
};

export {HitComponentsVuePlugin};
export * from './components';
export * from './directives';
export * from './mixins';
export * from './models';
export * from './plugins';
export * from './utils';
