import BaseRootEntityService from '../../../api/base/BaseRootEntityService';
import {Resource, resourceSchema} from '../../../models';
import tag from './tag';
import {makeEntityStore} from '../../helpers/factory';

export default makeEntityStore({
  modelCls: Resource,
  service: new BaseRootEntityService('resource', Resource),
  schema: resourceSchema,
  tag,
});
