export default class {
  static getWidthFromWord(aWord) {
    let wordLength = aWord.length;

    if (wordLength < 10) {
      return 'w-32';
    } else if (wordLength < 17 && wordLength >= 10) {
      return 'w-48';
    } else if (wordLength >= 17 && wordLength < 22) {
      return 'w-56';
    } else {
      return 'w-auto';
    }
  }
}
