<template>
  <hit-app-select
    :readonly="readonly"
    :loading="isSelectLoading"
    :options-loading="isSelectOptionsLoading"
    :label="label"
    :value="value"
    :options="options"
    :selected-options="selectedOptions"
    :multiple="multiple"
    :create-label="createLabelString"
    :label-fields="labelFields"
    :disabled="disabled"
    :validation-state="validationState"
    :placeholder="placeholder"
    :custom-actions="customActions"
    :filterable="filterable"
    :custom-filter="filterable"
    :clearable="clearable"
    :enable-clear="!!clearable"
    :inline-input="inlineInput"
    :reset-validation-if-valid="resetValidationIfValid"
    :label-separator="labelFieldsSeparator"
    :instant-focus="instantFocus"
    @change="onChange"
    @filter="onFilter"
    @show-options="onShowOptions"
  >
    <template #itemDesignation="{item}">
      <!-- @slot Designation of the item -->
      <slot
        name="itemDesignation"
        :item="item"
      />
    </template>

    <template #help>
      <!-- @slot Help of the item input -->
      <slot name="help" />
    </template>
    <template #creation="{closeSlotFn, selectFn, closeDropdownFn}">
      <hit-app-form
        :value="{}"
        :store-namespace="optionsStoreNamespace"
        :fields="labelFields"
        :validations="creationValidations"
        cancellable
        :save-label="t('hit-components.common.add')"
        class="app-select-form"
        @created="onCreationFormCreated($event.id, selectFn, closeDropdownFn)"
        @cancel="onCreationFormCancel(closeSlotFn)"
      >
        <template
          #default="{formData, validationState: creationFormValidationState}"
        >
          <!-- @slot Item creation form inputs -->
          <slot
            name="createForm"
            :form-data="formData"
            :validation-state="creationFormValidationState"
          />
        </template>
      </hit-app-form>
    </template>
  </hit-app-select>
</template>

<script>
import {
  HitLoadingEmitterMixin,
  HitFormValidationMixin,
  HitAppSelectMixin,
} from '@hit/components';
import HitAppSelect from '../../form/input/HitAppSelect';
import {useI18n} from 'vue-i18n';
import HitAppForm from '../form/HitAppForm.vue';

export default {
  name: 'HitAppSelectEntity',
  components: {HitAppSelect, HitAppForm},
  mixins: [HitLoadingEmitterMixin, HitFormValidationMixin, HitAppSelectMixin],
  props: {
    /**
     * ID of the parent entity
     */
    parentId: {
      type: String,
      default: null,
    },
    /**
     * Namespace of the options
     */
    optionsStoreNamespace: {
      type: String,
      required: true,
    },

    additionalQueryFields: {
      type: [String],
      required: false,
      default() {
        return [];
      },
    },
    /**
     * Filters of the store
     */
    storeFilters: {
      type: Object,
      default: null,
    },
    /**
     * Label separator
     */
    labelFieldsSeparator: {
      type: String,
      default: null,
    },
    /**
     * Enable creation of entities
     */
    creatable: Boolean,
    /**
     * Creation form validation
     */
    creationValidations: {
      type: Object,
      default() {
        return {
          //property: { required: true },
        };
      },
    },
    clearable: Boolean,
    instantFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      fetchOptionsQueryId: undefined,
      fetchSelectedOptionsQueryIds: undefined,
      filterValue: undefined,
    };
  },
  computed: {
    options() {
      if (
        this.optionsStoreNamespace &&
        this.fetchOptionsQueryId &&
        !this.isOptionsLoading
      ) {
        return this.$store.getters[`${this.optionsStoreNamespace}/items`](
          this.fetchOptionsQueryId
        );
      } else {
        return [];
      }
    },
    createLabelString() {
      return this.t('hit-components.common.add');
    },
    isFetchSelectedOptionsLoading() {
      if (!this.fetchSelectedOptionsQueryIds) {
        return true;
      }
      return this.fetchSelectedOptionsQueryIds.some((x) => {
        return this.$store.getters[
          `${this.optionsStoreNamespace}/itemsLoading`
        ](x);
      });
    },
    isOptionsLoading() {
      return (
        this.fetchOptionsQueryId &&
        this.$store.getters[`${this.optionsStoreNamespace}/itemsLoading`](
          this.fetchOptionsQueryId
        )
      );
    },
    fetchOptionsQueryParams() {
      return {
        query: {
          q: this.filterValue || null,
          qFields: this.labelFields
            ? this.labelFields.concat(this.additionalQueryFields)
            : null,
          fields: this.labelFields.concat(this.additionalQueryFields),
          sort: this.labelFields,
          parentId: this.parentId,
          filters: this.storeFilters,
        },
        listenAdd: true,
      };
    },
    selectedOptionsIds() {
      return this.value
        ? Array.isArray(this.value)
          ? this.value
          : [this.value]
        : [];
    },
    selectedOptions() {
      if (
        this.fetchSelectedOptionsQueryParamsList &&
        !this.isFetchSelectedOptionsLoading
      ) {
        let result = this.selectedOptionsIds.map((itemId) => {
          return this.$store.getters[`${this.optionsStoreNamespace}/item`](
            itemId
          );
        });
        return result;
      } else {
        return [];
      }
    },
    fetchSelectedOptionsQueryParamsList() {
      return this.selectedOptionsIds.map((itemId) => {
        return {
          query: {
            fields: this.labelFields.concat(this.additionalQueryFields),
            parentId: this.parentId,
            id: itemId,
          },
        };
      });
    },
    isSelectLoading() {
      return this.loading || this.isFetchSelectedOptionsLoading;
    },
    isSelectOptionsLoading() {
      return this.isSelectLoading || this.isOptionsLoading;
    },
    customActions() {
      return this.creatable
        ? [
            {
              id: 'creation',
              buttonLabel: this.createLabelString,
              buttonIcon: 'add',
            },
          ]
        : null;
    },
    isOptionsFetchedAtLeastOnce() {
      return !!this.fetchOptionsQueryId;
    },
  },
  watch: {
    fetchOptionsQueryParams: {
      immediate: false,
      handler(newValue, oldValue) {
        // Do not refresh if not yet loaded
        if (
          this.isOptionsFetchedAtLeastOnce &&
          JSON.stringify(newValue) !== JSON.stringify(oldValue)
        ) {
          this.fetchOptions();
        }
      },
    },
    fetchSelectedOptionsQueryParamsList: {
      immediate: true,
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.fetchSelectedOptions();
        }
      },
    },
    isSelectLoading: {
      immediate: true,
      handler(newValue) {
        this.setLoading(newValue);
      },
    },
  },
  methods: {
    onChange(value) {
      this.fireInputChange(value);
    },
    onFilter(value) {
      this.filterValue = value;
    },
    onCreationFormCreated(id, selectFn, closeDropdownFn) {
      selectFn(id);
      closeDropdownFn();
    },
    onCreationFormCancel(closeSlotFn) {
      closeSlotFn();
      this.resetValidation();
    },
    async fetchOptions() {
      this.fetchOptionsQueryId = await this.$store.dispatch(
        `${this.optionsStoreNamespace}/fetchItems`,
        this.fetchOptionsQueryParams
      );
    },
    async fetchSelectedOptions() {
      // fetch current items 1 by 1, see http://gitlab.ficos.com/hit-office-web/hit-office-web/-/merge_requests/61#note_12419
      this.fetchSelectedOptionsQueryIds = undefined;
      let promises = this.fetchSelectedOptionsQueryParamsList.map((params) =>
        this.$store.dispatch(`${this.optionsStoreNamespace}/fetchItem`, params)
      );
      this.fetchSelectedOptionsQueryIds = await Promise.all(promises);
    },
    resetValidation() {
      if (this.creationFormValidationState) {
        this.$nextTick(() => {
          this.creationFormValidationState.$reset();
        });
      }
    },
    async onShowOptions() {
      // load on first show
      if (!this.isOptionsFetchedAtLeastOnce) {
        await this.fetchOptions();
      }
    },
  },
};
</script>
