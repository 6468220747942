<template>
  <footer class="footer #1C63F0 text-white h-15">
    <div class="content-container">
      <p class="text-2xl flex items-center">
        &copy; 2023 Hit-Online. All rights reserved.
      </p>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'MyFooter',
};
</script>

<style scoped>
.footer {
  background-color: #0066cc;
  color: white;
}

.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100%;
}

.header h1,
.footer p {
  margin: 0;
}

.adminui-card h1 {
  margin-top: 0;
}
</style>
