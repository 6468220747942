import {ActionService, DataService} from '@hit/base/src';
import {HitUUIDUtils} from '@hit/components';
import {globals} from '../globals';

function generateLinkedEntities(a, userLan) {
  const availableEntities = [
    'address',
    'staff',
    'equipment',
    'resource',
    'project_part',
  ];
  const linkedEntities = [];
  availableEntities.forEach((entity) => {
    if (a[entity]) {
      if (entity === 'resource') {
        linkedEntities.push(
          [a.resource.no, a.resource[`designation_${userLan}`]]
            .filter((i) => i !== null && i !== '')
            .join(' - ')
        );
      } else {
        linkedEntities.push(
          [a[entity].no, a[entity].designation]
            .filter((i) => i !== null && i !== '')
            .join(' - ')
        );
      }
    }
  });
  return linkedEntities;
}

export function actionToCard(
  actionResponseObject,
  finishedDuration,
  runningState,
  lastStart,
  userLanguage
) {
  return {
    no: actionResponseObject.no,
    designation: actionResponseObject.designation,
    assignee: actionResponseObject.assignee_id,
    creation_user_id: actionResponseObject.creation_user_id,
    status: actionResponseObject.status,
    id: actionResponseObject.id,
    due_date: actionResponseObject.due_date,
    url: actionResponseObject.url,
    urgency: actionResponseObject.urgency,
    importance: actionResponseObject.importance,
    sort_order: actionResponseObject.sort_order,
    running_state: runningState,
    tags: actionResponseObject.tag,
    finished_duration: finishedDuration,
    last_start: lastStart,
    linkedEntities: generateLinkedEntities(actionResponseObject, userLanguage),
  };
}

function sortActionsByStatus(actions) {
  const sortedActions = {
    opened: [],
    assigned: [],
    closed: [],
  };
  actions.forEach((action) => {
    sortedActions[action.status.toLowerCase()].push(action);
  });
  return sortedActions;
}

function sortActionsByTags(actions) {
  const sortedActions = {};
  actions.forEach((action) => {
    if (action.tags.length === 0) {
      if (!('hit-app.action.no-tag' in sortedActions)) {
        sortedActions['hit-app.action.no-tag'] = [];
      }
      sortedActions['hit-app.action.no-tag'].push(action);
    } else {
      action.tags.forEach((tag) => {
        const key = tag.designation;
        if (!(key in sortedActions)) {
          sortedActions[key] = [];
        }
        sortedActions[key].push(action);
      });
    }
  });
  return sortedActions;
}

export function sortActions(sortType, actions) {
  switch (sortType) {
    case 'status':
      return sortActionsByStatus(actions);
    case 'tags':
      return sortActionsByTags(actions);
    default:
      throw Error('Unsupported sort type for actions');
  }
}

export function duplicateAction(actionId) {
  return ActionService.post('hit-action/duplicate', {
    values: {
      actionId: actionId,
    },
  });
}
