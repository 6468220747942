function showAlert(ctx, level, title, message) {
  const options = {
    confirmButtonText: 'OK',
    type: level,
  };
  return ctx.$alert(message, title, options);
}

export default function (app) {
  app.config.globalProperties.$alertSuccess = function (message) {
    return showAlert(this, 'success', 'Success', message);
  };
  app.config.globalProperties.$alertInfo = function (message) {
    return showAlert(this, 'info', 'Info', message);
  };
  app.config.globalProperties.$alertWarning = function (message) {
    return showAlert(this, 'warning', 'Warning', message);
  };
  app.config.globalProperties.$alertError = function (message) {
    return showAlert(this, 'error', 'Error', message);
  };
}
