import BaseModel from '../BaseModel';

export default class FormTemplate extends BaseModel {
  constructor({
    no,
    active,
    designation,
    comment,
    memo,
    creation_date,
    modification_date,
    custom_data,
    status,
    author,
    mime_type,
    storage_location,
    storage_path,
    filename,
    folder_name,
  }) {
    super();
    this.no = no;
    this.active = active;
    this.designation = designation;
    this.comment = comment;
    this.memo = memo;
    this.creation_date = creation_date;
    this.modification_date = modification_date;
    this.custom_data = custom_data;
    this.status = status;
    this.author = author;
    this.mime_type = mime_type;
    this.storage_location = storage_location;
    this.storage_path = storage_path;
    this.filename = filename;
    this.folder_name = folder_name;
  }

  static getMTORelations() {
    return {
      author: {
        childIdColumn: 'author_id',
        childTableName: 'staff',
      },
    };
  }

  static factory() {
    return new FormTemplate({});
  }
}
