<template>
  <div class="ml-1 text-sm leading-5 inline-grid hit-input-value-help gap-2">
    <label
      :for="id"
      class="font-medium text-default"
    >{{ label }}</label>
    <hit-help
      v-if="help"
      :tooltip-label="label"
      :html="help"
    />
  </div>
</template>

<script>
import HitHelp from '../../help/HitHelp.vue';

export default {
  name: 'HitInputOption',
  components: {HitHelp},
  mixins: [],
  props: {
    /**
     * ID of the input to which this help is linked
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * Label of the help
     */
    label: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Help text
     */
    help: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style scoped>
.hit-input-value-help {
  grid-template-columns: minmax(0, 1fr) max-content;
}
</style>
