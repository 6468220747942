export default {
  props: {
    /**
     * Color of the component
     */
    color: {
      type: String,
      default: null,
      validator: function (value) {
        // The value must match one of these strings
        return (
          ['primary', 'secondary', 'warning', 'danger', 'success'].indexOf(
            value
          ) !== -1
        );
      },
    },
  },
};
