import {BaseRootEntityService} from '../../base';

export default class HitFormService extends BaseRootEntityService {
  constructor() {
    super('form');
  }

  async submit({item}) {
    try {
      return await this.$update({
        path: this.path,
        item: {
          id: item.id,
          status: 1,
          finished: true,
          submissionDate: new Date(),
        },
      });
    } catch (error) {
      console.error('Error in submit():', error);
      throw error;
    }
  }

  async validate({item, staffId}) {
    try {
      return await this.$update({
        path: this.path,
        item: {
          id: item.id,
          status: 2,
          finished: true,
          validatorId: staffId,
          validationDate: new Date(),
        },
      });
    } catch (error) {
      console.error('Error in validate():', error);
      throw error;
    }
  }
}
