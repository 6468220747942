<template>
  <hit-select
    :label-fields="['lastName', 'firstName']"
    :label="label === undefined ? commonUserString : label"
    :value="value"
    :options="users"
    @change="onChange"
  />
</template>

<script>
import {HitAppSelectMixin} from '@hit/components';
import {useI18n} from 'vue-i18n';
import {computed} from 'vue';
import {DataService} from '@hit/base';

export default {
  name: 'HitUserInput',
  components: {},
  mixins: [HitAppSelectMixin],
  props: {
    /**
     * Selected value of the user select
     */
    value: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Label of the user select
     */
    label: {
      type: String,
      default: null,
    },
  },
  setup() {
    const {t} = useI18n();
    const commonUserString = computed(() => t('hit-base.common.user'));
    return {t, commonUserString};
  },
  data() {
    return {
      fetchUsersQueryId: null,
      registeredUsers: null,
    };
  },
  computed: {
    currentCompanyId() {
      return this.$store.getters['context/company/companyId'];
    },
    fetchUsersQueryParams() {
      return {
        query: {
          fields: ['lastName', 'firstName'],
          filters: {companyId: 'eq.' + this.currentCompanyId}, //TODO modify this call because keycloak custom api no longer exists
        },
        listenAdd: true,
      };
    },
    isUsersLoading() {
      return (
        this.fetchUsersQueryId &&
        this.$store.getters['keycloak/user/itemsLoading'](
          this.fetchUsersQueryId
        )
      );
    },
    users() {
      if (this.fetchUsersQueryId && !this.isUsersLoading) {
        let allUsers = this.$store.getters['keycloak/user/items'](
          this.fetchUsersQueryId
        );
        allUsers = allUsers.filter((user) => user.username !== 'public-admin');
        if (this.registeredUsers) {
          allUsers = allUsers.filter(
            (user) =>
              !this.registeredUsers.includes(user.id) || user.id === this.value
          );
        }
        return allUsers;
      } else {
        return [];
      }
    },
  },
  watch: {
    fetchUsersQueryParams: {
      immediate: true,
      handler() {
        this.fetchUsers();
      },
    },
  },
  async mounted() {
    const registeredStaff = await DataService.read('staff', {
      filters: {user_id: 'not.is.null'},
      attributes: 'user_id',
    });
    this.registeredUsers = registeredStaff.data;
    this.registeredUsers = this.registeredUsers.map((obj) => obj.user_id);
  },
  methods: {
    onChange(value) {
      this.fireInputChange(value);
    },
    async fetchUsers() {
      this.fetchUsersQueryId = await this.$store.dispatch(
        'keycloak/user/fetchItems',
        this.fetchUsersQueryParams
      );
    },
  },
};
</script>
