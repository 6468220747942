export default function (app) {
  app.directive('hitSanitizedHtml', function (el, binding) {
    if (el && binding && binding.instance && binding.instance.$sanitize) {
      if (binding.value) {
        el.innerHTML = binding.instance.$sanitize(binding.value);
      } else {
        el.innerHTML = '';
      }
    } else {
      console.error('Error while sanitizing html : el or binding is empty');
    }
  });
}
