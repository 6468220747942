// @ is an alias to /src
export default {
  inject: {
    hitFormFieldChanged: {
      default: undefined, // a default value is required to suppress warning if not found
    },
    hitFormFieldInput: {
      default: undefined, // a default value is required to suppress warning if not found
    },
  },
  props: {
    /**
     * Name of the input
     */
    name: {
      type: String,
      required: false,
    },
    /**
     * Label of the input
     */
    label: {
      type: [String, Object],
      required: false,
    },
    /**
     * Enable to disable the input
     */
    disabled: Boolean,
    /**
     * Enable readonly for the input
     */
    readonly: {
      type: Boolean,
      default: false,
    },
    /**
     * Enable inline mode for the input
     */
    inlineInput: {
      type: Boolean,
      default: false,
    },
    /**
     * Width of the label in inline mode
     */
    inlineLabelWidth: {
      type: String,
      default: 'max-content',
    },
    /**
     * Property name representing the ID of the items
     */
    itemIdProperty: {
      type: String,
      required: false,
    },
    /**
     * Enable display of the label column
     */
    displayLabelColumn: Boolean,
    /**
     * Enable visibility of inline label color
     */
    inlineLabelColorVisible: {
      type: Boolean,
      default: true,
    },
    /**
     * Enable reset of the input's validation if it is valid
     */
    resetValidationIfValid: {
      type: Boolean,
      default: false,
    },
    /**
     * Remove the base height of the input
     */
    useCustomHeight: Boolean,
  },
  methods: {
    getItemId(item, itemId) {
      if (this.itemIdProperty) {
        return item[this.itemIdProperty];
      } else if (item.id) {
        return item.id;
      } else {
        return itemId;
      }
    },
    fireInputInput(value) {
      /**
       * When the input value has changed due to a user input
       */
      this.$emit('input', value);
      if (this.name) {
        if (this.hitFormFieldInput) {
          this.hitFormFieldInput(this.name, value);
        } else {
          throw 'name is set but no HitForm parent found!';
        }
      }
    },
    fireInputChange(value) {
      /**
       * When the input value has changed
       */
      this.$emit('change', value);
      if (this.name) {
        if (this.hitFormFieldChanged) {
          this.hitFormFieldChanged(this.name, value);
        } else {
          throw 'name is set but no HitForm parent found!';
        }
      }

      if (this.resetValidationIfValid && this.isValid) {
        this.validationState.$reset();
      }
    },
  },
};
