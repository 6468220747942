<template>
  <hit-app-input-rich-text
    :value="container.data[attribute]"
    :validation-state="validate ? container.validationState[attribute] : null"
    :disabled="container.attributes[attribute].readOnly || disabled"
    @change="(value) => container.handleChange(attribute, value)"
  />
</template>
<script>
import {HitAppInputRichText} from '../../hit';

export default {
  name: 'HitContainerInputRichText',
  components: {
    HitAppInputRichText,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
    validate: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
