import BaseModel from '../BaseModel';

export default class FollowUp extends BaseModel {
  constructor({author, description, designation, followUpDate, finished}) {
    super();
    this.author = author;
    this.description = description;
    this.designation = designation;
    this.followUpDate = followUpDate;
    this.finished = finished;
  }

  static factory() {
    return new FollowUp({});
  }

  static getMTORelations() {
    return {
      author: {
        childIdColumn: 'author_id',
        childTableName: 'staff',
      },
    };
  }
}
