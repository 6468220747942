export class HitUtils {
  /**
   * Converts the given string in camel case to kebab-case
   */
  static camelToKebab(camel) {
    return camel.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  }

  /**
   * Converts the given string in camel case to snake_case
   */
  static camelToSnake(camel) {
    return camel.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
  }

  /**
   * Converts the given kebab case to snake case (aB-Cd -> ab_cd)
   */
  static kebabToSnake(kebab) {
    return kebab.toLowerCase().replaceAll('-', '_');
  }

  /**
   * Converts the given snake case to kebab case (ab_cd -> ab-cd)
   */
  static snakeToKebab(snake) {
    return snake.toLowerCase().replaceAll('_', '-');
  }

  /**
   * Converts the given snake case to camel case (ab_cd -> abCd)
   */
  static snakeToCamel(snake) {
    let words = snake.split('_');
    return (
      words[0] +
      words
        .slice(1)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('')
    );
  }

  /**
   * Generates a random number with the specified number of digits
   */
  static randomNo(digits) {
    const min = Math.pow(10, digits - 1);
    const max = Math.pow(10, digits) - 1;
    return Math.floor(min + Math.random() * (max - min + 1));
  }

  /**
   * Generates the designation for the standard entities. THe designations is
   * the concatenation of no and designations separated by a -. If a part does
   * not exist, the - is removed.
   */
  static createStandardDesignation(
    record,
    noKey = 'no',
    desKey = 'designation',
    separator = ' - '
  ) {
    return [record[noKey], record[desKey]]
      .filter((p) => p && p !== '')
      .join(separator);
  }
}
