// @ is an alias to /src
import HitInputMixin from './HitInputMixin';

export default {
  mixins: [HitInputMixin],
  /* v-model uses 'change' event instead of 'input' event */
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    /**
     * Value of the select
     */
    value: {
      type: [Number, String, Array],
      required: false,
    },
    /**
     * Place holder of the entity select
     */
    placeholder: {
      type: String,
    },
    /**
     * Enable multiple select
     */
    multiple: {
      type: Boolean,
      default: false,
    },
    /**
     * Fields composing the label
     */
    labelFields: {
      type: Array,
      default() {
        return [];
      },
    },
    /**
     * Enable filter for the entity select
     */
    filterable: {
      type: Boolean,
      default: true,
    },
    /**
     * Enable clearing of the entity select
     */
    clearable: {
      type: Boolean,
      default: true,
    },
    /**
     * Creation option label
     */
    createLabel: {
      type: String,
    },
    /**
     * Enable loading of the select
     */
    loading: Boolean,
  },
};
