<template>
  <div
    class="hit-panel max-h-full overflow-visible"
    :class="{
      'window-minimized default': panelMinimized,
      'window-normal': panelNormal,
      'window-maximized': panelMaximized,
      'large-enough-for-form': largeEnoughForForm,
    }"
  >
    <!-- Add the following line to hit-card if we want come back to a hidden background if panel is minimized -->
    <!--      :hide-background="panelMinimized"-->
    <hit-card
      :ref="(ref) => (cardComponent = ref)"
      :display-shadow="true"
      class="h-full border-b-2 border-panel"
    >
      <div
        class="grid hit-panel-content h-full px-2 overflow-visible"
        :class="{
          'p-2': !panelMinimized,
        }"
        :style="{
          'grid-template-columns': panelColumnWidth
            ? panelColumnWidth
            : undefined,
        }"
      >
        <div
          class="hit-panel-header pb-1"
          :class="{
            'pb-2': !panelMinimized,
            'pt-2': panelMinimized,
          }"
        >
          <span class="hit-panel-header-title text-default">
            <!-- @slot Title of the panel -->
            <slot name="header-title">{{ title }}</slot>
          </span>
          <div class="hit-panel-header-buttons">
            <div class="hit-panel-header-buttons-left" />
            <div class="hit-panel-header-right" />
          </div>
          <div
            v-if="resizable"
            class="hit-panel-header-window-buttons"
          >
            <slot name="additionalButtons" />
            <hit-icon
              v-if="!panelMinimized"
              icon="minimize"
              :tooltip="t('hit-components.common.minimize')"
              :clickable="true"
              class="pl-1"
              size="2xs"
              color="text-panel"
              @click="minimize()"
            />
            <!--            <hit-icon-->
            <!--              v-if="!panelNormal"-->
            <!--              icon="restore-size"-->
            <!--              :tooltip="t('hit-components.common.restore')"-->
            <!--              :clickable="true"-->
            <!--              size="2xs"-->
            <!--              color="text-panel"-->
            <!--              class="pl-1"-->
            <!--              @click="restore()"-->
            <!--            />-->
            <hit-icon
              v-if="!panelMaximized"
              icon="maximize-size"
              :tooltip="t('hit-components.common.maximize')"
              :clickable="true"
              size="2xs"
              color="text-panel"
              class="pl-1"
              @click="maximize()"
            />
            <hit-icon
              v-if="closable"
              icon="clear"
              :clickable="true"
              size="2xs"
              color="text-panel"
              class="pl-1"
              @click="close"
            />
          </div>
        </div>
        <div
          v-if="panelWithValidation || !panelMinimized"
          v-show="!panelMinimized"
          class="hit-panel-body"
          :class="{
            'overflow-visible': !containsGraphic,
            'overflow-auto': containsGraphic,
          }"
        >
          <!-- @slot Body of the panel -->
          <slot
            v-if="supportedOnThisDevice"
            name="body"
            :large-enough-for-grid="largeEnoughForGrid"
          />
          <div v-else>
            <p class="text-warning-icon mt-1.5">
              {{ t('hit-app.common.panel-not-supported-screen-resolution') }}
            </p>
          </div>
        </div>
        <div
          v-if="!panelMinimized"
          class="hit-panel-footer"
        >
          <div class="hit-panel-left-footer grid grid-flow-col">
            <!-- @slot Footer of the panel -->
            <slot name="footer" />
          </div>
          <div class="hit-panel-right-footer" />
        </div>
      </div>
    </hit-card>
  </div>
</template>

<script>
import {HitBreakpointsMixin, HitLoadingReceiverMixin} from '../../mixins';
import {useI18n} from 'vue-i18n';
import HitCard from '../card/HitCard.vue';
import HitIcon from '../icon/HitIcon.vue';
import {provide} from 'vue';

export default {
  name: 'HitPanel',
  components: {HitCard, HitIcon},
  mixins: [HitLoadingReceiverMixin, HitBreakpointsMixin],
  props: {
    /**
     * Text ID of the panel used to check the specific config in the configuration store
     */
    panelId: {
      type: String,
      default: null,
    },
    /**
     * Title of the panel
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * Allow to resize the panel
     */
    resizable: {
      type: Boolean,
      default: true,
    },
    /**
     * Display shadow on the panel
     */
    displayShadow: {
      type: Boolean,
      default: true,
    },
    /**
     * Loading mode of the title
     */
    titleLoadingMode: {
      type: String,
      default: 'dots',
      validator(value) {
        return ['spinner', 'dots'].indexOf(value) !== -1;
      },
    },
    configStore: {
      type: Object,
      required: false,
    },
    notSupportedOnMobileDevices: {
      type: Boolean,
      required: false,
      default: false,
    },
    showChangeOrderButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    panelWithValidation: {
      type: Boolean,
      required: false,
      default: false,
    },
    containsGraphic: {
      type: Boolean,
      required: false,
      default: false,
    },
    panelColumnWidth: {
      type: String,
      required: false,
    },
    /**
     * Boolean to display a clear icon in the top right corner of the panel
     */
    closable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data: function () {
    return {
      windowSize: this.size,
      cardComponent: null,
      cardWidth: 0,
      resizeEventListenerTimeout: null,
    };
  },
  computed: {
    panelMinimized() {
      return this.windowSize === 'minimized';
    },
    panelNormal() {
      return this.windowSize === 'normal';
    },
    panelMaximized() {
      return this.windowSize === 'maximized';
    },
    titleLoadingParams() {
      return {
        isLoading: this.hasRegisteredLoadingEmitter,
        showMask: false,
        mode: this.titleLoadingMode,
      };
    },
    bodyLoadingParams() {
      return {
        isLoading: this.hasRegisteredLoadingEmitter,
        mode: 'invisible',
      };
    },
    largeEnoughForForm() {
      return this.cardWidth > 768;
    },
    largeEnoughForGrid() {
      return this.cardWidth > 768;
    },
    supportedOnThisDevice() {
      return (
        !this.notSupportedOnMobileDevices ||
        (this.notSupportedOnMobileDevices && this.responsiveBreakpointLg)
      );
    },
  },
  beforeMount() {
    provide('panelId', this.panelId);
    provide('largeEnoughForGrid', this.largeEnoughForGrid);
    if (this.panelId && this.configStore) {
      const collapsed = this.configStore.getConfiguration(
        `${this.panelId}.collapsed`
      );
      this.windowSize = collapsed ? 'minimized' : 'maximized';
    } else {
      this.windowSize = 'maximized';
    }
  },
  mounted() {
    new ResizeObserver(this.cardResizeHandler).observe(
      this.cardComponent.rootDomEl
    );
  },
  methods: {
    // For the moment, we only allow min or max panels
    restore: function () {
      this.windowSize = 'normal';
    },
    maximize: function () {
      this.windowSize = 'maximized';
      if (this.panelId && this.configStore) {
        this.configStore.updateHiddenConfiguration(
          `${this.panelId}.collapsed`,
          false
        );
      }
    },
    minimize: function () {
      this.windowSize = 'minimized';
      if (this.panelId && this.configStore) {
        this.configStore.updateHiddenConfiguration(
          `${this.panelId}.collapsed`,
          true
        );
      }
    },
    close() {
      this.$emit('close');
    },
    cardResizeHandler() {
      clearTimeout(this.resizeEventListenerTimeout);
      // gets only executed after event hasn't been fired for 50 ms
      this.resizeEventListenerTimeout = setTimeout(() => {
        if (this.cardComponent && this.cardComponent.rootDomEl) {
          this.cardWidth = this.cardComponent.rootDomEl.getBoundingClientRect().width;
        }
      }, 50);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.hit-panel {
  @apply border-none;

  &.window-normal {
    .hit-panel-body {
      max-height: 250px;
    }
  }

  .hit-panel-content {
    grid-template-rows: max-content 1fr minmax(0, max-content);
  }

  .hit-panel-header {
    grid-template-areas: 'hit-panel-header-title hit-panel-header-window-buttons';
    @apply text-default grid items-center;
    &with-search {
      grid-template-columns: minmax(auto, 35%) 1fr auto;
      grid-template-areas: 'hit-panel-header-title hit-panel-header-buttons hit-panel-header-window-buttons';

      .hit-panel-header-buttons {
        grid-area: hit-panel-header-buttons;
        grid-template-columns: 1fr auto;
        grid-template-areas: 'hit-panel-header-buttons-left hit-panel-header-right';
        @apply grid;
        .hit-panel-header-buttons-left {
          grid-area: hit-panel-header-buttons-left;
        }
      }
    }
    .hit-panel-header-title {
      @apply font-medium uppercase tracking-wide flex gap-1 items-center;
      grid-area: hit-panel-header-title;
    }
    .hit-panel-header-window-buttons {
      @apply justify-self-end flex;
      grid-area: hit-panel-header-window-buttons;
    }
  }

  .hit-panel-body {
    @apply text-default pb-2 h-full;
  }

  .hit-panel-footer {
    grid-template-columns: 1fr auto;
    grid-template-areas: 'hit-panel-left-footer hit-panel-right-footer';
    @apply items-center grid;
    .hit-panel-left-footer {
      grid-auto-columns: minmax(40px, max-content);
      grid-column-gap: 3px;
    }
    .hit-panel-right-footer {
      grid-area: hit-panel-right-footer;
    }
  }
}
</style>
