import BaseService from './BaseService';

export default class BaseChildEntityService extends BaseService {
  constructor(referenceTable, parentIdColumn, modelCls) {
    super(modelCls);
    this.referenceTable = referenceTable;
    this.parentIdColumn = parentIdColumn;
  }

  async create({item, parentId}) {
    return this.$create({
      path: this.referenceTable,
      item: item,
    });
  }

  async delete({item}) {
    if (item.id) {
      return this.$delete({path: this.referenceTable, item: {id: item.id}});
    }
    throw new Error('not implemented yet'); // implement once needed
  }

  async fetchItems({
    parentId,
    fields,
    q,
    qFields,
    sort,
    filters,
    limit,
    offset,
    getCount,
  }) {
    if (!filters) {
      filters = {};
    }
    filters[this.parentIdColumn] = 'eq.' + parentId;
    return this.$fetchItems({
      path: this.referenceTable,
      fields,
      q,
      qFields,
      sort,
      filters,
      limit,
      offset,
      getCount,
    });
  }

  async fetchItem({id, fields, q, qFields, sort}) {
    return this.$fetchItem({
      path: this.referenceTable,
      id,
      fields,
      q,
      qFields,
      sort,
    });
  }

  async update({item, path = ''}) {
    return this.$update({path: path ? path : this.referenceTable, item});
  }

  async upsert({item, path = ''}) {
    return this.$upsert({path: path ? path : this.referenceTable, item});
  }
}
