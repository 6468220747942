export default class BaseModel {
  static factory() {
    throw Error(
      `Factory method should be implemented in each subclass of BaseHitModel: ${this.name}`
    );
  }
  static fromJson(json) {
    let result = this.factory();
    json = this.$transformJson(json);
    result = Object.assign(result, json);
    return result;
  }

  static $transformJson(json) {
    Object.keys(json).forEach((property) => {
      // Change dates to Date type instead of String
      if (property.toUpperCase().endsWith('DATE')) {
        json[property] = new Date(json[property]);
      }
    });

    return json;
  }

  /**
   *
   * @returns returns a dictionary of Many To Many relationships of the model
   */
  static getMTMRelations() {
    return {};
  }

  /**
   *
   * @returns returns a dictionary of Many To One relationships of the model
   */
  static getMTORelations() {
    return {};
  }

  static getIdColumn() {
    return 'id';
  }
}
