import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-b4b6e710"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "hit-module-list-root bg-table rounded border border-table",
    style: { "max-height": "calc(100vh - 13.2rem)" }
};
const _hoisted_2 = { class: "sticky top-0 bg-table flex flex-col sm:flex-row justify-between border-b border-table py-2 z-20" };
const _hoisted_3 = { class: "hit-module-list-menu ml-2 sm:ml-5 mt-2.5 flex flex-col justify-start items-start gap-2" };
const _hoisted_4 = { class: "text-left text-sm" };
const _hoisted_5 = { class: "text-left text-sm" };
const _hoisted_6 = { class: "flex-grow flex flex-col items-end justify-end m-2 sm:m-3" };
const _hoisted_7 = { class: "hit-form" };
const _hoisted_8 = { class: "sticky top-0 z-10 bg-table module-list-header" };
const _hoisted_9 = { class: "whitespace-nowrap sm:rotate-0 rotate-90 sm:writing-mode-horizontal-tb writing-mode-vertical-rl sm:h-auto h-[80px] sm:text-sm text-xs sm:leading-normal leading-[2rem] max-w-full flex items-center justify-center" };
const _hoisted_10 = { key: 0 };
const _hoisted_11 = { key: 1 };
const _hoisted_12 = {
    key: 0,
    class: "ml-2 sm:ml-5 text-left text-sm break-words overflow-hidden text-ellipsis"
};
const _hoisted_13 = { class: "ml-2 sm:ml-5" };
const _hoisted_14 = {
    key: 1,
    class: "ml-2 sm:ml-5 text-left text-sm break-words overflow-hidden text-ellipsis font-bold"
};
const _hoisted_15 = { key: 2 };
const _hoisted_16 = {
    key: 3,
    class: "text-right"
};
const _hoisted_17 = {
    key: 0,
    class: "text-sm font-bold"
};
const _hoisted_18 = {
    key: 1,
    class: "text-sm"
};
const _hoisted_19 = { key: 4 };
const _hoisted_20 = ["onUpdate:modelValue", "onChange"];
const _hoisted_21 = ["innerHTML"];
const _hoisted_22 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_hit_button = _resolveComponent("hit-button");
    const _component_hit_module_list_cell = _resolveComponent("hit-module-list-cell");
    const _component_hit_module_list_row = _resolveComponent("hit-module-list-row");
    const _component_hit_icon = _resolveComponent("hit-icon");
    const _component_hit_dialog = _resolveComponent("hit-dialog");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_hit_button, {
                    label: _ctx.t('hit-components.modules.how_to_choose_modules_title'),
                    color: "accent",
                    size: "md sm:lg",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.onShowHowToChooseModulesDialog()))
                }, null, 8, ["label"]),
                _createElementVNode("div", null, [
                    _createElementVNode("span", _hoisted_4, [
                        _createTextVNode(_toDisplayString(_ctx.t('hit-components.modules.monthly_amount')) + " ", 1),
                        _createElementVNode("b", null, _toDisplayString(_ctx.amountValues.monthlyAmount.toFixed(2)) + " €", 1)
                    ])
                ]),
                _createElementVNode("div", null, [
                    _createElementVNode("span", _hoisted_5, [
                        _createTextVNode(_toDisplayString(_ctx.t('hit-components.modules.monthly_amount_excluding_free_allowance')) + " ", 1),
                        _createElementVNode("b", null, _toDisplayString(_ctx.amountValues.monthlyAmountIfFreeThresholdExceeded.toFixed(2)) + " €", 1)
                    ])
                ])
            ]),
            _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_hit_button, {
                        label: _ctx.t('hit-components.common.cancel'),
                        "prefix-icon": "clear",
                        color: "danger",
                        size: "md sm:lg",
                        disabled: _ctx.adminUiMode || _ctx.disableUserModulesModificationButton,
                        hidden: _ctx.adminUiMode,
                        onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.onCancelModulesChange()))
                    }, null, 8, ["label", "disabled", "hidden"]),
                    _createVNode(_component_hit_button, {
                        label: _ctx.t('hit-components.common.validate'),
                        "prefix-icon": "check",
                        color: "accent",
                        size: "md sm:lg",
                        disabled: _ctx.adminUiMode || _ctx.disableUserModulesModificationButton,
                        hidden: _ctx.adminUiMode,
                        onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.onShowUserModuleModificationDialog()))
                    }, null, 8, ["label", "disabled", "hidden"])
                ])
            ])
        ]),
        _createElementVNode("div", {
            class: "hit-module-list relative overflow-y-auto",
            style: _normalizeStyle(_ctx.cssProps)
        }, [
            _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_hit_module_list_row, { class: "border-b border-table" }, {
                    default: _withCtx(() => [
                        _createVNode(_component_hit_module_list_cell),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldsToDisplay, (field, idx) => {
                            return (_openBlock(), _createBlock(_component_hit_module_list_cell, {
                                key: idx,
                                "justify-content": _ctx.getHeaderJustifyContentProperty(idx),
                                "display-border": idx < _ctx.fieldsToDisplay.length - 1,
                                class: "text-xs overflow-hidden flex-1"
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_9, _toDisplayString(field.translation), 1)
                                ]),
                                _: 2
                            }, 1032, ["justify-content", "display-border"]));
                        }), 128))
                    ]),
                    _: 1
                })
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modulesList, (module, rowIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                    key: rowIndex,
                    class: _normalizeClass(_ctx.getLineClass(rowIndex === 0, rowIndex === _ctx.modulesList.length - 1, module.designation_key))
                }, [
                    _createVNode(_component_hit_module_list_row, null, {
                        default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredModule(module), (value, key) => {
                                return (_openBlock(), _createBlock(_component_hit_module_list_cell, {
                                    key: key,
                                    "justify-content": _ctx.getRowsJustifyContentProperty(_ctx.idx, key),
                                    "display-border": true,
                                    style: _normalizeStyle({
                                        borderTop: module.parent_id === null
                                            ? '2px solid border-b border-table'
                                            : 'none',
                                    }),
                                    class: "text-xs sm:text-base break-words"
                                }, {
                                    default: _withCtx(() => [
                                        (key === 'icon_key')
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                                (module.icon_key)
                                                    ? (_openBlock(), _createElementBlock("div", {
                                                        key: 0,
                                                        class: _normalizeClass([
                                                            'flex items-center justify-center vertical-align h-10 w-10',
                                                            `bg-${module.designation_key}`,
                                                        ])
                                                    }, [
                                                        _createVNode(_component_hit_icon, {
                                                            icon: module.icon_key,
                                                            color: "white",
                                                            size: "md"
                                                        }, null, 8, ["icon"])
                                                    ], 2))
                                                    : _createCommentVNode("", true)
                                            ]))
                                            : (key === 'designation_key')
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                                    (module.parent_id)
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                                            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t(`hit-components.modules.basic.${module.designation_key}.designation`)), 1)
                                                        ]))
                                                        : (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.t(`hit-components.modules.basic.${module.designation_key}.designation`)), 1))
                                                ]))
                                                : (key === 'description_key')
                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                                        (module.parent_id)
                                                            ? (_openBlock(), _createBlock(_component_hit_icon, {
                                                                key: 0,
                                                                icon: "description",
                                                                size: "md",
                                                                clickable: "",
                                                                alignment: "left",
                                                                onClick: ($event) => (_ctx.showFullDescriptionDialogScreen(module))
                                                            }, null, 8, ["onClick"]))
                                                            : _createCommentVNode("", true)
                                                    ]))
                                                    : (key === 'price_per_month')
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                                            (!module.parent_id && module.editable)
                                                                ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.computeTotalModulePricePerMonth(module).toFixed(2)), 1))
                                                                : (module.parent_id)
                                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(module.price_per_month.toFixed(2)), 1))
                                                                    : _createCommentVNode("", true)
                                                        ]))
                                                        : (key === 'selected')
                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                                                (module.editable)
                                                                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                                                        key: 0,
                                                                        "onUpdate:modelValue": ($event) => ((module.selected) = $event),
                                                                        size: "md",
                                                                        type: "checkbox",
                                                                        class: "form-checkbox h-5 w-5 transition duration-150 ease-in-out rounded-lg",
                                                                        onChange: ($event) => (_ctx.toggleModuleSelection(module))
                                                                    }, null, 40, _hoisted_20)), [
                                                                        [_vModelCheckbox, module.selected]
                                                                    ])
                                                                    : _createCommentVNode("", true)
                                                            ]))
                                                            : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                }, 1032, ["justify-content", "style"]));
                            }), 128))
                        ]),
                        _: 2
                    }, 1024)
                ], 2));
            }), 128))
        ], 4),
        _createVNode(_component_hit_dialog, {
            visible: _ctx.showModuleFullDescriptionDialogScreen,
            title: _ctx.showModuleFullDescriptionTitle,
            color: "accent",
            "cancel-button-label": _ctx.t('hit-components.common.close'),
            "show-cancel-button": true,
            "show-ok-button": false,
            class: "z-30",
            onCancel: _ctx.onCancelShowFullDescriptionDialogScreen
        }, {
            body: _withCtx(() => [
                _createElementVNode("div", {
                    class: "whitespace-pre-wrap m-2 max-h overflow-y-auto overflow-x-hidden text-justify",
                    innerHTML: _ctx.showModuleFullDescriptionText
                }, null, 8, _hoisted_21)
            ]),
            _: 1
        }, 8, ["visible", "title", "cancel-button-label", "onCancel"]),
        _createVNode(_component_hit_dialog, {
            visible: _ctx.showConfirmUserModuleModification,
            "v-show": _ctx.showConfirmUserModuleModification,
            title: _ctx.t('hit-components.modules.user-modules-modification'),
            color: "accent",
            message: _ctx.t('hit-components.modules.please-confirm-user-modules-modification'),
            "ok-button-label": _ctx.t('hit-components.common.confirm'),
            "ok-button-icon": "success",
            "ok-button-color": "accent",
            "cancel-button-icon": "clear",
            "cancel-button-label": _ctx.t('hit-components.common.cancel'),
            "show-cancel-button": true,
            class: "z-30",
            onOk: _ctx.onConfirmUserModuleModification,
            onCancel: _ctx.onCancelUserModuleModification
        }, null, 8, ["visible", "v-show", "title", "message", "ok-button-label", "cancel-button-label", "onOk", "onCancel"]),
        _createVNode(_component_hit_dialog, {
            visible: _ctx.showHowToChooseModulesDialog,
            "v-show": _ctx.showHowToChooseModulesDialog,
            title: _ctx.t('hit-components.modules.how_to_choose_modules_title'),
            color: "accent",
            class: "z-30",
            message: _ctx.t('hit-components.modules.please-confirm-user-modules-modification'),
            "cancel-button-icon": "clear",
            "cancel-button-label": _ctx.t('hit-components.common.cancel'),
            "show-cancel-button": true,
            "show-ok-button": false,
            onCancel: _ctx.onCancelHowToChooseModules
        }, {
            body: _withCtx(() => [
                _createElementVNode("div", {
                    innerHTML: _ctx.t('hit-components.modules.how_to_choose_modules_content')
                }, null, 8, _hoisted_22)
            ]),
            _: 1
        }, 8, ["visible", "v-show", "title", "message", "cancel-button-label", "onCancel"])
    ]));
}
