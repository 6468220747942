<template>
  <hit-form-definition-builder-base-component
    :validations="validations"
    :value="value"
  >
    <template #default="{formData, changeField}">
      <hit-base-input
        :label="t('hit-base.common.options')"
        use-custom-height
      >
        <hit-options-editor
          :options="formData.radioValues"
          @change="(optionValues) => changeField('radioValues', optionValues)"
        />
      </hit-base-input>
    </template>
  </hit-form-definition-builder-base-component>
</template>

<script>
import HitFormDefinitionBuilderBaseComponent from './HitFormDefinitionBuilderBaseComponent';
import {useI18n} from 'vue-i18n';
import HitOptionsEditor from './HitOptionsEditor.vue';
import {HitBaseInput} from '@hit/components';

export default {
  name: 'HitFormDefinitionBuilderRadioComponent',
  components: {
    HitFormDefinitionBuilderBaseComponent,
    HitOptionsEditor,
    HitBaseInput,
  },
  props: {
    /**
     * Entity component of the builder to edit
     */
    value: {
      type: Object,
      required: true,
    },
    /**
     * Validations for the entity component
     */
    validations: {
      type: Object,
      required: true,
    },
  },

  /**
   * The save and cancel event is emitted inside the HitFormDefinitionBuilderBaseComponent
   * We do not catch it here and do not define the events in this component. Like this,
   * the event listeners added to this component propagates to the base builder
   */
  emits: [],
  setup() {
    const {t} = useI18n();
    return {t};
  },
};
</script>
