import {schema} from 'normalizr';

const communicationModeSchema = new schema.Entity(
  'common/communicationMode',
  {}
);
const tagSchema = new schema.Entity('common/tag', {});
const countrySchema = new schema.Entity('common/country', {});

export {communicationModeSchema, tagSchema, countrySchema};
