const HitFormDefinitionBuilderComponentModel = class {
  unManagedProperties = {};

  constructor(
    id,
    componentType,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    this.id = id;
    this.componentType = componentType;

    this.languages = languages;
    this.getLocalizedFieldFn = getLocalizedFieldFn;

    this.initLocalizedFields({
      label: labelJSON,
      help: helpJSON,
    });
  }

  getLocalizedFields(result, fields) {
    fields.forEach((field) => {
      const languagesDict = {};
      this.languages.forEach((language) => {
        const fieldValue = this[this.getLocalizedFieldFn(field, language)];

        if (fieldValue) {
          languagesDict[language.hitI18nCode] = fieldValue;
        }
      });

      const languagesDictValues = Object.values(languagesDict);
      if (
        languagesDictValues.length === this.languages.length &&
        languagesDictValues.every((elt) => elt === languagesDictValues[0])
      ) {
        result[field] = languagesDictValues[0];
      } else {
        result[field] = {};
        for (let key of Object.keys(languagesDict)) {
          result[field][key] = languagesDict[key];
        }
      }
    });
  }

  initLocalizedFields(fieldsData = {}) {
    Object.entries(fieldsData).forEach((fieldData) => {
      const field = fieldData[0];
      const data = fieldData[1];
      if (data) {
        this.languages.forEach((language) => {
          const localizedField = this.getLocalizedFieldFn(field, language);

          if (typeof data !== 'string') {
            if (language.hitI18nCode in data) {
              this[localizedField] = data[language.hitI18nCode];
            } else {
              this[localizedField] = null;
            }
          } else {
            this[localizedField] = data;
          }
        });
      }
    });
  }

  getComponentNameKey() {
    return `hit-base.form.${this.componentType}-name`;
  }
};

const HitFormDefinitionBuilderSectionModel = class extends HitFormDefinitionBuilderComponentModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(id, 'section', languages, getLocalizedFieldFn, labelJSON, helpJSON);

    this.content = [];
  }

  getFormDefinition() {
    let result = {
      meta: {
        label: {},
        help: {},
      },
      content: {},
    };

    result = Object.assign({}, result, this.unManagedProperties);
    result.meta = Object.assign({}, result.meta, this.unManagedProperties.meta);

    this.getLocalizedFields(result.meta, ['label', 'help']);

    for (let i = 0; i < this.content.length; i++) {
      let component = this.content[i];
      result.content[component.id] = component.getFormDefinition();
    }

    return result;
  }

  static initFromJSON(id, json, languages, getLocalizedFieldFn) {
    const labelJSON = json.meta.label;
    const helpJSON = json.meta.help;

    let result = new HitFormDefinitionBuilderSectionModel(
      id,
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );
    const contentKeys = Object.keys(json.content);

    for (let i = 0; i < contentKeys.length; i++) {
      let inputJSON = json.content[contentKeys[i]];
      result.content.push(
        HitFormDefinitionBuilderInputModel.initFromJSON(
          contentKeys[i],
          inputJSON,
          languages,
          getLocalizedFieldFn
        )
      );
    }

    result.unManagedProperties = {};

    let metaUnManagedProperties = Object.keys(json.meta).filter(
      (key) => !['label', 'help'].indexOf(key)
    );
    if (metaUnManagedProperties.length > 0) {
      result.unManagedProperties.meta = {};

      for (let i = 0; i < metaUnManagedProperties.length; i++) {
        let key = metaUnManagedProperties[i];
        result.unManagedProperties.meta[key] = json.meta[key];
      }
    }

    let unManagedProperties = Object.keys(json).filter(
      (key) => !['meta', 'content'].includes(key)
    );
    for (let i = 0; i < unManagedProperties.length; i++) {
      let key = unManagedProperties[i];
      result.unManagedProperties[key] = json[key];
    }

    return result;
  }
};

const HitFormDefinitionBuilderInputModel = class extends HitFormDefinitionBuilderComponentModel {
  constructor(
    id,
    componentType,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      componentType,
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );
  }

  getFormDefinition() {
    let result = {
      label: {},
      help: {},
      constraints: {},
    };

    this.getLocalizedFields(result, ['label', 'help']);

    if (
      !result['help'] ||
      (typeof result['help'] !== 'string' &&
        Object.keys(result['help']).length === 0)
    ) {
      delete result['help'];
    }

    result.constraints = Object.keys(this.constraints).reduce((acc, key) => {
      if (this.constraints[key] !== null) {
        acc[key] = this.constraints[key];
      }

      return acc;
    }, {});

    if (
      !result['constraints'] ||
      (typeof result['constraints'] !== 'string' &&
        Object.keys(result['constraints']).length === 0)
    ) {
      delete result['constraints'];
    }

    return result;
  }

  static initFromJSON(id, json, languages, getLocalizedFieldFn) {
    if (json.type === 'list') {
      return HitFormDefinitionBuilderListInputModel.initFromJSON(
        id,
        json,
        languages,
        getLocalizedFieldFn
      );
    }
    const labelJSON = json.label;
    const helpJSON = json.help;

    let result = null;

    switch (json.type) {
      case 'text':
        result = new HitFormDefinitionBuilderTextInputModel(
          id,
          languages,
          getLocalizedFieldFn,
          labelJSON,
          helpJSON
        );
        break;
      case 'textarea':
        result = new HitFormDefinitionBuilderTextAreaInputModel(
          id,
          languages,
          getLocalizedFieldFn,
          labelJSON,
          helpJSON
        );
        break;
      case 'richtext':
        result = new HitFormDefinitionBuilderRichTextInputModel(
          id,
          languages,
          getLocalizedFieldFn,
          labelJSON,
          helpJSON
        );
        break;
      case 'entity':
        result = new HitFormDefinitionBuilderEntityInputModel(
          id,
          languages,
          getLocalizedFieldFn,
          json.reference,
          json.attributeFilter?.tag ?? [],
          labelJSON,
          helpJSON
        );
        break;
      case 'date':
        result = new HitFormDefinitionBuilderDateInputModel(
          id,
          languages,
          getLocalizedFieldFn,
          labelJSON,
          helpJSON
        );
        break;
      case 'int':
        result = new HitFormDefinitionBuilderIntInputModel(
          id,
          languages,
          getLocalizedFieldFn,
          labelJSON,
          helpJSON
        );
        break;
      case 'number':
        result = new HitFormDefinitionBuilderNumberInputModel(
          id,
          languages,
          getLocalizedFieldFn,
          labelJSON,
          helpJSON
        );
        break;
      case 'location':
        result = new HitFormDefinitionBuilderLocationInputModel(
          id,
          languages,
          getLocalizedFieldFn,
          labelJSON,
          helpJSON
        );
        break;
      case 'file':
        result = new HitFormDefinitionBuilderFileInputModel(
          id,
          languages,
          getLocalizedFieldFn,
          labelJSON,
          helpJSON
        );
        break;
      case 'checkbox':
        result = new HitFormDefinitionBuilderCheckboxInputModel(
          id,
          languages,
          getLocalizedFieldFn,
          labelJSON,
          helpJSON
        );
        break;
      case 'signature':
        result = new HitFormDefinitionBuilderSignatureInputModel(
          id,
          languages,
          getLocalizedFieldFn,
          labelJSON,
          helpJSON
        );
        break;
      case 'radio':
        result = new HitFormDefinitionBuilderRadioInputModel(
          id,
          languages,
          getLocalizedFieldFn,
          json.values,
          labelJSON,
          helpJSON
        );
        break;
      case 'hours':
        result = new HitFormDefinitionBuilderHoursInputModel(
          id,
          languages,
          getLocalizedFieldFn,
          json.steps,
          labelJSON,
          helpJSON
        );
        break;
      case 'time':
        result = new HitFormDefinitionBuilderTimeInputModel(
          id,
          languages,
          getLocalizedFieldFn,
          labelJSON,
          helpJSON
        );
        break;
      default:
        throw Error();
    }

    if (json.constraints) {
      const constraintKeys = Object.keys(json.constraints);

      for (let i = 0; i < constraintKeys.length; i++) {
        result.constraints[constraintKeys[i]] =
          json.constraints[constraintKeys[i]];
      }
    }

    return result;
  }
};

const HitFormDefinitionBuilderTextInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'text-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );

    this.constraints = {
      required: null,
      minLength: null,
      maxLength: null,
    };
  }

  getFormDefinition() {
    let result = super.getFormDefinition();
    result.type = 'text';
    return result;
  }
};

const HitFormDefinitionBuilderTextAreaInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'textarea-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );

    this.constraints = {
      required: null,
      minLength: null,
      maxLength: null,
    };
  }

  getFormDefinition() {
    let result = super.getFormDefinition();
    result.type = 'textarea';
    return result;
  }
};

const HitFormDefinitionBuilderRichTextInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'richtext-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );

    this.constraints = {
      required: null,
      minLength: null,
      maxLength: null,
    };
  }

  getFormDefinition() {
    let result = super.getFormDefinition();
    result.type = 'richtext';
    return result;
  }
};

const HitFormDefinitionBuilderCheckboxInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'checkbox-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );

    this.values = null;

    this.constraints = {
      required: null,
      multiple: null,
    };
  }

  getFormDefinition() {
    let result = super.getFormDefinition();
    result.type = 'checkbox';
    return result;
  }
};

const HitFormDefinitionBuilderRadioButtonInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'radio-button-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );

    this.values = {};

    this.constraints = {
      required: null,
    };
  }
};

const HitFormDefinitionBuilderSelectInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'select-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );

    this.values = {};

    this.constraints = {
      required: null,
      multiple: null,
    };
  }
};

const HitFormDefinitionBuilderEntityInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    reference = 'staff',
    tagList = [],
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'entity-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );

    this.reference = reference;
    this.tagList = tagList;
    this.constraints = {
      required: null,
    };
  }

  getFormDefinition() {
    let result = super.getFormDefinition();
    result.type = 'entity';
    result.reference = this.reference;
    if (this.tagList && this.tagList.length > 0) {
      result.attributeFilter = {
        tag: this.tagList,
      };
    }
    return result;
  }
};

const HitFormDefinitionBuilderDateInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'date-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );

    this.constraints = {
      required: null,
    };
  }

  getFormDefinition() {
    let result = super.getFormDefinition();
    result.type = 'date';
    return result;
  }
};

const HitFormDefinitionBuilderIntInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(id, 'int-input', languages, getLocalizedFieldFn, labelJSON, helpJSON);

    this.constraints = {
      required: null,
      minValue: null,
      maxValue: null,
    };
  }

  getFormDefinition() {
    let result = super.getFormDefinition();
    result.type = 'int';
    return result;
  }
};

const HitFormDefinitionBuilderNumberInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'number-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );

    this.constraints = {
      required: null,
      minValue: null,
      maxValue: null,
    };
  }

  getFormDefinition() {
    let result = super.getFormDefinition();
    result.type = 'number';
    return result;
  }
};

const HitFormDefinitionBuilderLocationInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'location-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );

    this.constraints = {
      required: null,
    };
  }

  getFormDefinition() {
    let result = super.getFormDefinition();
    result.type = 'location';
    return result;
  }
};

const HitFormDefinitionBuilderFileInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'file-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );

    this.constraints = {
      required: null,
    };
  }

  getFormDefinition() {
    let result = super.getFormDefinition();
    result.type = 'file';
    return result;
  }
};

const HitFormDefinitionBuilderSignatureInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'signature-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );

    this.constraints = {
      required: null,
    };
  }

  getFormDefinition() {
    let result = super.getFormDefinition();
    result.type = 'signature';
    return result;
  }
};

const HitFormDefinitionBuilderRadioInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    values = {},
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'radio-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );
    this.radioValues = values;

    this.constraints = {
      required: null,
    };
  }

  getFormDefinition() {
    let result = super.getFormDefinition();
    result.type = 'radio';
    result.values = this.radioValues;
    return result;
  }
};

const HitFormDefinitionBuilderHoursInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    steps = 0,
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'hours-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );
    this.steps = steps;

    this.constraints = {};
  }

  getFormDefinition() {
    let result = super.getFormDefinition();
    result.type = 'hours';
    result.steps = this.steps;
    return result;
  }
};

const HitFormDefinitionBuilderTimeInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'time-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );

    this.constraints = {};
  }

  getFormDefinition() {
    let result = super.getFormDefinition();
    result.type = 'time';
    return result;
  }
};

const HitFormDefinitionBuilderListInputModel = class extends HitFormDefinitionBuilderInputModel {
  constructor(
    id,
    languages,
    getLocalizedFieldFn,
    labelJSON = null,
    helpJSON = null
  ) {
    super(
      id,
      'list-input',
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );

    this.content = [];
  }

  getFormDefinition() {
    let result = {
      type: 'list',
      meta: {
        label: {},
        help: {},
      },
      content: {},
    };

    result = Object.assign({}, result, this.unManagedProperties);
    result.meta = Object.assign({}, result.meta, this.unManagedProperties.meta);

    this.getLocalizedFields(result.meta, ['label', 'help']);

    for (let i = 0; i < this.content.length; i++) {
      let component = this.content[i];
      result.content[component.id] = component.getFormDefinition();
    }

    return result;
  }

  static initFromJSON(id, json, languages, getLocalizedFieldFn) {
    const labelJSON = json.meta.label;
    const helpJSON = json.meta.help;

    let result = new HitFormDefinitionBuilderListInputModel(
      id,
      languages,
      getLocalizedFieldFn,
      labelJSON,
      helpJSON
    );
    const contentKeys = Object.keys(json.content);

    for (let i = 0; i < contentKeys.length; i++) {
      let inputJSON = json.content[contentKeys[i]];
      result.content.push(
        HitFormDefinitionBuilderInputModel.initFromJSON(
          contentKeys[i],
          inputJSON,
          languages,
          getLocalizedFieldFn
        )
      );
    }

    result.unManagedProperties = {};

    let metaUnManagedProperties = Object.keys(json.meta).filter(
      (key) => !['label', 'help'].indexOf(key)
    );
    if (metaUnManagedProperties.length > 0) {
      result.unManagedProperties.meta = {};

      for (let i = 0; i < metaUnManagedProperties.length; i++) {
        let key = metaUnManagedProperties[i];
        result.unManagedProperties.meta[key] = json.meta[key];
      }
    }

    let unManagedProperties = Object.keys(json).filter(
      (key) => !['meta', 'content'].includes(key)
    );
    for (let i = 0; i < unManagedProperties.length; i++) {
      let key = unManagedProperties[i];
      result.unManagedProperties[key] = json[key];
    }
    return result;
  }
};

export {
  HitFormDefinitionBuilderComponentModel,
  HitFormDefinitionBuilderSectionModel,
  HitFormDefinitionBuilderTextInputModel,
  HitFormDefinitionBuilderTextAreaInputModel,
  HitFormDefinitionBuilderRichTextInputModel,
  HitFormDefinitionBuilderCheckboxInputModel,
  HitFormDefinitionBuilderRadioButtonInputModel,
  HitFormDefinitionBuilderSelectInputModel,
  HitFormDefinitionBuilderEntityInputModel,
  HitFormDefinitionBuilderDateInputModel,
  HitFormDefinitionBuilderIntInputModel,
  HitFormDefinitionBuilderNumberInputModel,
  HitFormDefinitionBuilderLocationInputModel,
  HitFormDefinitionBuilderFileInputModel,
  HitFormDefinitionBuilderSignatureInputModel,
  HitFormDefinitionBuilderListInputModel,
  HitFormDefinitionBuilderRadioInputModel,
  HitFormDefinitionBuilderHoursInputModel,
  HitFormDefinitionBuilderTimeInputModel,
};
