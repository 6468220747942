// @ts-nocheck
import { HitComponentsVuePlugin } from '@hit/components';
import { createApp } from 'vue';
import { i18n } from './plugins/i18n';
import App from './App.vue';
import './assets/tailwind.css';
// import router from "./router";
const app = createApp(App);
app.use(HitComponentsVuePlugin);
// app.use(router)
app.use(i18n);
app.mount('#app');
