export var enumValues;
(function (enumValues) {
    enumValues[enumValues["email"] = 0] = "email";
    enumValues[enumValues["password"] = 1] = "password";
    enumValues[enumValues["confirmPassword"] = 2] = "confirmPassword";
    enumValues[enumValues["firstName"] = 3] = "firstName";
    enumValues[enumValues["lastName"] = 4] = "lastName";
    enumValues[enumValues["vatNumber"] = 5] = "vatNumber";
    enumValues[enumValues["countryCode"] = 6] = "countryCode";
    enumValues[enumValues["designationLegal"] = 7] = "designationLegal";
    enumValues[enumValues["street"] = 8] = "street";
    enumValues[enumValues["houseNumber"] = 9] = "houseNumber";
    enumValues[enumValues["boxNumber"] = 10] = "boxNumber";
    enumValues[enumValues["postCode"] = 11] = "postCode";
    enumValues[enumValues["city"] = 12] = "city";
    enumValues[enumValues["billingEmail"] = 13] = "billingEmail";
    enumValues[enumValues["phone"] = 14] = "phone";
    enumValues[enumValues["mobile"] = 15] = "mobile";
    enumValues[enumValues["rightsAccepted"] = 16] = "rightsAccepted";
    enumValues[enumValues["dateRequest"] = 17] = "dateRequest";
    enumValues[enumValues["totalCostModulePerUser"] = 18] = "totalCostModulePerUser";
    enumValues[enumValues["checkVatNumberBeforeAddAccount"] = 19] = "checkVatNumberBeforeAddAccount";
    enumValues[enumValues["userSystemLanguage"] = 20] = "userSystemLanguage";
    enumValues[enumValues["activity"] = 21] = "activity";
})(enumValues || (enumValues = {}));
export class Form {
    // USAGE = Used to add content in the object user that's passed on all pages
    static addContentInUserCompanyObject(myUser, propToModify, data, booleanValue, listValue, intValue) {
        if (data === '' ||
            data === null ||
            data === 'null' ||
            data === undefined ||
            data === 'undefined') {
            data = null;
        }
        switch (propToModify) {
            case enumValues.email:
                myUser.usernameEmail = data;
                break;
            case enumValues.password:
                myUser.password = data;
                break;
            case enumValues.confirmPassword:
                myUser.confirmPassword = data;
                break;
            case enumValues.firstName:
                myUser.firstName = data;
                break;
            case enumValues.lastName:
                myUser.lastName = data;
                break;
            case enumValues.vatNumber:
                myUser.vatNumber = data;
                break;
            case enumValues.countryCode:
                myUser.countryCode = data;
                break;
            case enumValues.designationLegal:
                myUser.designationLegal = data;
                break;
            case enumValues.street:
                myUser.street = data;
                break;
            case enumValues.houseNumber:
                myUser.houseNumber = data;
                break;
            case enumValues.boxNumber:
                myUser.boxNumber = data;
                break;
            case enumValues.postCode:
                myUser.postCode = data;
                break;
            case enumValues.city:
                myUser.city = data;
                break;
            case enumValues.billingEmail:
                myUser.billingEmail = data;
                break;
            case enumValues.phone:
                myUser.phone = data;
                break;
            case enumValues.mobile:
                myUser.mobile = data;
                break;
            case enumValues.rightsAccepted:
                myUser.rightsAccepted = booleanValue;
                break;
            case enumValues.dateRequest:
                myUser.dateRequest = new Date();
                break;
            case enumValues.totalCostModulePerUser:
                myUser.totalCostModulePerUser = intValue;
                break;
            case enumValues.checkVatNumberBeforeAddAccount:
                myUser.checkVatNumberBeforeAddAccount = booleanValue;
                break;
            case enumValues.userSystemLanguage:
                myUser.userSystemLanguage = data;
                break;
            case enumValues.activity:
                myUser.activity = data;
                break;
        }
    }
}
// ***************************************************************
//           Functions called on all pages of the form
// ***************************************************************
