import {BaseChildEntityService} from '../../../../api';
import {makeEntityStore} from '../../../helpers/factory';
import FollowUp from '../../../../models/hit/common/FollowUp';
import {addressFollowUpSchema} from '../../../../models/hit/address/schema';

export default makeEntityStore({
  modelCls: FollowUp,
  service: new BaseChildEntityService(
    'address_follow_up',
    'address_id',
    FollowUp
  ),
  schema: addressFollowUpSchema,
});
