import HitSaving from '../../components/loading/HitSaving.vue';
import {createApp, defineComponent} from 'vue';

export default function (app) {
  const updateSaving = (el, binding) => {
    if (binding.value) {
      insertDom(el);
    } else {
      // Remove relative class from parent as it is no longer required
      el.classList.remove('relative');

      // Set saving to false
      el.savingInstance._instance.data.saving = false;
    }
  };

  const insertDom = (el) => {
    // Set loading to visible
    el.savingInstance._instance.data.saving = true;

    // Add relative to parent to display loading correctly with absolute
    if (!el.classList.contains('relative')) {
      el.classList.add('relative');
    }

    // If the elements added by prepend are the same one, they will not be added repeatedly
    el.prepend(el.savingMask);
  };

  app.directive('hitSaving', {
    mounted: function (el, binding) {
      // Create mask that will be prepended to html element
      const mask = createApp(defineComponent({extends: HitSaving}));
      const maskMount = document.createElement('div');
      mask.mount(maskMount);

      //store component instance to access it later
      el.savingInstance = mask;
      //store component dom to access it later
      el.savingMask = maskMount;
      //if(binding.value) -> updateSaving
      binding.value && updateSaving(el, binding);
    },
    beforeUpdate: function (el, binding) {
      if (binding.oldValue !== binding.value) {
        // update display
        updateSaving(el, binding);
      }
    },
    unmounted: function (el, binding) {
      el.savingInstance.unmount();
    },
  });
}
