import BaseModel from '../BaseModel';

export default class Tag extends BaseModel {
  constructor({designation}) {
    super();
    this.designation = designation;
  }

  static factory() {
    return new Tag({});
  }
}
