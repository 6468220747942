<template>
  <hit-base-input
    :label="label"
    :validation-state="validationState"
    :remove-base-height="true"
    :use-custom-height="true"
    :full-width="false"
  >
    <hit-tabs
      v-if="responsiveBreakpointLg"
      class="pt-1 w-full"
      @tabSelect="onTabSelected"
    >
      <hit-tab
        id="editor"
        key="editor"
        :title="t('hit-base.form.editor')"
        :visible="tabVisible === 'editor'"
      >
        <div
          class="hit-form-definition-builder border-entity border-table bg-table"
          :class="{
            relative:
              !syntaxValid || builderDeactivated || !responsiveBreakpointMd,
          }"
        >
          <div
            v-if="
              (responsiveBreakpointMd && !syntaxValid) || builderDeactivated
            "
            class="absolute top-0 left-0 bottom-0 right-0 opacity-95 grid grid-cols-6 items-center justify-center"
          >
            <p class="text-xl col-start-2 col-end-6">
              {{ $t('hit-base.form.builder-deactivated') }}
            </p>
          </div>
          <div class="p-4 grid">
            <draggable
              class="grid gap-4 auto-rows-max"
              :list="sections"
              group="baseComponents"
              item-key="id"
              @change="onBuilderChange"
            >
              <template #item="{element: sectionComponent}">
                <hit-form-definition-builder-component
                  :designation="sectionComponent[tHitField('label')]"
                  :editable="true"
                  :component-type="sectionComponent.componentType"
                  :component-name="t(sectionComponent.getComponentNameKey())"
                  @edit="onEditComponent(sectionComponent)"
                  @delete="onDeleteComponent(sectionComponent)"
                >
                  <draggable
                    class="grid gap-4"
                    group="childComponents"
                    :list="sectionComponent.content"
                    item-key="id"
                    @change="onBuilderChange"
                  >
                    <template #item="{element: inputComponent}">
                      <hit-form-definition-builder-component
                        :component-type="inputComponent.componentType"
                        :editable="true"
                        :designation="inputComponent[tHitField('label')]"
                        :component-name="
                          t(inputComponent.getComponentNameKey())
                        "
                        @edit="
                          onEditComponent(inputComponent, sectionComponent)
                        "
                        @delete="
                          onDeleteComponent(inputComponent, sectionComponent)
                        "
                      >
                        <draggable
                          v-if="inputComponent.componentType === 'list-input'"
                          class="grid gap-4"
                          group="childComponents"
                          :list="inputComponent.content"
                          item-key="id"
                          @change="onBuilderChange"
                        >
                          <template #item="{element: insideListInputComponent}">
                            <hit-form-definition-builder-component
                              v-if="
                                inputsForbiddenForList.includes(
                                  insideListInputComponent.componentType
                                )
                              "
                              :component-type="
                                insideListInputComponent.componentType
                              "
                              :editable="true"
                              :designation="
                                t('hit-app.form.invalid-component-for-list')
                              "
                              :component-name="
                                t(
                                  insideListInputComponent.getComponentNameKey()
                                )
                              "
                              always-invalid
                              @delete="
                                onDeleteComponent(
                                  insideListInputComponent,
                                  inputComponent
                                )
                              "
                            />
                            <hit-form-definition-builder-component
                              v-else
                              :component-type="
                                insideListInputComponent.componentType
                              "
                              :editable="true"
                              :designation="
                                insideListInputComponent[tHitField('label')]
                              "
                              :component-name="
                                t(
                                  insideListInputComponent.getComponentNameKey()
                                )
                              "
                              @edit="
                                onEditComponent(
                                  insideListInputComponent,
                                  inputComponent
                                )
                              "
                              @delete="
                                onDeleteComponent(
                                  insideListInputComponent,
                                  inputComponent
                                )
                              "
                            />
                          </template>
                        </draggable>
                      </hit-form-definition-builder-component>
                    </template>
                  </draggable>
                </hit-form-definition-builder-component>
              </template>
            </draggable>
          </div>
          <div class="border-l border-table p-4 grid gap-4 auto-rows-max">
            <draggable
              class="grid gap-4 auto-rows-max"
              :list="baseComponents"
              :group="{name: 'baseComponents', pull: 'clone', put: false}"
              :clone="clone"
              item-key="id"
            >
              <template #item="{element: component}">
                <hit-form-definition-builder-component
                  :component-type="component.componentType"
                  :component-name="
                    t(`hit-base.form.${component.componentType}-name`)
                  "
                />
              </template>
            </draggable>
            <draggable
              class="grid gap-4 auto-rows-max"
              :list="childComponents"
              :group="{name: 'childComponents', pull: 'clone', put: false}"
              :clone="clone"
              item-key="id"
            >
              <template #item="{element: component}">
                <hit-form-definition-builder-component
                  :component-type="component.componentType"
                  :component-name="
                    t(`hit-base.form.${component.componentType}-name`)
                  "
                />
              </template>
            </draggable>
          </div>
        </div>
      </hit-tab>
      <hit-tab
        id="code"
        key="code"
        :title="t('hit-base.form.code')"
        :visible="tabVisible === 'code'"
      >
        <hit-input-code
          :value="sectionsYaml"
          :validation-state="validationState"
          @change="yamlChange"
        />
      </hit-tab>
    </hit-tabs>
    <hit-input-code
      v-if="responsiveBreakpointMd && !responsiveBreakpointLg"
      :value="sectionsYaml"
      :validation-state="validationState"
      @change="yamlChange"
    />
    <hit-dialog
      id="component-edition-dialog"
      v-model:visible="editComponentDialogVisible"
      :title="`${t('hit-base.form.component-edition')} - ${t(
        componentBeingEdited
          ? componentBeingEdited.getComponentNameKey()
          : 'common.unknown'
      )}`"
      :ok-button-label="t('hit-base.common.validate')"
      :show-cancel-button="true"
      cancel-button-icon="clear"
      :cancel-button-label="t('hit-components.common.cancel')"
      ok-button-color="accent"
      size="large"
    >
      <template #body>
        <hit-form-definition-builder-entity-component
          v-if="
            componentBeingEdited &&
              componentBeingEdited.componentType === 'entity-input'
          "
          :value="componentBeingEdited"
          :validations="componentValidations"
          @save="onSaveComponentEdition"
          @cancel="onCancelComponentEdition"
        />
        <hit-form-definition-builder-radio-component
          v-else-if="
            componentBeingEdited &&
              componentBeingEdited.componentType === 'radio-input'
          "
          :value="componentBeingEdited"
          :validations="componentValidations"
          @save="onSaveComponentEdition"
          @cancel="onCancelComponentEdition"
        />
        <hit-form-definition-builder-hours-component
          v-else-if="
            componentBeingEdited &&
              componentBeingEdited.componentType === 'hours-input'
          "
          :value="componentBeingEdited"
          :validations="componentValidations"
          @save="onSaveComponentEdition"
          @cancel="onCancelComponentEdition"
        />
        <hit-form-definition-builder-base-component
          v-else-if="componentBeingEdited"
          :value="componentBeingEdited"
          :validations="componentValidations"
          @save="onSaveComponentEdition"
          @cancel="onCancelComponentEdition"
        />
      </template>
      <template #footer>
        <div />
      </template>
    </hit-dialog>
    <hit-dialog
      id="component-edition-error-dialog"
      v-model:visible="editComponentErrorDialogVisible"
      :title="`${t('hit-base.form.component-edition')} - ${t(
        'hit-base.common.error'
      )}`"
      ok-button-color="accent"
      ok-button-label="Ok"
      size="large"
    >
      <template #body>
        {{ t('hit-base.form.duplicate-id') }}
      </template>
    </hit-dialog>
  </hit-base-input>
</template>

<script>
import HitFormDefinitionBuilderComponent from './component-forms/HitFormDefinitionBuilderComponent';
import {
  HitFormDefinitionBuilderCheckboxInputModel,
  HitFormDefinitionBuilderDateInputModel,
  HitFormDefinitionBuilderEntityInputModel,
  HitFormDefinitionBuilderFileInputModel,
  HitFormDefinitionBuilderHoursInputModel,
  HitFormDefinitionBuilderIntInputModel,
  HitFormDefinitionBuilderListInputModel,
  HitFormDefinitionBuilderLocationInputModel,
  HitFormDefinitionBuilderNumberInputModel,
  HitFormDefinitionBuilderRadioInputModel,
  HitFormDefinitionBuilderRichTextInputModel,
  HitFormDefinitionBuilderSectionModel,
  HitFormDefinitionBuilderSignatureInputModel,
  HitFormDefinitionBuilderTextAreaInputModel,
  HitFormDefinitionBuilderTextInputModel,
  HitFormDefinitionBuilderTimeInputModel,
} from './HitFormDefinitionBuilderComponentModel';
import draggable from 'vuedraggable';
import {v4 as uuidv4} from 'uuid';
import {HitYamlUtils} from '@hit/components/src/utils/yaml';
import HitFormDefinitionBuilderBaseComponent from './component-forms/HitFormDefinitionBuilderBaseComponent';
import HitFormDefinitionBuilderEntityComponent from './component-forms/HitFormDefinitionBuilderEntityComponent';
import HitFormDefinitionBuilderRadioComponent from './component-forms/HitFormDefinitionBuilderRadioComponent.vue';
import HitFormDefinitionBuilderHoursComponent from './component-forms/HitFormDefinitionBuilderHoursComponent.vue';
import {
  allCompanyLanguagesFieldsRequired,
  getRequiredValidations,
  HitBreakpointsMixin,
  HitFormValidationMixin,
  HitInputMixin,
  IntegerConstraint,
} from '@hit/components';
import {useI18n} from 'vue-i18n';
import {useConfigurationStore} from '../../../../store';

export default {
  name: 'HitFormDefinitionBuilder',
  components: {
    HitFormDefinitionBuilderEntityComponent,
    HitFormDefinitionBuilderBaseComponent,
    draggable,
    HitFormDefinitionBuilderComponent,
    HitFormDefinitionBuilderRadioComponent,
    HitFormDefinitionBuilderHoursComponent,
  },
  mixins: [HitInputMixin, HitFormValidationMixin, HitBreakpointsMixin],
  inject: ['tHitField'],
  props: {
    /**
     * YAML of the FormDefinition
     */
    value: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Set validity of the syntax
     */
    syntaxValid: Boolean,
  },
  setup() {
    const {t} = useI18n();
    const config = useConfigurationStore();
    return {t, config};
  },
  data() {
    return {
      tabVisible: 'editor',
      componentBeingEdited: null,
      componentBeingEditedParent: null,
      componentBeingEditedGrandParent: null,
      editComponentDialogVisible: false,
      editComponentErrorDialogVisible: false,
      sections: [],
      inputsForbiddenForList: [
        'textarea-input',
        'richtext-input',
        'location-input',
        'signature-input',
        'list-input',
      ],
      baseComponents: [
        {
          id: 1,
          componentType: 'section',
        },
      ],
      childComponents: [
        {
          id: 2,
          componentType: 'text-input',
        },
        {
          id: 3,
          componentType: 'textarea-input',
        },
        {
          id: 4,
          componentType: 'richtext-input',
        },
        {
          id: 5,
          componentType: 'checkbox-input',
        },
        {
          id: 7,
          componentType: 'entity-input',
        },
        {
          id: 8,
          componentType: 'date-input',
        },
        {
          id: 9,
          componentType: 'int-input',
        },
        {
          id: 10,
          componentType: 'number-input',
        },
        {
          id: 11,
          componentType: 'location-input',
        },
        {
          id: 12,
          componentType: 'file-input',
        },
        {
          id: 13,
          componentType: 'signature-input',
        },
        {
          id: 14,
          componentType: 'list-input',
        },
        {
          id: 15,
          componentType: 'radio-input',
        },
        {
          id: 16,
          componentType: 'hours-input',
        },
        {
          id: 17,
          componentType: 'time-input',
        },
      ],
      configStore: useConfigurationStore(),
      componentsValuesProperties: [
        {
          propertyName: 'id',
          header: this.t('hit-base.common.id'),
          sortable: true,
          searchable: true,
          filterable: true,
          maxWidth: '8em',
        },
        {
          propertyName: 'label',
          header: this.t('hit-base.common.label'),
          sortable: true,
          searchable: true,
          filterable: true,
          maxWidth: '50%',
        },
      ],
      builderDeactivated: false,
    };
  },
  computed: {
    componentValidations() {
      let result = {
        constraints: {
          required: {},
        },
      };
      allCompanyLanguagesFieldsRequired(
        result,
        'label',
        this.configStore.companyLanguagesObject,
        'allCompanyLanguagesFieldsRequired',
        this.tHitField
      );
      if (this.componentBeingEdited) {
        if (this.componentBeingEdited.componentType === 'entity-input') {
          result.reference = getRequiredValidations();
        }

        if (this.componentBeingEdited.componentType === 'int-input') {
          result.constraints.minValue = {};
          result.constraints.maxValue = {};

          new IntegerConstraint(true).addToValidator(
            result.constraints.minValue
          );
          new IntegerConstraint(true).addToValidator(
            result.constraints.maxValue
          );
        }
      }

      return result;
    },
    sectionsYaml() {
      if (!this.value) {
        return '';
      }
      if (
        !(typeof this.value.split === 'function') ||
        !this.value.split('sections:')[1]
      ) {
        return this.value;
      }
      return `sections:${this.value.split('sections:')[1]}`;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.syntaxValid) {
          this.initFormDefinitionSections();
        }
      },
    },
  },
  methods: {
    onTabSelected($event) {
      this.tabVisible = $event;
    },
    onEditComponent(component, parent = null) {
      this.componentBeingEdited = component;
      this.componentBeingEditedParent = parent;
      this.editComponentDialogVisible = true;
    },
    onCancelComponentEdition() {
      this.componentBeingEdited = null;
      this.componentBeingEditedParent = null;
      this.editComponentDialogVisible = false;
    },
    onSaveComponentEdition($event) {
      let idAlreadySet = false;
      if ('id' in $event) {
        if (this.componentBeingEditedParent) {
          idAlreadySet = this.componentBeingEditedParent.content.some(
            (subElt) => subElt.id === $event.id
          );
        } else {
          idAlreadySet = this.sections.some(
            (subElt) => subElt.id === $event.id
          );
        }
      }
      const objectKeys = Object.keys($event);
      for (let i = 0; i < objectKeys.length; i++) {
        let key = objectKeys[i];
        if (key === 'id' && idAlreadySet) {
          this.editComponentErrorDialogVisible = true;
          continue;
        }
        this.componentBeingEdited[key] = $event[key];
      }

      this.componentBeingEdited = null;
      this.componentBeingEditedParent = null;
      this.editComponentDialogVisible = false;
      this.yamlChange(this.generateSectionsYaml());
    },
    clone(original) {
      switch (original.componentType) {
        case 'section':
          return new HitFormDefinitionBuilderSectionModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        case 'text-input':
          return new HitFormDefinitionBuilderTextInputModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        case 'textarea-input':
          return new HitFormDefinitionBuilderTextAreaInputModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        case 'richtext-input':
          return new HitFormDefinitionBuilderRichTextInputModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        case 'checkbox-input':
          return new HitFormDefinitionBuilderCheckboxInputModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        case 'entity-input':
          return new HitFormDefinitionBuilderEntityInputModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        case 'date-input':
          return new HitFormDefinitionBuilderDateInputModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        case 'int-input':
          return new HitFormDefinitionBuilderIntInputModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        case 'number-input':
          return new HitFormDefinitionBuilderNumberInputModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        case 'location-input':
          return new HitFormDefinitionBuilderLocationInputModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        case 'file-input':
          return new HitFormDefinitionBuilderFileInputModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        case 'signature-input':
          return new HitFormDefinitionBuilderSignatureInputModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        case 'list-input':
          return new HitFormDefinitionBuilderListInputModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        case 'radio-input':
          return new HitFormDefinitionBuilderRadioInputModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        case 'hours-input':
          return new HitFormDefinitionBuilderHoursInputModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        case 'time-input':
          return new HitFormDefinitionBuilderTimeInputModel(
            `${uuidv4()}`,
            this.configStore.companyLanguagesObject,
            this.tHitField
          );
        default:
          throw Error('Component not implemented');
      }
    },
    onDeleteComponent(component, parent = null) {
      if (!parent) {
        this.sections = this.sections.filter((c) => c.id !== component.id);
      } else if (parent && parent.content) {
        parent.content = parent.content.filter((c) => c.id !== component.id);
      }
      this.yamlChange(this.generateSectionsYaml());
    },
    yamlChange(sectionsYaml) {
      if (sectionsYaml) {
        this.fireInputChange(sectionsYaml);
      }
    },
    initFormDefinitionSections() {
      this.builderDeactivated = false;
      try {
        let jsonDefinition = HitYamlUtils.toJson(this.value);

        if (jsonDefinition.sections) {
          const sections = [];
          const sectionKeys = Object.keys(jsonDefinition.sections);

          for (let i = 0; i < sectionKeys.length; i++) {
            sections.push(
              HitFormDefinitionBuilderSectionModel.initFromJSON(
                sectionKeys[i],
                jsonDefinition.sections[sectionKeys[i]],
                this.configStore.companyLanguagesObject,
                this.tHitField
              )
            );
          }
          this.sections = sections;
        }
      } catch (e) {
        console.error(e);
        this.builderDeactivated = true;
      }
    },
    generateSectionsYaml() {
      let result = {
        sections: {},
      };
      for (let i = 0; i < this.sections.length; i++) {
        let definitionComponent = this.sections[i];
        result.sections[
          definitionComponent.id
        ] = definitionComponent.getFormDefinition();
      }
      return HitYamlUtils.toYaml(result);
    },
    onBuilderChange(evt) {
      if (
        !this.builderDeactivated &&
        this.syntaxValid &&
        (evt.added || evt.moved)
      ) {
        this.yamlChange(this.generateSectionsYaml());
      }
    },
  },
};
</script>

<style scoped>
.hit-form-definition-builder {
  @apply grid border rounded;
  grid-template-columns: 60% 40%;
}
</style>

<style lang="scss">
#component-edition-dialog {
  .modal {
    overflow: visible !important;
  }
}
</style>
