<template>
  <div
    :ref="(ref) => (rootDom = ref)"
    class="grid items-center cursor-pointer gap-2 pl-4 py-2"
    :class="{
      'hover:text-default bg-panel hover:bg-panel-hover text-panel': !color,
      ['bg-' +
        color +
        ' hover:bg-' +
        color +
        '-hover' +
        ' text-' +
        color]: !!color,
      'pr-4': !hasHoverContent,
      'pr-0': hasHoverContent,
    }"
    :style="gridStyle"
    @mouseenter="hoverContentVisible = true"
    @mouseleave="hoverContentVisible = false"
    @click="onDropdownItemClick"
  >
    <hit-icon
      v-if="icon"
      :icon="icon"
      :size="iconSize"
      :color="'text-' + color"
    />
    <slot>
      <span>{{ label }}</span>
    </slot>
    <hit-icon
      v-if="hasHoverContent"
      :icon="forceDisplayHoverContent ? 'expand-bottom' : 'expand-right'"
    />
    <div
      v-if="showHoverContent"
      class="absolute rounded-md bg-panel ring-1 ring-black ring-opacity-5 text-panel-light py-1 dropdown-content"
      :style="hoverContentStyle"
    >
      <slot name="hoverContent" />
    </div>
  </div>
</template>

<script>
import {HitIcon} from '../icon';
export default {
  name: 'HitDropdownItem',
  components: {
    HitIcon,
  },
  inject: {
    hideDropdown: {
      default: undefined, // a default value is required to suppress warning if not found
    },
  },
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconSize: {
      type: String,
      default: 'base',
    },
    closeOnClick: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: null,
    },
    // if null, uses standard behaviour, if true or false, show or hide content
    forceDisplayHoverContent: {
      type: Boolean,
      default: null,
    },
  },
  emits: ['itemClicked'],
  data() {
    return {
      rootDom: null,
      hoverContentVisible: false,
    };
  },
  computed: {
    hasHoverContent() {
      return !!this.$slots['hoverContent'];
    },
    showHoverContent() {
      return (
        this.hasHoverContent &&
        (this.forceDisplayHoverContent === null
          ? this.hoverContentVisible
          : this.forceDisplayHoverContent)
      );
    },
    hoverContentStyle() {
      if (!this.showHoverContent) {
        return {};
      }
      let result = {};
      let rect = this.rootDom.getBoundingClientRect();
      let parentRect = this.rootDom.parentNode.getBoundingClientRect();
      let availableSpace = window.innerWidth - rect.right;
      if (availableSpace > 150) {
        result.left = rect.right - parentRect.left + 'px';
        result.top = rect.top - parentRect.top + 'px';
      } else {
        result.right = rect.right - parentRect.left + 'px';
        result.top = rect.top - parentRect.top + 'px';
      }
      return result;
    },
    gridStyle() {
      let gridTemplateColumns = '';
      if (this.icon) {
        gridTemplateColumns += 'max-content ';
      }
      gridTemplateColumns += this.hasHoverContent
        ? '1fr max-content'
        : 'max-content';
      return {'grid-template-columns': gridTemplateColumns};
    },
  },
  methods: {
    onDropdownItemClick($event) {
      if (this.closeOnClick) {
        this.hideDropdown();
      }
      this.$emit('item-clicked', $event);
      $event.stopImmediatePropagation();
    },
  },
};
</script>
