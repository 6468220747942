<template>
  <div class="flex items-start gap-x-1">
    <div class="flex flex-col">
      <div
        v-for="option of optionList"
        :key="option.id"
        class="flex items-center gap-1"
      >
        <hit-input
          :value="option.label"
          inline-input
          @change="(value) => changeOptionLabel(option.id, value)"
        />
        <hit-button-delete @click="deleteOption(option.id)" />
      </div>
    </div>
    <hit-button-add @click="addOption" />
  </div>
</template>
<script>
import {HitButtonAdd, HitButtonDelete, HitInput} from '@hit/components';
import {v4 as uuidv4} from 'uuid';

export default {
  name: 'HitOptionsEditor',
  components: {
    HitInput,
    HitButtonAdd,
    HitButtonDelete,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    optionList() {
      return Object.keys(this.options).map((key) => {
        return {
          id: key,
          label: this.options[key].label,
        };
      });
    },
  },
  methods: {
    addOption() {
      let id = uuidv4();
      let optionsCopy = JSON.parse(JSON.stringify(this.options));
      optionsCopy[id] = {label: ''};
      this.$emit('change', optionsCopy);
    },
    changeOptionLabel(id, label) {
      let optionsCopy = JSON.parse(JSON.stringify(this.options));
      optionsCopy[id].label = label;
      this.$emit('change', optionsCopy);
    },
    deleteOption(id) {
      let optionsCopy = JSON.parse(JSON.stringify(this.options));
      delete optionsCopy[id];
      this.$emit('change', optionsCopy);
    },
  },
};
</script>
