import {BaseRootEntityService} from '../../../../api';
import {makeEntityStore} from '../../../helpers/factory';
import Country from '../../../../models/hit/common/Country';
import {countrySchema} from '../../../../models/hit/common/schema';

export default makeEntityStore({
  modelCls: Country,
  service: new BaseRootEntityService('country', Country),
  schema: countrySchema,
});
