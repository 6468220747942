<template>
  <hit-table
    custom-search
    custom-sort
    :items="items"
    :parent-id="parentId"
    :items-selectable="itemsSelectable"
    :paginator="paginator"
    :headers="headers"
    :inline-edition-allowed="inlineEditionAllowed"
    :item-properties="itemProperties"
    :router-link="routerLink"
    :items-per-page="itemsPerPage"
    :default-sort="defaultSort"
    :store-filters="storeFilters"
    :printable="printable"
    :printable-fn="printableFn"
    :deletable-fn="deletableFn"
    :create-item-fn="createItemFn"
    :validations="validations"
    :auto-save="autoSave"
    :is-loading="loading"
    :add-item-allowed="addItemAllowed"
    :custom-line-class="customLineClass"
    :origin-view="originView"
    :deletable="isDeletable"
    :addable="addable"
    v-bind="$attrs"
    @search="onSearch"
    @sort="onSort"
    @delete="onDelete"
    @print="onPrint"
    @update-visible-fields="onUpdateVisibleFields"
  >
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>

    <template
      #editable-row="{
        tabLine,
        itemPropertiesToDisplay,
        disableEditingFn,
        rowValidations,
      }"
    >
      <hit-app-form
        :store-namespace="storeNamespace"
        :value="tabLine.item"
        :parent-id="parentId"
        :store-update="storeUpdate"
        :autosave="autoSave"
        :cancellable="true"
        :validations="rowValidations"
        render-as-table
        @updated="disableEditingFn"
        @cancel="disableEditingFn"
      >
        <template #default="{formData, validationState}">
          <hit-table-row editable>
            <hit-table-cell />
            <hit-table-cell
              v-for="itemProperty in itemPropertiesToDisplay"
              :key="itemProperty.propertyName"
              input
            >
              <slot
                :name="generateCustomSlotName(itemProperty.propertyName, true)"
                :form-data="formData"
                :item-property="itemProperty"
                :validation-state="validationState"
              >
                <hit-input-generic
                  v-model:value="formData[itemProperty.propertyName]"
                  :disabled="itemProperty.readOnly"
                  :name="itemProperty.propertyName"
                  :value-type="
                    getValueType(itemProperty.propertyName, formData)
                  "
                  :validation-state="validationState[itemProperty.propertyName]"
                  :inline-input="true"
                  class="w-full"
                />
              </slot>
            </hit-table-cell>
          </hit-table-row>
        </template>
      </hit-app-form>
    </template>

    <template
      #creation-row="{
        itemPropertiesToDisplay,
        disableCreatingFn,
        newItem,
        rowValidations,
      }"
    >
      <hit-app-form
        :store-namespace="storeNamespace"
        :store-create="storeAdd"
        :value="newItem"
        :parent-id="parentId"
        :store-update="storeUpdate"
        :autosave="autoSave"
        :cancellable="true"
        :validations="rowValidations"
        render-as-table
        @created="disableCreatingFn"
        @cancel="disableCreatingFn"
      >
        <template #default="{formData, validationState}">
          <hit-table-row editable>
            <hit-table-cell />
            <hit-table-cell
              v-for="itemProperty in itemPropertiesToDisplay"
              :key="itemProperty.propertyName"
              input
              :class="`app-table-cell-${generateCustomSlotName(
                itemProperty.propertyName,
                true
              )}`"
            >
              <slot
                :name="generateCustomSlotName(itemProperty.propertyName, true)"
                :form-data="formData"
                :item-property="itemProperty"
                :validation-state="validationState"
              >
                <hit-input-generic
                  v-model:value="formData[itemProperty.propertyName]"
                  :disabled="itemProperty.readOnly"
                  :name="itemProperty.propertyName"
                  :value-type="
                    getValueType(itemProperty.propertyName, formData)
                  "
                  :validation-state="validationState[itemProperty.propertyName]"
                  :inline-input="true"
                  class="w-full"
                />
              </slot>
            </hit-table-cell>
          </hit-table-row>
        </template>
      </hit-app-form>
    </template>

    <template
      #mobile-form="{
        item,
        itemPropertiesToDisplay,
        disableFormFn,
        rowValidations,
      }"
    >
      <hit-app-form
        :store-namespace="storeNamespace"
        :store-create="storeAdd"
        :value="item"
        :parent-id="parentId"
        :store-update="storeUpdate"
        :autosave="autoSave"
        :cancellable="true"
        :validations="rowValidations"
        class="w-full p-1"
        render-as-table
        @created="disableFormFn"
        @updated="disableFormFn"
        @cancel="disableFormFn"
      >
        <template #default="{formData, validationState}">
          <div class="w-full inline-grid gap-1">
            <div
              v-for="itemProperty in itemPropertiesToDisplay"
              :key="itemProperty.propertyName"
              class="hit-form-row"
            >
              <div
                class="lg:text-right lg:pt-1 lg:h-8 font-semibold lg:font-medium font"
              >
                {{ itemProperty.header }} :
              </div>
              <div class="lg:pt-1 hit-form-row-content-with-label">
                <slot
                  :name="
                    generateCustomSlotName(itemProperty.propertyName, true)
                  "
                  :item-property="itemProperty"
                  :form-data="formData"
                  :validation-state="validationState"
                >
                  <hit-input-generic
                    v-model:value="formData[itemProperty.propertyName]"
                    :disabled="itemProperty.readOnly"
                    :name="itemProperty.propertyName"
                    :value-type="
                      getValueType(itemProperty.propertyName, formData)
                    "
                    :validation-state="
                      validationState[itemProperty.propertyName]
                    "
                    :inline-input="true"
                    class="w-full"
                  />
                </slot>
              </div>
            </div>
          </div>
        </template>
      </hit-app-form>
    </template>
    <template #pagination>
      <div class="grid items-center grid-flow-col">
        <div class="mr-2">
          <hit-select
            :inline-input="true"
            :options="itemsPerPageOptions"
            :clearable="false"
            :filterable="false"
            :value="itemsPerPage"
            @change="(id) => setItemsPerPage(id)"
          />
        </div>
        <hit-icon
          icon="expand-left"
          :clickable="currentPage > 0"
          @click="setCurrentPage(currentPage - 1)"
        />
        <span>{{ noPages ? currentPage + 1 + '/' + noPages : '' }}</span>
        <hit-icon
          icon="expand-right"
          :clickable="currentPage + 1 < noPages"
          @click="setCurrentPage(currentPage + 1)"
        />
      </div>
    </template>
  </hit-table>
</template>

<script>
import {HitTableMixin} from '@hit/components';
import {HitLoadingEmitterMixin, HitBreakpointsMixin} from '@hit/components';
import {BaseRootEntityService} from '../../../../api';
import HitAppForm from '../form/HitAppForm.vue';
import HitIcon from '@hit/components/src/components/icon/HitIcon.vue';

const ITEMS_PER_PAGE_OPTIONS = [
  {
    id: 10,
    label: '10',
  },
  {
    id: 20,
    label: '20',
  },
  {
    id: 50,
    label: '50',
  },
];

export default {
  name: 'HitAppTable',
  components: {HitIcon, HitAppForm},
  mixins: [HitBreakpointsMixin, HitTableMixin, HitLoadingEmitterMixin],
  props: {
    /**
     * Parent entity ID
     */
    parentId: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Enable selection of items
     */
    itemsSelectable: {
      type: Boolean,
      default: false,
    },
    /**
     * Enable paginator
     */
    paginator: {
      type: Boolean,
      default: false,
    },
    /**
     * Enable headers
     */
    headers: {
      type: Boolean,
      default: true,
    },
    /**
     * Enable inline edition
     */
    inlineEditionAllowed: {
      type: Boolean,
      default: false,
    },
    /**
     * Item properties to display
     */
    itemProperties: {
      type: Array,
      required: true,
    },
    /**
     * Routerlink to use when clicking on an item
     */
    routerLink: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Default sort of the table
     */
    defaultSort: {
      type: Object,
      default() {
        return {
          property: null,
          order: null,
        };
      },
    },
    /**
     * Store namespace
     */
    storeNamespace: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Store filters
     */
    storeFilters: {
      type: Object,
      default: null,
    },
    /**
     * Store update action
     */
    storeUpdate: {
      type: String,
      required: false,
      default: 'updateItem',
    },
    /**
     * Store fetch action
     */
    storeFetch: {
      type: String,
      required: false,
      default: 'fetchItems',
    },
    /**
     * Store add action
     */
    storeAdd: {
      type: String,
      required: false,
      default: 'addItem',
    },
    /**
     * Store delete action
     */
    storeDelete: {
      type: String,
      required: false,
      default: 'deleteItem',
    },
    /**
     * Url to print item
     */
    printPath: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Enable printing of an item
     */
    printable: {
      type: Boolean,
      default: false,
    },
    /**
     * Function that returns if an item is printable
     */
    printableFn: {
      type: Function,
      default: null,
    },
    /**
     * Function that returns if an item is deletable
     */
    deletableFn: {
      type: Function,
      default: null,
    },
    /**
     * Function to create an item
     */
    createItemFn: {
      type: Function,
      default: null,
    },
    /**
     * Function to print an item
     */
    printItemFn: {
      type: Function,
      default: null,
    },
    /**
     * Validations for an item creation
     */
    validations: {
      type: Object,
      required: false,
      default: null,
    },
    /**
     * Enable automatic save
     */
    autoSave: {
      type: Boolean,
      required: false,
    },
    /**
     * Enable loading of the table
     */
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Allow to add item
     */
    addItemAllowed: {
      type: Boolean,
      default: true,
    },
    /**
     * Custom classes for every table line
     */
    customLineClass: {
      type: [Function, String],
      default: null,
    },
    /**
     * Origin view route
     */
    originView: {
      type: String,
      default: null,
    },
    /**
     * Extra query fields
     */
    extraQueryFields: {
      type: Array,
      default: () => [],
    },
    /**
     * Function that returns the delete promises for the selected items
     */
    deletePromisesFn: {
      type: Function,
      default: null,
    },
    /**
     * Allows to add new entries to the table. If false, the "add" button is hidden.
     */
    addable: {
      type: Boolean,
      default: true,
    },
    searchFields: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      fetchItemsQueryId: null,
      searchValue: null,
      sortParams: undefined,
      currentPage: 0,
      itemsPerPageOptions: [...ITEMS_PER_PAGE_OPTIONS],
      itemsPerPage: 20,
      visibleFields: this.itemProperties.map(
        (x) => x.propertyQuery || x.propertyName
      ), //todo check if fetchItems gets called twice when some columns are hidden
    };
  },
  computed: {
    isItemsLoading() {
      return (
        this.fetchItemsQueryId &&
        this.$store.getters[`${this.storeNamespace}/itemsLoading`](
          this.fetchItemsQueryId
        )
      );
    },
    items() {
      const result =
        this.isLoading || !this.storeNamespace
          ? []
          : this.$store.getters[`${this.storeNamespace}/items`](
              this.fetchItemsQueryId
            );
      return result;
    },
    response() {
      return this.isLoading || !this.storeNamespace
        ? []
        : this.$store.getters[`${this.storeNamespace}/response`](
            this.fetchItemsQueryId
          );
    },
    noPages() {
      if (
        !this.response ||
        !this.response.headers ||
        !this.response.headers['content-range']
      ) {
        return null;
      }
      const noItems = parseInt(
        this.response.headers['content-range'].split('/')[1]
      ); //has format 0-24/3573458 for example
      return Math.ceil(noItems / this.itemsPerPage);
    },
    loading() {
      return this.isLoading || this.isItemsLoading;
    },
    searchFilters() {
      const filters = {};
      if (
        this.searchValue &&
        this.searchFields &&
        this.searchFields.length > 0
      ) {
        //TODO nigel prevent special characters in searchValue
        let searchString = '';
        for (const field of this.searchFields) {
          searchString +=
            this.translateStringToSnakeCase(field) +
            '.like.*' +
            this.searchValue +
            '*,';
        }
        filters['or'] = '(' + searchString.slice(0, -1) + ')';
      }
      return filters;
    },
    fetchItemsQueryParams() {
      const result = {
        q: this.searchValue || null,
        qFields: this.searchValue ? this.searchedFields : null,
        fields: this.queryFields,
        sort: this.sortFields,
        parentId: this.parentId,
        filters: {...this.storeFilters, ...this.searchFilters},
      };
      if (this.paginator) {
        result['getCount'] = true;
        result['limit'] = this.itemsPerPage;
        result['offset'] = this.currentPage * this.itemsPerPage;
      }
      return result;
    },
    isDeletable() {
      return !!this.storeDelete || !!this.deletePromisesFn;
    },
    sortFields() {
      //TODO service fix subprops
      if (this.sortParams && this.sortParams.property) {
        let sign = this.sortParams.asc ? '.asc' : '.desc';
        let prop = this.sortParams.property.propertyName || 'id';
        let subProp = this.sortParams.property.sortProp
          ? `.${this.sortParams.property.sortProp}`
          : '';
        return [`${prop}${subProp}${sign}`];
      } else if (
        this.defaultSort &&
        this.defaultSort.property &&
        this.defaultSort.order
      ) {
        let sign = this.defaultSort.order === 'asc' ? '.asc' : '.desc';
        let prop = this.defaultSort.property || 'id';

        return [`${prop}${sign}`];
      } else {
        return null;
      }
    },
    queryFields() {
      if (this.extraQueryFields) {
        return this.visibleFields.concat(this.extraQueryFields);
      } else {
        return this.visibleFields;
      }
    },
  },
  watch: {
    fetchItemsQueryParams(oldValue, newValue) {
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        this.fetchItems();
      }
    },
    loading: {
      immediate: true,
      handler(newValue) {
        this.setLoading(newValue);
      },
    },
  },
  beforeMount() {
    this.fetchItems();
  },
  methods: {
    async fetchItems() {
      if (!this.isLoading && this.storeNamespace) {
        this.fetchItemsQueryId = undefined;
        this.fetchItemsQueryId = await this.$store.dispatch(
          `${this.storeNamespace}/${this.storeFetch}`,
          {
            key: 'APP_TABLE_FETCH_ITEMS',
            query: this.fetchItemsQueryParams,
            listenAdd: true,
          }
        );
      }
    },
    onSearch(newValue) {
      this.searchValue = newValue;
      this.fetchItems();
    },
    onSort(sortParams) {
      this.sortParams = sortParams;
    },
    onDelete(itemsToDelete) {
      let promises = [];
      if (this.storeDelete) {
        itemsToDelete.forEach((itemToDelete) => {
          promises.push(
            this.$store.dispatch(`${this.storeNamespace}/${this.storeDelete}`, {
              parentId: this.parentId,
              item: itemToDelete,
            })
          );
        });
      } else if (this.deletePromisesFn) {
        promises = this.deletePromisesFn(itemsToDelete);
      }
      Promise.all(promises).then(() => {
        this.selectedItems = [];
        if (!this.storeDelete && this.deletePromisesFn) {
          this.fetchItems();
        }
      });
    },
    onPrint(itemsToPrint) {
      let baseRootEntityService = new BaseRootEntityService(this.printPath);
      itemsToPrint.forEach((itemToPrint) => {
        baseRootEntityService.print({item: itemToPrint});
      });
    },
    onUpdateVisibleFields(visibleFields) {
      this.visibleFields = visibleFields;
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
    setItemsPerPage(n) {
      this.itemsPerPage = n;
      this.currentPage = 0;
    },
    //TODO nigel unify duplicated code
    translateStringToSnakeCase(s) {
      if (s) {
        return s
          .trim()
          .split(/\.?(?=[A-Z])/)
          .join('_')
          .toLowerCase();
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss"></style>
