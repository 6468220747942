<template>
  <hit-input-time
    :value="container.data[attribute]"
    @changeTime="(value) => container.handleChange(attribute, value)"
  />
</template>
<script>
import {HitInputTime} from '@hit/components';

export default {
  name: 'HitContainerInputTime',
  components: {
    HitInputTime,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
    steps: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
</script>
