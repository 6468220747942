import BaseRootEntityService from '../../../api/base/BaseRootEntityService';
import {Project, projectSchema} from '../../../models';
import tag from './tag';
import {makeEntityStore} from '../../helpers/factory';

export default makeEntityStore({
  modelCls: Project,
  service: new BaseRootEntityService('project', Project),
  schema: projectSchema,
  tag,
});
