import BaseModel from '../BaseModel';

export default class Address extends BaseModel {
  // méthode constructor
  constructor({
    id,
    no,
    designation,
    active,
    customer,
    supplier,
    potentialCustomer,
    potentialSupplier,
  }) {
    super();
    this.id = id;
    this.no = no;
    this.designation = designation;
    this.active = active;
    this.customer = customer;
    this.supplier = supplier;
    this.potentialCustomer = potentialCustomer;
    this.potentialSupplier = potentialSupplier;
    this.street = undefined;
    this.postcode = undefined;
    this.city = undefined;
    this.country = undefined;
  }

  static factory() {
    return new Address({active: true});
  }

  static getMTMRelations() {
    return {
      tags: {
        pivotTableName: 'address_tag',
        parentIdColumn: 'address_id',
        childIdColumn: 'tag_id',
        childTableName: 'tag',
      },
      communicationModeData: {
        pivotTableName: 'address_communication_mode_data',
        parentIdColumn: 'address_id',
        childIdColumn: 'communication_mode_id',
        childTableName: 'communication_mode',
      },
      followUps: {
        pivotTableName: 'address_follow_up',
        parentIdColumn: 'address_id',
        childIdColumn: 'author_id',
        childTableName: 'staff',
      },
    };
  }

  static getKeyTranslations() {
    return {
      tag: 'tags',
    };
  }
}
