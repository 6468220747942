<template>
  <span
    :class="{
      'text-danger-icon': !syntaxValid,
      'text-success-icon': syntaxValid,
    }"
    :title="showTooltip ? syntaxValidLabel : ''"
  >{{ syntaxValidLabel }}</span>
</template>

<script>
import {useI18n} from 'vue-i18n';

export default {
  name: 'HitFormDefinitionSyntaxValid',
  props: {
    /**
     * Set validity of the syntax
     */
    syntaxValid: {
      type: Boolean,
      default: false,
    },
    /**
     * Show tooltip
     */
    showTooltip: Boolean,
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  computed: {
    syntaxValidLabel() {
      return this.syntaxValid
        ? this.t('hit-base.common.valid')
        : this.t('hit-base.common.invalid');
    },
  },
};
</script>
