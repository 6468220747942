<template>
  <hit-input-checkbox
    v-if="valueType === 'boolean'"
    :value="value"
    :disabled="true"
    use-custom-height
    :remove-padding="true"
  />
  <hit-date
    v-else-if="['date', 'datetime', 'datetime-seconds'].includes(valueType)"
    :value="value"
    :disabled="true"
    :show-tooltip="true"
    :show-only-date="valueType === 'date'"
    :show-seconds="valueType === 'datetime-seconds'"
  />
  <hit-input-colour-picker
    v-else-if="valueType === 'colour'"
    :value="value"
    :disabled="true"
  />
  <hit-entity-list
    v-else-if="valueType === 'tag'"
    :entities-to-display="value"
  />
  <span
    v-else-if="valueType === 'decimal'"
    class="w-full"
  >{{
    decimalValue
  }}</span>
  <span
    v-else-if="valueType === 'int'"
    class="w-full"
  >
    {{ integerValue }}
  </span>
  <span v-else-if="valueType === 'enum'">{{ value?.label ?? '' }}</span>
  <span
    v-else
    class="w-full"
    :title="value"
  >{{ value }}</span>
</template>

<script>
import HitDate from '../../date/HitDate';
import HitInputCheckbox from '../input/HitInputCheckbox.vue';
import HitInputColourPicker from '../input/HitInputColourPicker.vue';
import {useI18n} from 'vue-i18n';
import HitEntityList from '../../list/HitEntityList.vue';

export default {
  name: 'HitInputGenericReadOnly',
  components: {
    HitDate,
    HitInputCheckbox,
    HitInputColourPicker,
    HitEntityList,
  },
  props: {
    /**
     * Value to display
     */
    value: undefined,
    /**
     * Type of the value to display
     */
    valueType: {
      type: String,
      default: null,
    },
  },
  setup() {
    const {n} = useI18n();
    return {n};
  },
  computed: {
    decimalValue() {
      let decimalPlaces;
      try {
        decimalPlaces = this.value.split('.')[1].length;
      } catch {
        decimalPlaces = 0;
      }
      const numberFormatting = `decimal${decimalPlaces}Digits`;
      return this.n(parseFloat(this.value), numberFormatting);
    },
    integerValue() {
      return this.value ? this.value : 0;
    },
  },
};
</script>
