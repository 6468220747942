//TODO make different locales dynamic instead of hardcoded
export const getValSchemaFormDef = () => {
  return getValSchema(false, false);
};

export const getValSchemaSections = () => {
  return getValSchema(true, false);
};

export const getValSchemaSectionsForDisplay = () => {
  return getValSchema(true, true);
};

const getValSchema = (onlySections, forDisplay) => {
  return {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    required: onlySections ? ['sections'] : ['version', 'meta', 'sections'],
    properties: {
      version: {
        type: 'number',
        enum: [1],
      },
      meta: {
        type: 'object',
        required: ['name', 'validation'],
        properties: {
          name: {
            $ref: '#/definitions/str_or_localized_str',
          },
          help: {
            $ref: '#/definitions/str_or_localized_str',
          },
          parent: {
            $ref: '#/definitions/field_entity',
          },
          formDate: {
            $ref: '#/definitions/field_date',
          },
          validation: {
            type: 'object',
            required: ['type'],
            properties: {
              type: {
                type: 'string',
                enum: ['validate', 'submit'],
              },
            },
          },
          notification: {
            type: 'array',
            items: {
              $ref: '#/definitions/notification_details',
            },
            minItems: 1,
          },
        },
      },
      sections: {
        type: 'object',
        patternProperties: {
          '.*': {
            $ref: '#/definitions/section',
          },
        },
        additionalProperties: false,
      },
    },
    definitions: {
      str_or_localized_str_not_empty: {
        anyOf: [
          {
            type: 'string',
          },
          {
            $ref: '#/definitions/localized_str_not_empty',
          },
        ],
      },
      str_or_localized_str: {
        anyOf: [
          {
            type: 'string',
          },
          {
            $ref: '#/definitions/localized_str',
          },
        ],
      },
      localized_str_not_empty: {
        type: 'object',
        properties: {
          fr_be: {type: 'string'},
          en_gb: {type: 'string'},
          nl_be: {type: 'string'},
          de_be: {type: 'string'},
          mr_in: {type: 'string'},
        },
        additionalProperties: false,
        minProperties: 1,
      },
      localized_str: {
        type: 'object',
        properties: {
          fr_be: {type: 'string'},
          en_gb: {type: 'string'},
          nl_be: {type: 'string'},
          de_be: {type: 'string'},
          mr_in: {type: 'string'},
        },
        additionalProperties: false,
      },
      label: {
        $ref: '#/definitions/str_or_localized_str',
      },
      label_not_empty: {
        $ref: forDisplay
          ? '#/definitions/str_or_localized_str'
          : '#/definitions/str_or_localized_str_not_empty',
      },
      help: {
        $ref: '#/definitions/str_or_localized_str',
      },
      field_date: {
        type: 'object',
        required: ['type', 'label'],
        properties: {
          type: {enum: ['date']},
          label: {$ref: '#/definitions/label_not_empty'},
          help: {$ref: '#/definitions/help'},
          constraints: {$ref: '#/definitions/date_constraints'},
        },
      },
      date_constraints: {
        type: 'object',
        properties: {
          required: {type: 'boolean'},
        },
      },
      field_entity: {
        type: 'object',
        required: ['type', 'reference', 'label'],
        properties: {
          type: {enum: ['entity']},
          reference: {
            enum: [
              'staff',
              'equipment',
              'country',
              'address',
              'project',
              'project_part',
              'resource',
            ],
          },
          input: {type: 'string', enum: ['select']},
          label: {$ref: '#/definitions/label_not_empty'},
          help: {$ref: '#/definitions/help'},
          constraints: {$ref: '#/definitions/entity_constraints'},
        },
      },
      entity_constraints: {
        type: 'object',
        properties: {
          required: {type: 'boolean'},
        },
      },
      notification_details: {
        type: 'object',
        required: ['trigger', 'method', 'recipients'],
        properties: {
          trigger: {
            type: 'string',
            enum: ['submit', 'validate'],
          },
          method: {
            type: 'string',
            enum: ['email'],
          },
          recipients: {
            type: 'array',
            items: {
              anyOf: [{type: 'string'}, {enum: ['{{author}}']}],
            },
            minItems: 1,
          },
        },
      },
      section: {
        type: 'object',
        required: ['meta', 'content'],
        properties: {
          meta: {
            type: 'object',
            required: ['label'],
            properties: {
              label: {$ref: '#/definitions/label_not_empty'},
              help: {$ref: '#/definitions/help'},
            },
          },
          content: {
            type: 'object',
            patternProperties: {
              '.*': {$ref: '#/definitions/field_or_list'},
            },
            additionalProperties: false,
          },
        },
      },
      field_or_list: {
        anyOf: [
          {$ref: '#/definitions/field'},
          {$ref: '#/definitions/field_list'},
        ],
      },
      field: {
        anyOf: [
          {$ref: '#/definitions/field_date'},
          {$ref: '#/definitions/field_radio'},
          {$ref: '#/definitions/field_hours'},
          {$ref: '#/definitions/field_time'},
          {$ref: '#/definitions/field_checkbox'},
          {$ref: '#/definitions/field_select'},
          {$ref: '#/definitions/field_text'},
          {$ref: '#/definitions/field_int'},
          {$ref: '#/definitions/field_number'},
          {$ref: '#/definitions/field_entity'},
          {$ref: '#/definitions/field_location'},
          {$ref: '#/definitions/field_file'},
          {$ref: '#/definitions/field_signature'},
        ],
      },
      list_compatible_field: {
        anyOf: [
          {$ref: '#/definitions/field_date'},
          {$ref: '#/definitions/field_checkbox'},
          {$ref: '#/definitions/field_select'},
          {$ref: '#/definitions/field_single_line_text'},
          {$ref: '#/definitions/field_int'},
          {$ref: '#/definitions/field_number'},
          {$ref: '#/definitions/field_entity'},
          {$ref: '#/definitions/field_file'},
          {$ref: '#/definitions/field_hours'},
          {$ref: '#/definitions/field_time'},
          {$ref: '#/definitions/field_radio'},
        ],
      },
      field_list: {
        type: 'object',
        required: ['type', 'meta', 'content'],
        properties: {
          type: {enum: ['list']},
          meta: {
            type: 'object',
            required: ['label'],
            properties: {
              label: {$ref: '#/definitions/label'},
              help: {$ref: '#/definitions/help'},
            },
          },
          content: {
            type: 'object',
            patternProperties: {
              '.*': {
                $ref: forDisplay
                  ? '#/definitions/field_or_list'
                  : '#/definitions/list_compatible_field',
              },
            },
            additionalProperties: false,
          },
          constraints: {$ref: '#/definitions/field_list_constraints'},
        },
      },
      field_list_constraints: {
        type: 'object',
        properties: {
          required: {type: 'boolean'},
          minLength: {type: 'integer', minimum: 0},
          maxLength: {type: 'integer', minimum: 0},
        },
      },
      label_with_optional_help: {
        type: 'object',
        required: ['label'],
        properties: {
          label: {
            $ref: '#/definitions/label_not_empty',
          },
          help: {
            $ref: '#/definitions/help',
          },
        },
      },
      field_hours: {
        type: 'object',
        required: ['type', 'label', 'steps'],
        properties: {
          type: {enum: ['hours']},
          label: {$ref: '#/definitions/label_not_empty'},
          help: {$ref: '#/definitions/help'},
          steps: {type: 'integer', minimum: 0},
          constraints: {$ref: '#/definitions/hours_constraints'},
        },
      },
      hours_constraints: {
        type: 'object',
        properties: {},
      },
      field_time: {
        type: 'object',
        required: ['type', 'label'],
        properties: {
          type: {enum: ['time']},
          label: {$ref: '#/definitions/label_not_empty'},
          help: {$ref: '#/definitions/help'},
          constraints: {$ref: '#/definitions/time_constraints'},
        },
      },
      time_constraints: {
        type: 'object',
        properties: {},
      },
      field_radio: {
        type: 'object',
        required: ['type', 'label', 'values'],
        properties: {
          type: {enum: ['radio']},
          label: {$ref: '#/definitions/label_not_empty'},
          help: {$ref: '#/definitions/help'},
          values: {
            type: 'object',
            patternProperties: {
              '.*': {$ref: '#/definitions/label_with_optional_help'},
            },
            additionalProperties: false,
          },
          constraints: {$ref: '#/definitions/radio_constraints'},
        },
      },
      radio_constraints: {
        type: 'object',
        properties: {
          required: {type: 'boolean'},
        },
      },
      field_checkbox: {
        type: 'object',
        required: ['type', 'label'],
        properties: {
          type: {enum: ['checkbox']},
          label: {$ref: '#/definitions/label_not_empty'},
          help: {$ref: '#/definitions/help'},
          values: {
            type: 'object',
            patternProperties: {
              '.*': {$ref: '#/definitions/label_with_optional_help'},
            },
            additionalProperties: false,
          },
          constraints: {$ref: '#/definitions/checkbox_constraints'},
        },
      },
      checkbox_constraints: {
        type: 'object',
        properties: {
          required: {type: 'boolean'},
          multiple: {type: 'boolean'},
        },
      },
      field_select: {
        type: 'object',
        required: ['type', 'label', 'values'],
        properties: {
          type: {enum: ['select']},
          label: {$ref: '#/definitions/label_not_empty'},
          help: {$ref: '#/definitions/help'},
          values: {
            type: 'object',
            patternProperties: {
              '.*': {$ref: '#/definitions/label_with_optional_help'},
            },
            additionalProperties: false,
          },
          constraints: {$ref: '#/definitions/select_constraints'},
        },
      },
      select_constraints: {
        type: 'object',
        properties: {
          required: {type: 'boolean'},
          multiple: {type: 'boolean'},
        },
      },
      field_text: {
        type: 'object',
        required: ['type', 'label'],
        properties: {
          type: {enum: ['text', 'textarea', 'richtext']},
          label: {$ref: '#/definitions/label_not_empty'},
          help: {$ref: '#/definitions/help'},
          constraints: {$ref: '#/definitions/text_constraints'},
        },
      },
      field_single_line_text: {
        type: 'object',
        required: ['type', 'label'],
        properties: {
          type: {enum: ['text']},
          label: {$ref: '#/definitions/label_not_empty'},
          help: {$ref: '#/definitions/help'},
          constraints: {$ref: '#/definitions/text_constraints'},
        },
      },
      text_constraints: {
        type: 'object',
        properties: {
          required: {type: 'boolean'},
          minLength: {type: 'integer', minimum: 0},
          maxLength: {type: 'integer', minimum: 0},
        },
      },
      field_int: {
        type: 'object',
        required: ['type', 'label'],
        properties: {
          type: {enum: ['int']},
          label: {$ref: '#/definitions/label_not_empty'},
          help: {$ref: '#/definitions/help'},
          constraints: {$ref: '#/definitions/int_constraints'},
        },
      },
      int_constraints: {
        type: 'object',
        properties: {
          required: {type: 'boolean'},
          minValue: {type: 'integer'},
          maxValue: {type: 'integer'},
        },
      },
      field_number: {
        type: 'object',
        required: ['type', 'label'],
        properties: {
          type: {enum: ['number']},
          label: {$ref: '#/definitions/label_not_empty'},
          help: {$ref: '#/definitions/help'},
          constraints: {$ref: '#/definitions/field_number_constraints'},
        },
      },
      field_number_constraints: {
        type: 'object',
        properties: {
          required: {type: 'boolean'},
          minValue: {type: 'number'},
          maxValue: {type: 'number'},
        },
      },
      field_location: {
        type: 'object',
        required: ['type', 'label'],
        properties: {
          type: {enum: ['location']},
          label: {$ref: '#/definitions/label_not_empty'},
          help: {$ref: '#/definitions/help'},
          values: {
            type: 'object',
            patternProperties: {
              '.*': {$ref: '#/definitions/label_with_optional_help'},
            },
            additionalProperties: false,
          },
          constraints: {$ref: '#/definitions/location_constraints'},
        },
      },
      location_constraints: {
        type: 'object',
        properties: {
          required: {type: 'boolean'},
        },
      },
      field_file: {
        type: 'object',
        required: ['type', 'label'],
        properties: {
          type: {enum: ['file']},
          label: {$ref: '#/definitions/label_not_empty'},
          help: {$ref: '#/definitions/help'},
          values: {
            type: 'object',
            patternProperties: {
              '.*': {$ref: '#/definitions/label_with_optional_help'},
            },
            additionalProperties: false,
          },
          constraints: {$ref: '#/definitions/field_file_constraints'},
        },
      },
      field_file_constraints: {
        type: 'object',
        properties: {
          required: {type: 'boolean'},
        },
      },
      field_signature: {
        type: 'object',
        required: ['type', 'label'],
        properties: {
          type: {enum: ['signature']},
          label: {$ref: '#/definitions/label_not_empty'},
          help: {$ref: '#/definitions/help'},
          values: {
            type: 'object',
            patternProperties: {
              '.*': {$ref: '#/definitions/label_with_optional_help'},
            },
            additionalProperties: false,
          },
          constraints: {$ref: '#/definitions/field_signature_constraints'},
        },
      },
      field_signature_constraints: {
        type: 'object',
        properties: {
          required: {type: 'boolean'},
        },
      },
    },
  };
};
