import BaseService from './BaseService';

export default class BaseRootEntityService extends BaseService {
  constructor(path, modelCls) {
    super(modelCls);
    this.path = path;
  }

  async create({item}) {
    return this.$create({path: this.path, item: item});
  }

  async delete({item}) {
    return this.$delete({path: this.path, item: item});
  }

  async deleteItems({path, params}) {
    return this.$deleteItems({path, params});
  }

  async fetchItems({
    fields,
    q,
    qFields,
    sort,
    filters,
    limit,
    offset,
    getCount,
  }) {
    return this.$fetchItems({
      path: this.path,
      fields,
      q,
      qFields,
      sort,
      filters,
      limit,
      offset,
      getCount,
    });
  }

  async fetchItem({id, fields, q, qFields, sort}) {
    return this.$fetchItem({path: this.path, id, fields, q, qFields, sort});
  }

  async update({item, path = ''}) {
    return this.$update({path: path ? path : this.path, item});
  }

  async upsert({item, path = ''}) {
    return this.$upsert({path: path ? path : this.path, item});
  }

  async print(
    id,
    template_id,
    page_layout,
    language,
    fallBackLanguage,
    address = {}
  ) {
    return this.$print(
      id,
      template_id,
      page_layout,
      language,
      fallBackLanguage,
      address
    );
  }
}
