// computes currentUserId and currentStaffId
import {mapActions} from 'vuex';

const HitAppCurrentUserStaffMixin = {
  data() {
    return {
      fetchCurrentUserStaffsQueryId: null,
    };
  },
  computed: {
    currentUserId() {
      return this.$store.getters['context/user/userId'];
    },
    /**
     * undefined : if staffs are not loaded or loading
     * null : staff linked to user not found
     * staffId: staff id linked to user
     */
    currentStaffId() {
      if (
        this.currentUserId &&
        this.fetchCurrentUserStaffsQueryId &&
        !this.$store.getters['staff/itemsLoading'](
          this.fetchCurrentUserStaffsQueryId
        )
      ) {
        let currentStaffList = this.$store.getters['staff/items'](
          this.fetchCurrentUserStaffsQueryId
        );
        if (currentStaffList && currentStaffList.length > 0) {
          return currentStaffList[0].id;
        } else {
          return null;
        }
      }
      return undefined;
    },
    fetchCurrentUsersQueryParam() {
      return {
        fields: ['id'],
        filters: {user_id: 'eq.' + this.currentUserId},
      };
    },
  },
  methods: {
    ...mapActions({
      storeFetchStaffItems: 'staff/fetchItems',
    }),
    fetchCurrentUserStaffs() {
      this.storeFetchStaffItems({
        query: this.fetchCurrentUsersQueryParam,
      }).then((queryId) => (this.fetchCurrentUserStaffsQueryId = queryId));
    },
  },
  watch: {
    fetchCurrentUsersQueryParam(value) {
      if (value != this.fetchCurrentUsersQueryParam) {
        this.fetchCurrentUserStaffs();
      }
    },
  },
  mounted() {
    this.fetchCurrentUserStaffs();
  },
};

export default HitAppCurrentUserStaffMixin;
