<template>
  <span>{{ getUnicodeFlagIcon(country) }}</span>
</template>

<script>
import {hasFlag} from 'country-flag-icons';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

export default {
  name: 'HitCountryFlag',
  components: {},

  props: {
    /**
     * Name of the country in the following format: US, BE, ...
     */
    country: {
      type: String,
      required: true,
      validator: (value) => {
        return hasFlag(value);
      },
    },
  },

  methods: {
    getUnicodeFlagIcon,
  },
};
</script>

<style scoped lang="scss"></style>
