// @ts-nocheck
import uuid from '@hit/components/src/utils/uuid/uuid';
import { globals } from '../../../../app/src/globals';
import axios from 'axios';
export class NotificationService {
    static get baseUrl() {
        return process.env.VUE_APP_NOTIFY_URL;
    }
    static get axiosConfig() {
        return {
            headers: {
                Authorization: 'Bearer ' + globals.$keycloak.token,
                'Content-Profile': globals.$keycloak.tokenParsed.schema_id,
            },
        };
    }
    static send(data = {}) {
        const path = this.baseUrl + 'deposit-webui';
        if (!Object.prototype.hasOwnProperty.call(data, 'user_id')) {
            throw Error('You have to add the user_id attribute to the data object');
        }
        const company_id = globals.$keycloak.tokenParsed.company_id; // Type assertion
        const notification = {
            id: uuid.generate(),
            type: 'notification',
            body_is_html: false,
            ttl: 0,
            show: false,
            company_id: company_id,
            ...data, // If any value is defined in the data object, it overwrites the generated values
        };
        return axios.post(path, notification, this.axiosConfig);
    }
    static deleteSystemNotification(notificationId) {
        const path = this.baseUrl + 'remove-system-notification';
        const config = this.axiosConfig;
        config['params'] = {
            user_id: globals.$keycloak.tokenParsed.sub,
            notification_id: notificationId,
        };
        return axios.delete(path, config).then((response) => {
            return response;
        });
    }
}
