<template>
  <div :title="showTooltip && staff ? formatFn(staff) : ''">
    {{ staff ? formatFn(staff) : '' }}
  </div>
</template>

<script>
import {HitLoadingEmitterMixin} from '@hit/components';

export default {
  name: 'HitStaff',
  components: {},
  mixins: [HitLoadingEmitterMixin],
  props: {
    /**
     * Staff ID
     */
    staffId: {
      type: String,
      default: null,
    },
    /**
     * Fields to load
     */
    fields: {
      type: Array,
      default: () => {
        return ['id', 'no', 'firstName', 'lastName'];
      },
    },
    /**
     * Function to format staff
     */
    formatFn: {
      type: Function,
      default: (staff) => `${staff.lastName} ${staff.firstName} (${staff.no})`,
    },
    /**
     * Show tooltip
     */
    showTooltip: Boolean,
    /**
     * Hide loading
     */
    hideLoading: Boolean,
  },
  data() {
    return {
      fetchStaffQueryId: null,
    };
  },
  computed: {
    fetchStaffQueryParams() {
      return this.staffId
        ? {
            query: {id: this.staffId},
            fields: [this.fields],
          }
        : null;
    },
    staff() {
      return this.staffId
        ? this.$store.getters['staff/item'](this.staffId)
        : null;
    },
    isStaffLoading() {
      return (
        this.fetchStaffQueryId &&
        this.$store.getters['staff/itemsLoading'](this.fetchStaffQueryId)
      );
    },
  },
  watch: {
    fetchStaffQueryParams() {
      this.fetchStaff();
    },
    isStaffLoading: {
      handler(newValue) {
        if (this.hideLoading) {
          this.setLoading(newValue);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchStaff();
  },
  methods: {
    async fetchStaff() {
      this.fetchStaffQueryId = null;
      if (this.fetchStaffQueryParams) {
        this.fetchStaffQueryId = await this.$store.dispatch(
          'staff/fetchItem',
          this.fetchStaffQueryParams
        );
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
