import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "h-10/100" };
const _hoisted_2 = { class: "flex" };
const _hoisted_3 = { class: "flex-1 flex items-center justify-start" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { class: "flex-1 flex items-center justify-end" };
const _hoisted_6 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_hit_button = _resolveComponent("hit-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                (!_ctx.hideBackButton)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_hit_button, {
                            "icon-size": "lg",
                            "suffix-icon": "back",
                            color: "accent",
                            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('previousClick')))
                        })
                    ]))
                    : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
                (!_ctx.hideNextButton)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_hit_button, {
                            color: "accent",
                            disabled: _ctx.disableClick,
                            "suffix-icon": "forward",
                            "icon-size": "lg",
                            onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('nextClick')))
                        }, null, 8, ["disabled"])
                    ]))
                    : _createCommentVNode("", true)
            ])
        ])
    ]));
}
