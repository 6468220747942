import {defineStore} from 'pinia';
import {DataService} from '../../api';

function isValidUUID(uuid) {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}

export const useCompanyProfileStore = defineStore('companyProfile', {
  state: () => ({
    uuid: '',
    name: '',
    locales: [],
    addressObject: {},
    companyId: null,
  }),
  getters: {
    address: (state) => state.addressObject,
  },
  actions: {
    /**
     * The init action is called when the application is loaded
     * This helps us to avoid recurrent requests to the database for values that remain the same
     */
    initCompanyStore() {
      return DataService.read('config', {
        attributes: 'value',
        filters: {label: 'eq.company.address.id'},
      }).then((response) => {
        if (response && response.data && response.data.length > 0) {
          if (isValidUUID(response.data[0].value)) {
            this.companyId = response.data[0].value;
          }
        }
      });
    },
    initAddress(address) {
      this.addressObject = address;
    },
  },
});
