import BaseModel from '../BaseModel';

export default class CommunicationModeData extends BaseModel {
  constructor({communicationMode, value, comment}) {
    super();
    this.communicationMode = communicationMode;
    this.value = value;
    this.comment = comment;
  }

  static factory() {
    return new CommunicationModeData({});
  }

  static getMTORelations() {
    return {
      communicationMode: {
        childIdColumn: 'communication_mode_id',
        childTableName: 'communication_mode',
      },
    };
  }
}
