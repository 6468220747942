<template>
  <hit-app-select-entity
    :loading="loading"
    :label="label"
    :validation-state="validationState"
    :value="value"
    :additional-query-fields="allQueryFields"
    options-store-namespace="common/country"
    :label-fields="labelFields"
    :readonly="readonly"
    :inline-input="inlineInput"
    @change="onValueChange"
  >
    <template #help>
      <!-- @slot Help of the select -->
      <slot name="help" />
    </template>
  </hit-app-select-entity>
</template>

<script>
import {HitFormValidationMixin, HitAppSelectMixin} from '@hit/components';
import HitAppSelectEntity from '../../app/form/HitAppSelectEntity';
import {inject} from 'vue';
import {generateColumnForAllCompanyLanguages} from '@hit/base';

export default {
  name: 'HitCommonCountrySelect',
  components: {HitAppSelectEntity},
  mixins: [HitAppSelectMixin, HitFormValidationMixin],
  props: {
    /**
     * Selected value of the country select
     */
    value: {
      type: String,
      default: null,
      required: false,
    },
    /**
     * Enable loading of the select options
     */
    loading: Boolean,
  },
  setup() {
    const tHitField = inject('tHitField');
    return {tHitField};
  },
  data() {
    return {};
  },
  computed: {
    labelFields() {
      return [this.tHitField('designation')];
    },
    allQueryFields() {
      const necessaryFields = generateColumnForAllCompanyLanguages(
        'designation'
      );
      necessaryFields.push('id');
      return necessaryFields;
    },
  },
  methods: {
    onValueChange(value) {
      this.fireInputChange(value);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
