<template>
  <div
    class="hit-table-row bg-table hover:bg-table-hover border-t"
    @click="onClick"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'HitTableRow',
  methods: {
    onClick($event) {
      this.$emit('click', $event);
      $event.stopImmediatePropagation();
    },
  },
};
</script>

<style lang="scss">
.hit-table-row {
  @apply cursor-pointer grid px-2 hover:shadow-sm h-auto min-h-11;
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  grid-template-columns: var(--grid-template-columns);
  gap: 8px;
  border-color: rgba(
    0,
    0,
    0,
    0
  ); /* need a transparent border for the drag and drop effect of grabbable rows */
}

.inward-border {
  box-shadow: inset 0px 0px 0px 2px #0a0 !important;
}
.inward-border-transparent {
  box-shadow: inset 0px 0px 0px 2px transparent !important;
  transition: box-shadow 0.5s ease-out !important;
}
.drag-border-top {
  border-color: #0a0 !important;
}
</style>
