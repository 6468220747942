<template>
  <div class="inline-grid items-center relative">
    <hit-tooltip
      :content="text || html"
      :title="tooltipTitle"
      @tooltipVisible="tooltipVisible = $event"
    >
      <template #default="{toggleTooltip}">
        <hit-icon
          icon="help"
          :color="tooltipVisible ? 'accent' : undefined"
          size="xs"
          :tooltip="t('hit-components.common.click-to-get-help')"
          :clickable="true"
          @click="toggleTooltip"
        />
      </template>
    </hit-tooltip>
  </div>
</template>

<script>
import {useI18n} from 'vue-i18n';
import HitTooltip from '../tooltip/HitTooltip.vue';
import HitIcon from '../icon/HitIcon.vue';

export default {
  name: 'HitHelp',
  components: {HitTooltip, HitIcon},
  props: {
    /**
     * Label of the help tooltip
     */
    tooltipLabel: {
      type: String,
      required: false,
      default: undefined,
    },
    /**
     * HTML of the help tooltip
     */
    html: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Text of the help tooltip
     */
    text: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      tooltipVisible: false,
    };
  },
  computed: {
    tooltipTitle() {
      if (this.tooltipLabel === undefined) {
        return this.t('hit-components.common.help');
      } else {
        return this.tooltipLabel;
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
